.caret{display:inline-block;width:0;height:0;margin-left:2px;vertical-align:middle;border-top:4px dashed #34495f;border-top:4px solid\9;border-right:4px solid transparent;border-left:4px solid transparent}
.collapse.in{display:block}
.btn{-webkit-border-radius:2px;border-radius:2px;outline:0!important;-webkit-transition:all ease-in-out .15s;-o-transition:all ease-in-out .15s;transition:all ease-in-out .15s;position:relative}
.btn:active,.btn.active,.show>.btn.dropdown-toggle,.open>.btn.dropdown-toggle{-webkit-box-shadow:none;box-shadow:none;cursor:pointer}
.btn .active-visible{display:none}
.btn.active .active-visible{display:inline-block}
.btn.active .active-hidden{display:none}
.btn.btn-xs,.btn-group-xs .btn{padding:2px 5px;font-size:12px}
.ibox{position:relative;margin-bottom:20px;background-color:#fff;box-shadow:0 6px 0 0 rgba(0,0,0,.01),0 15px 32px 0 rgba(0,0,0,.06);border-radius:10px}
.ibox .ibox-head{padding:0 20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;min-height:68px;flex-wrap:wrap;padding:15px}
.ibox .ibox-head .ibox-title{font-size:16px;font-weight:600;margin-right:74px;color:#151515}
.ibox .ibox-head .ibox-title.services_name{margin-right:0}
.col-lg-8.pd-8{padding-right:7px}
.col-lg-4.pd-8{padding-right:7px}
.data-scroller.scroller{max-height:458px}
.ibox .ibox-head .ibox-title.services_name{font-weight:400;font-size:14px;text-align:right;width:100%}
.ibox .ibox-head .ibox-title.services_name span{font-weight:500;margin-right:3px;font-size:16px;text-align:right;float:left}
.ibox .ibox-head .ibox-title.services_name span i{font-style:normal;font-size:14px;font-weight:400;margin-left:5px}
.ibox .ibox-head .ibox-title small{font-weight:400;font-size:13px;color:#777}
.ibox .ibox-head .ibox-title small a{color:inherit}
.ibox .ibox-head .ibox-tools{position:relative}
.ibox .ibox-head .ibox-tools>a{color:#aaa;font-size:14px;padding:0 6px}
.ibox .ibox-head .ibox-tools>a:hover{color:#6d7c85}
.ibox .ibox-head .nav-tabs{margin:0;border:0;height:100%}
.ibox .ibox-head .nav-tabs .nav-link{height:100%;padding:.9rem 1rem}
.ibox .ibox-body{padding:20px}
.ibox .ibox-footer{padding:10px 0;border-top:1px solid #eee}
label.switch.on-off.ng-star-inserted{border:transparent!important}
.ibox.ibox-fullscreen{position:fixed;top:0;left:0;right:0;bottom:0;margin:0;z-index:1005}
.ibox.ibox-fullscreen .fullscreen-link>i::before{content:"\f066"}
.ibox.collapsed-mode .ibox-collapse>i::before{content:"\f067"}
.ibox-primary .ibox-head{color:#fff;background-color:#3498db}
.ibox-primary .ibox-head .ibox-tools>a,.ibox-primary .ibox-head .ibox-tools>a:hover{color:#fff}
.ibox-success .ibox-head{color:#fff;background-color:#2ecc71}
.ibox-success .ibox-head .ibox-tools>a,.ibox-success .ibox-head .ibox-tools>a:hover{color:#fff}
.ibox-info .ibox-head{color:#fff;background-color:#23B7E5}
.ibox-info .ibox-head .ibox-tools>a,.ibox-info .ibox-head .ibox-tools>a:hover{color:#fff}
.ibox-warning .ibox-head{color:#fff;background-color:#F39C12}
.ibox-warning .ibox-head .ibox-tools>a,.ibox-warning .ibox-head .ibox-tools>a:hover{color:#fff}
.ibox-danger .ibox-head{color:#fff;background-color:#e74c3c}
.ibox-danger .ibox-head .ibox-tools>a,.ibox-danger .ibox-head .ibox-tools>a:hover{color:#fff}
.ibox-grey .ibox-head{color:#fff;background-color:#bdc3c7}
.ibox-grey .ibox-head .ibox-tools>a,.ibox-grey .ibox-head .ibox-tools>a:hover{color:#fff}
body.fullscreen-mode{overflow-y:hidden}
.nav-pills,.nav-tabs{margin-bottom:15px}

.nav-tabs .nav-link{-webkit-border-radius:2px 2px 0 0;border-radius:2px 2px 0 0;margin-right:0;padding:.7rem 1rem}
.nav-tabs .nav-link:hover,.nav-tabs .nav-link:focus,.nav-tabs .nav-link.active{color:#333}
.table-full-bx .badge{min-width:52px}
.badge,.label{padding:1px 6px;line-height:inherit;font-size:11px;font-weight:500;color:#fff;-webkit-border-radius:2px;border-radius:2px}
.badge-pill,.label-pill{-webkit-border-radius:2px!important;border-radius:2px!important}
.badge-default,.label-default{background-color:#e3e6e7;color:#333}
.badge-primary,.label-primary{background-color:#f75a5f}
.badge-success,.label-success{background-color:#18c5a9}
.badge-ongoing,.label-ongoing{background-color:#fbb84f}
.badge-info,.label-info{background-color:#23B7E5}
.list .badge,.list .label{padding:0 6px}
.badge-warning,.label-warning{background-color:#F39C12}
.badge-danger,.label-danger{background-color:#f75a5f}
.badge-circle,.label-circle{height:20px;min-width:20px;line-height:20px;padding:0;-webkit-border-radius:10px;border-radius:10px}
.color1{background-color:#18c5a9}
.color2{background-color:#f75a5f}
.color3{background-color:#fbb84f}
.color4{background-color:#25c6da}
.color5{background-color:#d26e9c}
.color6{background-color:#404e67}
.locations span{margin:2px 0}
.badge-big{display:inline-block;padding:0;text-align:center;width:30px;height:30px;line-height:29px;font-size:15px}
.progress .progress-bar.progress-bar-primary{background-color:#3498db}
.progress .progress-bar.progress-bar-success{background-color:#2ecc71}
.progress .progress-bar.progress-bar-info{background-color:#23B7E5}
.progress .progress-bar.progress-bar-warning{background-color:#F39C12}
.progress .progress-bar.progress-bar-danger{background-color:#e74c3c}
.progress.progress-vertical{display:inline-block;position:relative;height:100%;width:16px;margin-right:20px}
.progress.progress-vertical .progress-bar{width:100%}
.progress.progress-vertical.progress-bottom .progress-bar{position:absolute;bottom:0}
.progress.progress-vertical.progress-wide{width:36px}
.dropdown-menu{border-radius:20px;padding:10px 0;-webkit-animation-name:dropdown-animate;animation-name:dropdown-animate;-webkit-animation-duration:.2s;animation-duration:.2s;-webkit-animation-fill-mode:both;animation-fill-mode:both;border:0}
.dropdown-menu .dropdown-item,/* a img{margin-right:5px}
*/
.dropdown-menu .dropdown-item,.dropdown-menu>li>a{padding:8px 15px;color:inherit;color:#151515;font-weight:500}
.dropdown-menu .dropdown-item:hover,.dropdown-menu .dropdown-item:focus,.dropdown-menu>li>a:hover,.dropdown-menu>li>a:focus{color:#151515;font-weight:500;background-color:transparent}
.dropdown-menu .active>a,.dropdown-menu .active>a:hover,.dropdown-menu .active>a:focus,.dropdown-menu .dropdown-item.active,.dropdown-menu .dropdown-item.active:hover,.dropdown-menu .dropdown-item.active:focus{background-color:transparent;color:inherit}
.dropdown-menu>li>a{display:block;width:100%;clear:both;font-weight:400;text-align:inherit;word-wrap:break-word;background:0 0;border:0}
.dropdown-toggle::after{display:none}
.dropdown-menu-right{right:0;left:auto}
.col-list-right .dropdown-menu-right{min-width:12rem;font-size:13px}
.list-group .list-group-item{border-color:transparent;background-color:transparent;padding:15px;border-bottom:1px solid rgba(0,0,0,.15)}
.list-group .list-group-item.disabled{background-color:transparent;color:#ccc}
.list-group .list-group-item.active{background-color:transparent;border-color:transparent;color:#2ecc71}
.list-group.list-group-full .list-group-item{padding-left:0;padding-right:0}
.list-group.list-group-divider .list-group-item:not(:first-child){border-top-color:#e1eaec}
.ibox-body .list-group .list-group-item{border-bottom:0}
.list-group.list-group-bordered .list-group-item{border-color:#e1eaec}
.list-group.list-group-bordered .list-group-item.active{background-color:#3498db;border-color:#3498db;color:#fff}
.list-group.list-group-bordered .list-group-item:first-child{-webkit-border-top-left-radius:3px;border-top-left-radius:3px;-webkit-border-top-right-radius:3px;border-top-right-radius:3px}
.list-group.list-group-bordered .list-group-item:last-child{-webkit-border-bottom-left-radius:3px;border-bottom-left-radius:3px;-webkit-border-bottom-right-radius:3px;border-bottom-right-radius:3px}
.list-group .list-group-item-success{color:#219351;background-color:#e0f7ea}
.list-group a.list-group-item-success,.list-group button.list-group-item-success{color:#219351}
.list-group a.list-group-item-success:hover,.list-group a.list-group-item-success:focus,.list-group button.list-group-item-success:hover,.list-group button.list-group-item-success:focus{color:#219351;background-color:#ccf2dc}
.list-group .list-group-item-info{color:#1984a5;background-color:#def4fb}
.list-group a.list-group-item-info,.list-group button.list-group-item-info{color:#1984a5}
.list-group a.list-group-item-info:hover,.list-group a.list-group-item-info:focus,.list-group button.list-group-item-info:hover,.list-group button.list-group-item-info:focus{color:#1984a5;background-color:#c7ecf8}
.list-group .list-group-item-warning{color:#af700d;background-color:#fdf0db}
.list-group a.list-group-item-warning,.list-group button.list-group-item-warning{color:#af700d}
.list-group a.list-group-item-warning:hover,.list-group a.list-group-item-warning:focus,.list-group button.list-group-item-warning:hover,.list-group button.list-group-item-warning:focus{color:#af700d;background-color:#fce6c3}
.list-group .list-group-item-danger{color:#a6372b;background-color:#fbe4e2}
.list-group a.list-group-item-danger,.list-group button.list-group-item-danger{color:#a6372b}
.list-group a.list-group-item-danger:hover,.list-group a.list-group-item-danger:focus,.list-group button.list-group-item-danger:hover,.list-group button.list-group-item-danger:focus{color:#a6372b;background-color:#f8cfcc}
.media{margin:0}
.media .media-heading{line-height:1.1;font-weight:600;margin-bottom:5px}
.media .media-img{padding-right:14px}
.media-list{padding:0;list-style:none}
.media-list .media{padding:8px 0}
.media-right .media-img{padding-left:14px;padding-right:0}
.media-list.media-list-divider .media:not(:first-child){border-top:1px solid #e1eaec}
.card-title{font-size:16px;font-weight:500;margin-bottom:10px;margin-top:0}
.card-subtitle{margin-top:-10px;margin-bottom:10px;font-size:13px}
.card-header,.card-footer{background-color:inherit}
.form-control-rounded{-webkit-border-radius:200px!important;border-radius:200px!important;padding:.65rem 1.25rem}
.form-control:focus{border-color:#b5b5b5}
.input-rounded{-webkit-border-radius:200px;border-radius:200px}
.input-group-addon{color:#5d6064;color:#71808f;-webkit-border-radius:2px!important;border-radius:2px!important}
.help-block{display:block;font-size:13px;margin-bottom:0;margin-top:2px}
.input-group-icon{position:relative;color:#71808f}
.input-group-icon .form-control{padding-left:33px;padding-right:33px}
.col-list-right .circle{width:37px;height:40px;background:#f4f5f9;border-radius:50%;padding:0;text-align:center;cursor:pointer}
.col-list-right .circle i{font-size:20px;line-height:40px;text-align:center}
.input-group-icon .input-icon{position:absolute;left:0;top:0;height:40px;line-height:40px;width:34px;text-align:center;cursor:pointer}
.col-list-right .input-icon.input-icon-left{left:inherit;right:0;font-size:12px}
.col-list-right .input-group-icon .input-icon{line-height:44px}
.table-scroll .table-bordered td,.table-scroll .table-bordered th{border-right:1px solid #ddd!important;text-align:left!important}
.table-scroll .table-bordered td a.dropdownlist{display:block;width:100%;overflow:unset;text-overflow:inherit;white-space:normal}
.table-scroll .table-bordered td{vertical-align:middle}
table thead .sorting_asc{cursor:pointer;position:relative}
.table-scroll table thead>tr>th.sorting_asc{padding-right:30px}
.input-group-icon.right .input-icon{right:0;left:auto}
.input-group-icon.right .form-control{padding-left:12px;padding-right:33px}
.table>tbody>tr>th,.table>tbody>tr>td,.table>tfoot>tr>th,.table>tfoot>tr>td{border-top:1px solid #e8e8e8}
.table thead th{border-bottom:1px solid #ddd!important;border-top:0;font-weight:600;vertical-align:top}
.table.no-border>tbody>tr>td,.table.no-border>thead>tr>th{border:0 none!important}
.groups-permissions .table-striped tbody tr:nth-of-type(odd){background-color:#fff!important}
.table tr.success>td{background-color:#e0f7ea!important}
.table tr.info>td{background-color:#def4fb!important}
.table tr.warning>td{background-color:#fdf0db!important}
.table tr.danger>td{background-color:#fbe4e2!important}
.pagination .page-link{color:inherit;border:0 solid #ddd;padding:0;background:transparent}
.pagination .page-link:hover,.pagination .page-link:focus{color:#3498db}
.pagination .page-item.active .page-link,.pagination .active>a,.pagination .active>a:hover,.pagination .active>a:focus,.pagination .active>span,.pagination .active>span:hover,.pagination .active>span:focus{background-color:rgba(40,45,51,0.98);border-color:rgba(40,45,51,0.98);color:#fff;padding:0}
.page-item.disabled .page-link{width:auto;background:transparent;padding:0 10px}
.pagination li{margin-right:5px}
.page-link{height:31px;width:31px;padding:0;border:0;margin:0;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;color:inherit;-webkit-border-radius:50%!important;border-radius:50%!important}
.page-link:focus,.page-link:hover{background-color:#333940;color:#fff!important}
.paginate_button.page-item.next .page-link:hover{background:none;color:#333!important}
.modal-dialog.small{max-width:550px}
.modal-dialog .modal-content{-webkit-box-shadow:0 2px 4px -1px rgba(0,0,0,0.14),0 4px 5px 0 rgba(0,0,0,0.098),0 1px 10px 0 rgba(0,0,0,0.084);box-shadow:0 2px 4px -1px rgba(0,0,0,0.14),0 4px 5px 0 rgba(0,0,0,0.098),0 1px 10px 0 rgba(0,0,0,0.084)}
.modal-footer.buttons{margin-top:0;margin-left:0;display:block}
.modal-header .close{margin:0;font-size:0;font-family:'themify';font-style:normal;font-variant:normal;text-transform:none;-webkit-font-smoothing:antialiased;opacity:.5;cursor:pointer}
.modal-header .close::before{content:"\e646";font-size:15px}
.modal-header .close:hover{opacity:.75}
.modal-header .close span{display:none}
.img-circle{-webkit-border-radius:50%;border-radius:50%}
@-webkit-keyframes collapseMenu {
from{width:60px}
to{width:100%}
}
@keyframes collapseMenu {
from{width:60px}
to{width:100%}
}
@-webkit-keyframes fadeInUp {
0%{opacity:0;-webkit-transform:translateY(20px);transform:translateY(20px)}
100%{opacity:1;-webkit-transform:translateY(0);transform:translateY(0)}
}
@keyframes fadeInUp {
0%{opacity:0;-webkit-transform:translateY(20px);transform:translateY(20px)}
100%{opacity:1;-webkit-transform:translateY(0);transform:translateY(0)}
}
@-webkit-keyframes heartbit {
0%{-webkit-transform:scale(0);opacity:0}
25%{-webkit-transform:scale(0.1);opacity:.1}
50%{-webkit-transform:scale(0.5);opacity:.3}
75%{-webkit-transform:scale(0.8);opacity:.5}
100%{-webkit-transform:scale(1);opacity:0}
}
@keyframes heartbit {
0%{-webkit-transform:scale(0);opacity:0}
25%{-webkit-transform:scale(0.1);opacity:.1}
50%{-webkit-transform:scale(0.5);opacity:.3}
75%{-webkit-transform:scale(0.8);opacity:.5}
100%{-webkit-transform:scale(1);opacity:0}
}
@-webkit-keyframes loader-rotate {
to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}
}
@keyframes loader-rotate {
to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}
}
.fade-in-up{-webkit-animation:fadeInUp .5s;animation:fadeInUp .5s}
.font-13{font-size:13px}
.font-14{font-size:14px}
.font-15{font-size:15px}
.font-16{font-size:16px}
.font-17{font-size:17px}
.font-18{font-size:18px}
.font-20{font-size:20px}
.font-26{font-size:26px}
.color-white{color:#fff!important}
.color-green{color:#2ecc71!important}
.color-blue{color:#3498db!important}
.color-orange{color:#F39C12!important}
.color-red{color:#e74c3c!important}
.color-blue-light{color:#23B7E5!important}
.color-purple{color:#7E57C2!important}
.color-yellow{color:#fbb84f!important}
.color-ebony{color:#34495f!important}
.color-silver{color:#bdc3c7!important}
.color-inherit{color:inherit!important}
.text-primary{color:#5c6bc0!important}
.text-pink{color:#ff4081!important}
.text-success{color:#18c5a9!important}
.text-info{color:#23B7E5!important}
.text-warning{color:#F39C12!important}
.text-danger{color:#f75a5f!important}
.text-muted{color:#151515!important;font-size:15px}
.notificationmain{display:flex;align-items:flex-start;padding:10px 0;border-bottom:1px solid #EFEFEF}
.notificationmain .applybtn{width:fit-content;font-size:14px}
.notificationmain .para{font-size:14px}
.notificationmain.active{background:#f8f8f8}
.notify-img{height:30px;border-radius:50%;margin-right:10px;width:36px;background-color:#f5f5f5}
.viewall{padding:10px 0;text-transform:capitalize}
.text-white{color:#fff!important}
.notificationmain{position:relative;padding:15px 10px;align-items:flex-start}
.notitime{position:absolute;bottom:10px;right:10px}
.option{position:absolute;right:15px;top:25px}
a.text-primary:hover,a.text-primary:focus{color:#258cd1!important}
a.text-info:hover,a.text-info:focus{color:#19a9d5!important}
a.text-success:hover,a.text-success:focus{color:#29b765!important}
a.text-warning:hover,a.text-warning:focus{color:#e08e0b!important}
a.text-danger:hover,a.text-danger:focus{color:#e43725!important}
.uppercase{text-transform:uppercase!important}
.centered{float:none;margin-left:auto;margin-right:auto}
.rel,.p-relative{position:relative!important}
.p-absolute{position:absolute!important}
.v-middle{vertical-align:middle}
.border-0{border:0!important}
.hidden{display:none}
.flexbox{display:flex;justify-content:space-between;align-items:center}
.table-full-bx .flexbox.mb-4{padding:20px 20px 0;margin-bottom:10px!important}
.table th{padding:16px 10.5px}
.flexbox-b{display:-webkit-box!important;display:-webkit-flex!important;display:-ms-flexbox!important;display:flex!important;-webkit-box-align:center!important;-webkit-align-items:center!important;-ms-flex-align:center!important;align-items:center!important}
.circle-chart.completed-reports{width:80px;height:80px;float:left}
.grey-circle{fill:none;stroke:#f2f2f2;stroke-width:3}
.card{overflow:hidden;border:0;min-height:100px;box-shadow:0 6px 0 0 rgba(0,0,0,.01),0 15px 32px 0 rgba(0,0,0,.06)}
.card .form-group{margin-bottom:0}
.circle-chart.completed-reports .inn-circle-chart{stroke:#18c5a9}
.circle-chart.completed-reports.text-pink .inn-circle-chart{stroke:#ff4081}
.circle-chart.completed-reports.text-primary .inn-circle-chart{stroke:#5c6bc0}
.inn-circle-chart{stroke-width:3;fill:none;stroke-linecap:round;-webkit-animation:circle-chart-fill .7s reverse;animation:circle-chart-fill .7s reverse;-webkit-transform-origin:center;-ms-transform-origin:center;transform-origin:center}
.flex-1{-webkit-box-flex:1!important;-webkit-flex:1!important;-ms-flex:1!important;flex:1!important}
.header{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;height:66px;background-color:#fff;margin:0;border:0;border-radius:0;box-shadow:0 2px 4px rgba(0,0,0,0.08);position:fixed;left:0;right:0;width:100%;z-index:1}
.header .page-brand{width:230px;background:#fff;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;padding:0 15px;font-weight:500;font-size:20px;-webkit-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;transition:all .2s ease-in-out;overflow:hidden}
.header .page-brand .brand{display:block}
.header .page-brand .brand img{max-width:100px}
.header .brand svg{width:150px;line-height:66px;display:block}
.header .brand-mini svg{line-height:66px;display:block;width:55px;margin-left:3px}
.header .page-brand .brand-mini{display:none}
.header .navbar-toolbar{-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center}
.header .sidebar-toggler .icon-bar{width:20px;height:2px;background-color:#6d7c85;margin-top:4px;display:block;transition:all .3s}
.header .sidebar-toggler .icon-bar:first-child{margin-top:0}
.sidebar-mini .header .sidebar-toggler .icon-bar:first-child{width:7px}
.sidebar-mini .header .sidebar-toggler .icon-bar:nth-child(2){width:14px}
.header .navbar-toolbar .toolbar-icon{font-size:18px}
.header .navbar-toolbar>li>a{-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;color:#6d7c85}
.header .navbar-toolbar>li .notify-signal{height:6px;width:6px;position:absolute;top:-3px;right:-3px;background:#18C5A9;-webkit-border-radius:50%;border-radius:50%}
.header .navbar-toolbar>li .notify-signal::before{display:none;content:'';position:absolute;top:-10px;right:-10px;height:25px;width:25px;z-index:10;border:5px solid #18C5A9;-webkit-border-radius:50%;border-radius:50%;animation:heartbit 1s ease-out;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite}
.header .navbar-toolbar>li.active .notify-signal::before{display:block}
.header .navbar-toolbar .dropdown-notification a.dropdown-toggle .notify-signal{background:#f75a5f}
.header .navbar-toolbar .dropdown-notification a.dropdown-toggle .notify-signal::before{border-color:#f75a5f}
.header .navbar-search{padding:0 15px}
.header .navbar-search .search-icon{position:absolute;top:0;height:100%;width:40px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;color:#6d7c85}
.header .navbar-search .form-control{border:0;padding:.65rem 1.25rem .65rem 40px;-webkit-border-radius:200px!important;border-radius:200px!important;background-color:#f4f5f9;border-color:#f4f5f9;font-size:13px}
.header .sidebar-toggler{font-size:18px}
.header .dropdown-user{padding-right:10px}
.header .dropdown-user a.dropdown-toggle{padding:0 15px}
.header .dropdown-user a.dropdown-toggle img{width:36px;-webkit-border-radius:50%;border-radius:50%;margin-left:10px}
.header .dropdown-user>.dropdown-menu .dropdown-item i{margin-right:10px}
.header .dropdown-inbox,.header .dropdown-notification{font-size:15px}
.header .dropdown-inbox img{width:40px;height:40px}
.header .toolbar-icon{font-size:18px;height:40px;width:40px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-border-radius:50%;border-radius:50%;margin:0 5px;padding:.5rem .3rem;text-align:center}
.dropdown-menu-media{min-width:360px}
.dropdown-menu-media .dropdown-menu-header span{color:#151515;font-weight:600}
.dropdown-menu-media a{display:block}
.dropdown-menu-media strong{font-weight:500}
.envelope-badge{position:absolute;right:4px;top:0;padding:2px 5px;line-height:12px;font-size:11px;font-weight:500;text-align:center;color:#fff;-webkit-border-radius:50%;border-radius:50%;background-color:#f75a5f}
.page-sidebar{width:230px;overflow:auto;position:fixed;top:0;bottom:0;margin-top:66px;-webkit-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;transition:all .2s ease-in-out;z-index:3}
.page-sidebar ul{padding:0;margin:0;list-style:none}
.page-sidebar ul li{position:relative;display:block;margin:20px 8px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:220px;padding-left:0;text-transform:capitalize}
.page-sidebar ul li a{position:relative;display:block;padding:12px 10px;font-size:14px;color:#151515;display:flex;align-items:center}
.page-sidebar .admin-block{padding:15px 10px}
.page-sidebar .admin-block .admin-avatar{width:45px}
.page-sidebar .admin-block .admin-info{padding-left:1rem}
.side-menu .heading{padding:12px 15px;display:block;font-size:13px}
.side-menu li{outline:0}
.side-menu li .sidebar-item-icon{display:inline-block;vertical-align:middle;width:22px;line-height:20px!important;font-size:17px;height:17px}
.side-menu li .nav-label{display:block;font-weight:600;margin-left:8px}
.side-menu li .arrow{line-height:18px;position:absolute;top:50%;right:15px;margin-top:-9px;opacity:1;font-size:10px}
.side-menu li.active>a>.arrow{-webkit-transform:rotate(-90deg);-ms-transform:rotate(-90deg);transform:rotate(-90deg)}
.side-menu>li a{white-space:nowrap;overflow:hidden}
.side-menu .nav-2-level>li>a{padding:10px 10px 10px 45px}
.side-menu .nav-3-level>li>a{padding:7px 10px 7px 55px}
body:not(.fixed-layout).sidebar-mini .content-wrapper{margin-left:70px}
body:not(.fixed-layout).sidebar-mini .page-sidebar{width:70px}
body:not(.fixed-layout).sidebar-mini .page-sidebar:hover{width:230px}
body:not(.fixed-layout).sidebar-mini .page-sidebar:hover .side-menu>li>a>.nav-label{display:block}
body:not(.fixed-layout).sidebar-mini .side-menu li .arrow{right:5px}
body:not(.fixed-layout).sidebar-mini .page-brand{width:70px}
body:not(.fixed-layout).sidebar-mini .brand{display:none}
body:not(.fixed-layout).sidebar-mini .brand-mini{display:block}
body:not(.fixed-layout).sidebar-mini .side-menu li.heading{display:none}
body:not(.fixed-layout).sidebar-mini .side-menu li .sidebar-item-icon{font-size:17px;text-align:left}
body:not(.fixed-layout).sidebar-mini .page-sidebar:hover .side-menu li .sidebar-item-icon{font-size:17px;text-align:left}
body:not(.fixed-layout).sidebar-mini .side-menu li .nav-2-level ul{padding-left:0}
body:not(.fixed-layout).sidebar-mini .side-menu li .nav-3-level>li>a{padding-left:27px}
body:not(.fixed-layout).sidebar-mini .side-menu li .nav-3-level>li>a{padding-left:27px}
body:not(.fixed-layout).sidebar-mini .side-menu>li>a>.nav-label{display:none}
body:not(.fixed-layout).sidebar-mini .side-menu>li:hover>a{overflow:visible}
body:not(.fixed-layout).sidebar-mini .side-menu>li:hover>a>.nav-label{display:block}
body:not(.fixed-layout).sidebar-mini .page-sidebar:hover .side-menu li>a>.arrow{-webkit-transform:rotate(-90deg);-ms-transform:rotate(-90deg);transform:rotate(-90deg)}
body:not(.fixed-layout).sidebar-mini .side-menu>li .nav-2-level{display:none}
body:not(.fixed-layout).sidebar-mini .page-sidebar:hover .side-menu>li .nav-2-level{display:block;height:auto!important;opacity:1;visibility:visible}
body:not(.fixed-layout).sidebar-mini .admin-block{padding:15px 12px}
body:not(.fixed-layout).sidebar-mini .admin-block .admin-info{display:none}
body.fixed-layout.sidebar-mini .page-sidebar,body.fixed-layout.sidebar-mini .page-brand{margin-left:-220px;visibility:hidden}
body.fixed-layout.sidebar-mini .content-wrapper{margin-left:0}
.page-footer{position:absolute;left:0;bottom:0;width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;padding:10px 15px;background-color:#fff}
.content-wrapper{position:relative;background-color:#FAFAFA;margin-left:230px;padding:90px 15px 10px;-webkit-transition:margin .2s ease-in-out;-o-transition:margin .2s ease-in-out;transition:margin .2s ease-in-out}
.card-body .easypie{position:relative;text-align:center}
.card-body .easypie i{font-size:30px;width:58px;height:58px;line-height:58px;text-align:center;border-radius:50%;display:inline-block;background:#e4e7ec;color:#404e67}
.card-body.text-success .easypie i{background-color:#d7f9f6;color:#84d9d2;font-size:26px}
.card-body.text-primary .easypie i{background:#ffe2e4;color:#f75a5f;font-size:32px}
.easypie-data.pd-set i{font-size:23px}
.card-body.text-pink .easypie i{background-color:rgba(255,242,210,0.9)!important;color:rgba(255,205,86,.9)}
.card-body.text-red .easypie i{background-color:#ffe4f0!important;color:#d26e9c}
.card-body.yellow .easypie i{background:#ffecce;color:#fccd83}
.report-sroller{min-height:205px}
.card-body h3{letter-spacing:.64px;color:#151515;font-weight:600}
.page-header{padding-top:35px;padding-bottom:20px}
.page-heading{display:flex}
.page-heading .page-title{font-size:22px;margin:15px 0;display:inline-block}
.page-heading .breadcrumb{padding:0;margin:0;font-size:13px;background-color:inherit;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;margin-left:auto}
.page-heading .breadcrumb li{float:none;color:#868e96}
.page-heading .breadcrumb li a{color:inherit}
.page-heading .breadcrumb li+li:before{content:'\002022';color:inherit}
body.empty-layout .theme-config,body.empty-layout .to-top{display:none!important}
body.fixed-navbar .header,body.fixed-layout .header{position:fixed;left:0;right:0;top:0;z-index:5}
.fixed-navbar .content-wrapper,body.fixed-layout .content-wrapper{padding-top:20px}
body.fixed-layout .page-sidebar{position:fixed;top:56px;bottom:0;margin-top:0;padding-bottom:100px}
body.boxed-layout{max-width:1200px;margin:0 auto}
body.boxed-layout.fixed-navbar .header,body.boxed-layout.fixed-layout .header{max-width:1200px;margin:0 auto}
.theme-config{position:absolute;right:-310px;top:90px;z-index:1001;width:310px;-webkit-transition:right .3s ease-in-out;-o-transition:right .3s ease-in-out;transition:right .3s ease-in-out}
.theme-config .theme-config-toggle{position:absolute;left:-34px;height:38px;width:35px;background-color:#23b7e5;-webkit-border-bottom-left-radius:4px;border-bottom-left-radius:4px;-webkit-border-top-left-radius:4px;border-top-left-radius:4px;text-align:center;cursor:pointer}
.theme-config .theme-config-toggle .theme-config-show{font-size:22px;color:#fff;line-height:38px;-webkit-animation:fa-spin 3s infinite linear;animation:fa-spin 3s infinite linear}
.theme-config .theme-config-toggle .theme-config-close{display:none;font-size:18px;line-height:38px}
.theme-config .theme-config-box{padding:10px 20px 20px;background-color:#fff;border:1px solid rgba(0,0,0,0.1)}
.theme-config .theme-config-box .ui-checkbox+.ui-checkbox{margin-top:15px}
.theme-config.opened{right:0}
.theme-config.opened .theme-config-toggle{background-color:#fff;border:1px solid rgba(0,0,0,0.1);border-right:none}
.theme-config.opened .theme-config-toggle .theme-config-show{display:none}
.theme-config.opened .theme-config-toggle .theme-config-close{display:block}
.theme-config.opened .theme-config-toggle .close-btn{display:block}
.theme-config .color-skin-box{position:relative;-webkit-box-flex:1;-webkit-flex:1;-ms-flex:1;flex:1;margin:0 5px;border:1px solid #ddd}
.theme-config .color-skin-box .color{height:40px}
.theme-config .color-skin-box .color-small{display:block;height:18px}
.theme-config .color-skin-box .color-check-icon{position:absolute;left:10px;top:10px;opacity:0;font-size:16px}
.theme-config .color-skin-box label{display:block;cursor:pointer;margin:0}
.theme-config .color-skin-box input{position:absolute;opacity:0;visibility:hidden}
.theme-config .color-skin-box input:checked+.color-check-icon{opacity:1}
.preloader-backdrop{position:fixed;top:0;bottom:0;left:0;right:0;z-index:1004;background-color:#fff}
.preloader-backdrop .page-preloader{position:fixed;top:40%;left:50%;padding:10px 45px 10px 10px;margin-left:-60px;z-index:1005;font-family:"Poppins";font-size:16px}
.preloader-backdrop .page-preloader::before{content:'';position:absolute;top:50%;right:10px;width:20px;height:20px;margin-top:-10px;margin-left:-10px;border-top:2px solid #34495f;border-right:2px solid transparent;-webkit-border-radius:50%;border-radius:50%;-webkit-animation:loader-rotate .6s linear infinite;animation:loader-rotate .6s linear infinite}
.backdrop{position:fixed;top:0;left:0;height:100%;width:100%;opacity:0;background-color:rgba(0,0,0,0.5)!important;-webkit-transition:opacity .3s cubic-bezier(0.4,0,0.2,1);-o-transition:opacity .3s cubic-bezier(0.4,0,0.2,1);transition:opacity .3s cubic-bezier(0.4,0,0.2,1);z-index:1004;pointer-events:none}
.shined{z-index:1005!important}
body.has-backdrop .backdrop{pointer-events:auto;opacity:1}
.to-top{position:fixed;right:3px;bottom:40px;width:30px;height:30px;line-height:30px;text-align:center;color:#FFF;border:2px solid #687991;-webkit-border-radius:50%;border-radius:50%;z-index:1001;opacity:1;cursor:pointer;display:none}
.to-top i{font-size:26px;color:#687991}
.to-top:hover{background-color:#687991}
.to-top:hover i{display:block;color:#fff}
.page-sidebar{background-color:#fff;box-shadow:0 4px 2px #00000008}
.page-sidebar .admin-block .admin-info{color:#fff}
.page-sidebar .admin-block .admin-info small{color:#b4bcc8}
.side-menu .heading{color:rgba(255,255,255,0.4)}
.side-menu>li a{color:#151515}
.side-menu>li a:hover,.side-menu>li a:focus{color:#fff;background:#EF7E2B;border-radius:10px;text-transform:capitalize}
.side-menu>li.active>a,.side-menu>li.active>a:hover,.side-menu>li.active>a:focus{color:#fff;background:#EF7E2B;border-radius:10px}
.side-menu>li>a.active,.side-menu>li>a.active:hover,.side-menu>li>a.active:focus{color:#fff;background:#EF7E2B}
.side-menu>li a:hover img,.side-menu>li a:focus img{filter:brightness(0) invert(1)}
.side-menu li.active a img{filter:brightness(0) invert(1)}
.side-menu li a.active{color:#fff}
.sidebar-mini .side-menu>li:hover>a{color:#fff;background:#EF7E2B;border-radius:10px}
.sidebar-mini .side-menu>li:hover .nav-2-level{background-color:#253544}
.legendLabel{padding-left:5px}
.flotTip{-webkit-border-radius:0!important;border-radius:0!important;background-color:#323232!important;border:1px solid #323232!important;opacity:.8;color:#fff}
.jqstooltip{-webkit-box-sizing:content-box;box-sizing:content-box;border:0!important}
table.dataTable{width:100%!important}
.fc-event{padding:4px 6px;background-color:#3498db;color:#fff!important;border:0}
#external-events .ex-event{padding:5px 10px;margin:5px 0;cursor:move}
.select2-container{max-width:100%}
.select2-container--default .select2-selection--multiple,.select2-container--default .select2-selection--single,.select2-container--default.select2-container--focus .select2-selection--multiple{outline:0!important;height:auto}
.select2-dropdown{border:1px solid rgba(0,0,0,0.15)}
.select2-container--default .select2-selection--single .select2-selection__rendered{line-height:30px}
.select2-container--default .select2-selection--multiple .select2-selection__rendered{line-height:1.25}
.bootstrap-timepicker-widget .bootstrap-timepicker-hour,.bootstrap-timepicker-widget .bootstrap-timepicker-meridian,.bootstrap-timepicker-widget .bootstrap-timepicker-minute,.bootstrap-timepicker-widget .bootstrap-timepicker-second{border:0;background:0 0;outline:0!important;-webkit-box-shadow:none;box-shadow:none}
.note-editor.note-frame{-webkit-border-radius:0;border-radius:0;border-color:#ddd}
.note-toolbar{background-color:#f2f2f2!important}
.note-btn,.note-btn:hover,.open .note-btn.dropdown-toggle{background-color:#fff}
.note-color .dropdown-menu>li>.btn-group{display:table-cell!important;padding:5px}
.note-popover.popover{display:none}
.jvectormap-zoomin,.jvectormap-zoomout,.jvectormap-goback{background:#3498db}
.adminca-banner{display:block;padding:15px;background-image:-webkit-linear-gradient(315deg,#2cc4cb,#18c5a9);background-image:-o-linear-gradient(315deg,#2cc4cb,#18c5a9);background-image:linear-gradient(135deg,#2cc4cb,#18c5a9);position:relative;max-width:1260px}
.wrap-1,.wrap-2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex}
.adminca-banner-ribbon{position:absolute;left:0;top:5px;padding:4px 10px;background-image:-webkit-linear-gradient(315deg,rgba(0,0,0,0.1),rgba(0,0,0,0.5));background-image:-o-linear-gradient(315deg,rgba(0,0,0,0.1),rgba(0,0,0,0.5));background-image:linear-gradient(135deg,rgba(0,0,0,0.1),rgba(0,0,0,0.5));color:#f39c12!important;font-size:1.75rem}
.adminca-banner-b{font-size:16px;padding:5px 10px;background:#fff;-webkit-box-shadow:0 0 15px 5px rgba(0,0,0,0.2);box-shadow:0 0 15px 5px rgba(0,0,0,0.2);width:170px;-webkit-border-radius:50px;border-radius:50px;color:#333}
.dev-img{position:absolute;right:0;bottom:0}
.select2-hidden-accessible{border:0!important;clip:rect(0 0 0 0)!important;height:1px!important;margin:-1px!important;overflow:hidden!important;padding:0!important;position:absolute!important;width:1px!important}
.select-outer{display:block;width:100%}
.select2-container{box-sizing:border-box;display:inline-block;margin:0;position:relative;vertical-align:middle}
.select2-dropdown{background-color:#fff;border:1px solid #f4f4f4;border-radius:2px;box-sizing:border-box;display:block;position:absolute;left:-100000px;width:100%;z-index:1051}
.select2-container--default .select2-results__option[role=group]{padding:0}
.select2-results__option{padding:6px;user-select:none;-webkit-user-select:none}
.select2-container--open .select2-dropdown{left:0}
.select2-dropdown{border:1px solid rgba(0,0,0,.15)}
.select2-results__option{padding:6px;user-select:none;-webkit-user-select:none}
.select2-results__option[aria-selected]{cursor:pointer}
.select2-container--default .select2-results__option .select2-results__option{padding-left:10px}
.select2-results__options{list-style:none;margin:0;padding:0}
.select2-container--default .select2-results>.select2-results__options{max-height:200px;overflow-y:auto}
.select2-container--default .select2-selection--single .select2-selection__arrow{height:26px;position:absolute;top:1px;right:1px;width:20px}
.select2-container--default .select2-selection--single .select2-selection__arrow b{border-color:#888 transparent transparent;border-style:solid;border-width:5px 4px 0;height:0;left:50%;margin-left:-4px;margin-top:-2px;position:absolute;top:50%;width:0}
.select2-container .select2-selection--single .select2-selection__rendered{display:block;padding-left:0;padding-right:20px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;text-transform:uppercase}
.select2-container .select2-selection--single{box-sizing:border-box;cursor:pointer;display:block;height:40px;user-select:none;-webkit-user-select:none}
.listview dl{margin-bottom:15px;margin-top:0;font-size:14px}
.listview .list dt:first-child{font-weight:500;text-overflow:ellipsis;width:22%;padding-left:35px}
.listview .list dt{text-align:left;display:inline-block;vertical-align:top;width:75%;line-height:22px;font-size:14px;font-weight:400;word-wrap:break-word}
.repeatedrow h3{font-size:20px;margin:0 7px 25px 0}
.action-wrap{margin-top:20px}
.repeatedrow .action-wrap{margin-top:0}
.repeatedrow .action-wrap a{color:#ccd3dc;border:1px solid #34495f;background:#282d33;font-size:16px}
.action-wrap.table-action{margin-top:0}
.action-wrap.table-action a{color:#EF7E2B;border:1px solid #EF7E2B;border-radius:8px;width:120px;height:35px;font-size:16px}
.action-wrap a{width:36px;height:36px;display:inline-block;margin-right:1px;position:relative;vertical-align:top;line-height:36px;font-size:18px;text-align:center;color:#6d7c85;text-align:center;padding:0 6px;overflow:visible;border-radius:30px;background:#fff;position:relative}
.splash.on:before{content:'';position:absolute;top:6px;left:50%;right:0;width:1.5px;background:#ccd3dc;bottom:6px;border-radius:2px;transform:rotate(20deg)}
.action-wrap.table-action .splash.on:before{content:'';background:#aaa}
.amount{display:block;font-size:12px;font-style:normal;font-weight:500}
.light .image-box{width:100px;height:100px;overflow:hidden;float:left;margin:6px 6px 6px 0;border-radius:2px;border:1px solid #e0e0e0;text-align:center;line-height:100px;cursor:pointer}
.left-panel.light .image-box{width:auto;height:auto;margin:0;line-height:inherit}
.stars .las.la-star.fill{color:#fbb84f}
.ad-spa h3{font-size:14px;display:inline-block;border-bottom:1px solid #354558;padding-bottom:0;margin-bottom:0}
.list .col-list-right .circle{width:30px;height:30px;background:none;margin-top:-4px}
.list .col-list-right .circle i{font-size:30px;line-height:30px}
.list .col-list-right .dropdown-menu{min-width:350px}
.list .col-list-right .dropdown-menu{padding:0}
.list .col-list-right .dropdown-menu h2{margin-top:0;font-weight:400;font-size:20px;padding:14px;color:rgba(40,45,51,0.98);border-radius:3px 3px 0 0;margin-bottom:0;border-bottom:1px solid #d5d6e4}
.bar-container{margin:8px 15px;font-weight:400}
.bar-container:after{content:'';display:table;width:100%}
.list .col-list-right .dropdown-menu .bar-container{font-size:14px}
.list .col-list-right .dropdown-menu .bar-container.bold{font-weight:500}
.list .col-list-right .dropdown-menu .bar-col{width:60%;padding-right:0;float:left;font-size:14px}
.list .col-list-right .dropdown-menu .reating-count{width:40%;padding-right:0;float:left;text-align:right}
.bar-container.sub-total{border-top:1px solid #d5d6e4;margin:0 15px;padding:12px 0;border-bottom:1px solid #d5d6e4}
.bar-container.bold.sub-total:last-child{border-bottom:0}
.before_cancelled{font-size:12px}
.job-container .col-4{padding-left:5px;position:relative}
.job-container .col-4:before{content:'';position:absolute;top:0;right:15px;left:5px;background:#fff;bottom:20px;-webkit-box-shadow:0 1px 1px rgba(0,0,0,0.1);box-shadow:0 1px 1px rgba(0,0,0,0.1)}
.job-container .col-4 .ibox{box-shadow:inherit}
.timeline .timeline-item{position:relative;padding:10px 15px 10px 23px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;flex-wrap:wrap}
.timeline{position:relative;padding:0;list-style:none;padding-left:10px}
.timeline .timeline-item .timeline-point{height:15px;width:15px;background-color:#18c5a9;border:4px solid #fff;-webkit-border-radius:100%;border-radius:100%;position:absolute;left:-7px;top:14px}
.timeline-item strong{margin-right:10px;font-weight:500}
.time-zone{padding:0 0 0 33px;font-weight:500}
.timeline.total-time .timeline-item .timeline-point{display:none}
.timeline.total-time:before{display:none}
.timeline.total-time .timeline-item{padding:10px 15px 10px 3px;margin-left:20px;border-top:1px solid #ebedf2;border-bottom:1px solid #ebedf2}
.buttons{display:block;text-align:center;margin-top:20px;margin-left:30px}
.common-btn{background:#1E5147;color:#fff;padding:6px 10px;display:inline-block;border-radius:2px;margin-top:10px;font-size:14px;letter-spacing:0;margin:2px;border:0;cursor:pointer;display:inline-block!important}
.buttons a:hover{color:#fff}
.buttons .common-btn{min-width:110px;color:#fff!important}
.common-btn.cancel{background:#f75a5f}
.job-cancelled{display:block;width:100%;flex-wrap:wrap;text-align:right;font-size:11px;font-style:normal;font-weight:500;color:#f75a5f}
.ibox.set-spacing .left-panel{display:table-cell;width:20%;padding:20px;vertical-align:top;background:#fafafa;border-right:1px solid #ddd;border-radius:3px 0 0 3px}
.ibox.set-spacing .left-panel img{cursor:pointer}
.ibox.set-spacing .user-details{display:table-cell;width:75%;padding:5px 0}
.fixed-box.ibox.set-spacing .user-details{display:block;width:100%}
.ibox.set-spacing .ibox-body{padding:0}
.ibox.set-spacing .user-details{padding:20px}
.ibox.set-spacing .listview .list dt:first-child{width:35%}
.ibox.set-spacing .listview .list dt{width:62%}
.left-panel.light.license_photo{width:100px;padding:10px;border:none}
.export-select{border:1px solid rgba(0,0,0,.15);border-radius:2px;margin-left:8px}
.export-select i{font-style:normal;margin-right:5px}
.export-select .ti-angle-down:before{font-size:12px;margin-left:10px}
.export-select a{padding:.5rem .75rem}
.form-control{color:#34495f}
.flexbox select.show-tick.form-control{-webkit-appearance:none;-moz-appearance:none;appearance:none;cursor:pointer;min-width:60px;position:relative;background:none}
.select-box{position:relative}
.select-box .ti-angle-down{position:absolute;right:10px;top:12px;font-size:12px}
.total-stats{display:inline-block;vertical-align:top;width:100%;background:#fafafa;padding:20px 15px;border:1px solid #e6e6e6;border-radius:2px 2px 0 0}
.total-stats .col{background:none;border:1px solid #e6e6e6;display:inline-block;border-radius:2px;line-height:25px;box-sizing:border-box;margin:5px;width:auto;background:#fff}
.total-stats .col a{display:block;padding:8px 0;box-sizing:border-box}
.total-stats .col span{margin-left:10px;font-size:18px;padding:0 5px 0 15px;padding:0 15px;border-left:1px solid #e6e6e6}
.total-stats .col.active{background:#4cb962;color:#fff}
.total-stats .col.active a,.total-stats .col.active span{color:#fff}
.country-selection .dropdown-menu-media{width:800px;padding:25px}
.checkbox,.radio{display:inline-block;position:relative;vertical-align:middle;padding:0 0 0 25px;height:20px;margin:10px 0}
label.checkbox,label.radio{cursor:pointer;font-weight:400!important;color:#717070!important;font-size:14px!important}
.checkbox input,.radio input{top:0;left:0;margin:0!important;z-index:1;cursor:pointer;opacity:0;filter:alpha(opacity=0)}
.radio input[type="radio"],.radio-inline input[type="radio"],.checkbox input[type="checkbox"],.checkbox-inline input[type="checkbox"]{position:absolute;margin-left:-20px;}
.checkbox .input-helper:before,.radio .input-helper:before,.checkbox .input-helper:after,.radio .input-helper:after{-webkit-transition:all;-o-transition:all;transition:all;-webkit-transition-duration:250ms;transition-duration:250ms;-webkit-backface-visibility:hidden;-moz-backface-visibility:hidden;backface-visibility:hidden;position:absolute;content:""}
.checkbox .input-helper:before,.radio .input-helper:before{left:0;border:1px solid #ccc}
.checkbox.disabled,.radio.disabled{opacity:.6;filter:alpha(opacity=60)}
.checkbox input{width:17px;height:17px}
.checkbox input:checked+.input-helper:before{-webkit-transform:scale(0);-ms-transform:scale(0);-o-transform:scale(0);transform:scale(0)}
.checkbox input:checked+.input-helper:after{-webkit-transform:scale(1) rotate(-50deg);-ms-transform:scale(1) rotate(-50deg);-o-transform:scale(1) rotate(-50deg);transform:scale(1) rotate(-50deg);opacity:1;filter:alpha(opacity=100)}
.radio input:checked+.input-helper:before{-webkit-transform:scale(0);-ms-transform:scale(0);-o-transform:scale(0);transform:scale(0)}
.radio input:checked+.input-helper:after{-webkit-transform:scale(1) rotate(-50deg);-ms-transform:scale(1) rotate(-50deg);-o-transform:scale(1) rotate(-50deg);transform:scale(1) rotate(-50deg);opacity:1;filter:alpha(opacity=100)}
.checkbox .input-helper:before,.radio .input-helper:before{top:1px;width:17px;height:17px}
.checkbox .input-helper:after,.radio .input-helper:after{opacity:0;filter:alpha(opacity=0);-webkit-transform:scale(0) rotate(80deg);-ms-transform:scale(0) rotate(80deg);-o-transform:scale(0) rotate(80deg);transform:scale(0) rotate(80deg);width:22px;height:9px;border-bottom:2px solid #282829;border-left:2px solid #282829;border-bottom-left-radius:2px;left:-1px;top:1px}
.country-selection ul{margin:10px 0;padding:0}
.country-selection ul li{display:inline-block;width:32%;vertical-align:top}
.heading-bar{display:inline-block;width:32%;vertical-align:top}
.heading-bar .radio{font-weight:500}
.heading-title{display:block;margin-bottom:5px;border-bottom:1px solid #e3e4ea;padding-bottom:10px;margin-top:10px}
.country-selection .col-list-right{margin-left:0}
.country-selection .buttons{margin-left:0;margin-left:0;border-top:1px solid #e3e4ea;padding-top:20px;margin-top:30px}
.dispute-bx .listview .list dt:first-child{width:40%}
.dispute-bx .listview .list dt{width:58%}
.dispute-bx{background:#fffafa;padding:15px;border:1px solid #ffe9e9}
.dispute-bx .buttons,.light .buttons{text-align:left}
.dispute-bx .buttons .common-btn,.light .buttons .common-btn{text-align:center}
.buttons.space-rm{margin-left:0;margin-top:5px}
.col-bx-top.dp-space{padding:20px}
.ibox.sticky-top{top:66px;position:sticky;z-index:1}
.actions-btn.bs-select-all{background:#34495f;color:#fff}
.form-control[readonly]{background-color:#fff!important;opacity:1}
.table-static .table td{padding:16px 10.5px}
.bank-detail .listview .list dt:first-child{width:30%}
.bank-detail .listview .list dt{width:68%}
.bank-detail .col-6:nth-child(even){padding-left:0}
.bank-detail .light .buttons{min-height:41px}
.tab-section{padding:0;border-radius:2px 2px 0 0;margin-bottom:5px;background:rgba(43,48,55,0.1);display:inline-block;z-index:9;border-left:0;border-right:0;border-radius:50px}
.tab-section ul{margin:0;padding:0}
.tab-section ul li{list-style:none;display:inline-block;min-width:175px}
.tab-section ul li a{padding:10px 30px;display:block;font-size:14px;position:relative;border-radius:50px;text-align:center}
.tab-section ul li a i{margin-right:5px}
.tab-section ul li.active a{background-color:rgba(40,45,51,0.98);color:#b4bcc8}
.ibox.sp-detail-set .listview .list dt:first-child{width:35%}
.ibox.sp-detail-set .listview .list dt{width:64%}
.modal-header{-webkit-flex-direction:row-reverse;flex-direction:row-reverse}
.modal-header,.modal-body,.modal-footer{padding:20px}
.slider.slider-horizontal{width:100%;height:10px}
.slider{display:inline-block;vertical-align:middle;position:relative}
.slider-track{position:absolute;cursor:pointer;background:#d6d6d6;background-repeat:repeat-x;-webkit-box-shadow:inset 0 1px 2px rgba(0,0,0,0.1);box-shadow:inset 0 1px 2px rgba(0,0,0,0.1);border-radius:2px}
.slider.slider-horizontal .slider-track{height:3px;width:100%;margin-top:-5px;top:50%;left:0}
.slider-handle{position:absolute;top:-5px;width:12px;height:12px;background-color:#fff;filter:none;-webkit-box-shadow:inset 0 1px 0 rgba(255,255,255,.2),0 1px 2px rgba(0,0,0,.05);box-shadow:inset 0 1px 0 rgba(255,255,255,.2),0 1px 2px rgba(0,0,0,.05);border:2px solid #34495f;cursor:pointer}
.slider-handle.round{border-radius:50%}
.slider.slider-horizontal .slider-tick,.slider.slider-horizontal .slider-handle{margin-left:-5px}
.slider.slider-horizontal .tooltip{-ms-transform:translateX(-50%);transform:translateX(-50%)}
.slider .tooltip.top{margin-top:-36px}
.slider.slider-horizontal .slider-selection,.slider.slider-horizontal .slider-track-low,.slider.slider-horizontal .slider-track-high{height:100%;top:0;bottom:0}
.slider.slider-horizontal .slider-selection,.slider.slider-horizontal .slider-track-low,.slider.slider-horizontal .slider-track-high{height:100%;top:0;bottom:0}
.slider-selection{position:absolute;background-image:-webkit-linear-gradient(top,#f9f9f9 0,#f5f5f5 100%);background-image:-o-linear-gradient(top,#f9f9f9 0,#f5f5f5 100%);background-image:linear-gradient(to bottom,#f9f9f9 0,#f5f5f5 100%);background-repeat:repeat-x;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;border-radius:4px;background:#34495f}
.slider .tooltip-inner{white-space:nowrap;max-width:none}
.tooltip-inner{max-width:200px;padding:3px 8px;color:#fff;text-align:center;background-color:#34495f;border-radius:2px}
.tooltip{position:absolute;z-index:1070;display:block;font-size:12px;font-style:normal;font-weight:400;font-family:'Poppins',sans-serif;line-height:1.42857143;text-align:left;text-align:start;text-decoration:none;text-shadow:none;text-transform:none;letter-spacing:normal;word-break:normal;word-spacing:normal;word-wrap:normal;white-space:normal;filter:alpha(opacity=0);opacity:0;line-break:auto}
.tooltip.in{filter:alpha(opacity=90);opacity:.9}
.tooltip.top{padding:5px 0;margin-top:-3px}
.tooltip.top .tooltip-arrow{bottom:0;left:50%;margin-left:-5px;border-width:5px 5px 0;border-top-color:#34495f}
.tooltip-arrow{position:absolute;width:0;height:0;border-color:transparent;border-style:solid}
.form-element label{font-weight:500;white-space:nowrap;-ms-transition:all 200ms ease 0;-webkit-transition:all 200ms ease 0;transition:all 200ms ease 0;color:#34495f;font-size:13px;letter-spacing:1px;margin-bottom:3px;display:block}
.form-element{position:relative;margin:15px 0;display:inline-block;width:100%}
.form-element.range-space{padding:0 5px}
.form-element.range-space label{margin:4px -5px}
.form-element ul{margin:0;padding:0}
.form-element ul li{list-style:none;display:inline-block}
.form-element ul.col-custome5 li{width:19%;vertical-align:top;padding-right:5px}
.form-element ul.col-custome4 li{width:24%;vertical-align:top;padding-right:5px}
.form-element ul.col-custome2 li{width:49%;vertical-align:top;padding-right:5px}
.form-element ul.col-custome3 li{width:32%;vertical-align:top;padding-right:5px}
.form-element ul.col-custome3 li label{white-space:inherit;height:auto}
.form-element .select2-container .select2-selection--single .select2-selection__rendered{background-color:#fff;background-image:none;background-clip:padding-box;border:1px solid rgba(0,0,0,.15);min-height:40px;box-shadow:none!important;border-radius:2px;padding:.65rem 1.25rem;padding:.5rem 1.25rem;line-height:inherit}
.select2-container--default .select2-selection--single .select2-selection__arrow{height:35px;right:5px}
.select2-search--dropdown{padding:10px;display:block}
.select2-search__field{border:1px solid rgba(0,0,0,.1);-webkit-border-radius:2px;border-radius:2px;padding:4px;width:100%;box-sizing:border-box}
.select2-container--default .select2-results__option,.select2-container--default .select2-results__option .select2-results__group{padding:6px 15px;width:100%;display:block}
.select2-container--default .select2-results__option .select2-results__group{font-weight:500}
.select2-container--default .select2-results__option{padding-left:30px}
.select2-container--default .select2-results__option[aria-selected=true]{background-color:#ebedee}
.select2-dropdown{border:1px solid #ebedee;-webkit-box-shadow:0 3px 12px rgba(0,0,0,.175);box-shadow:0 3px 12px rgba(0,0,0,.175)}
.datepicker .cw{font-size:10px;width:12px;padding:0 2px 0 5px;vertical-align:middle}
.datepicker table tr td.new,.datepicker table tr td.old{color:#999}
.datepicker table tr td,.datepicker table tr th{width:35px;height:35px}
.datepicker tfoot th,.datepicker thead th{font-weight:500}
.datepicker .datepicker-switch{width:145px}
.datepicker .datepicker-switch,.datepicker .next,.datepicker .prev,.datepicker tfoot tr th{cursor:pointer}
.datepicker table tr td,.datepicker table tr th{text-align:center;width:35px;height:32px;border-radius:0;border:none}
.datepicker table tr td.range-start,.datepicker table tr td.range-end{background:#f75a5f;color:#fff}
.datepicker table tr td.range{background:#ffb3b6}
.datepicker table tr td{cursor:pointer}
.datepicker thead th.next,.datepicker thead th.prev{font-size:0;font-family:themify;font-style:normal;font-variant:normal;text-transform:none;-webkit-font-smoothing:antialiased}
.datepicker thead th.prev::before{content:"\e64a";font-size:12px}
.datepicker thead th.next::before{content:"\e649";font-size:12px}
.datepicker table tr td span.active,.datepicker table tr td.today{background-color:#34495f!important;border-color:#34495f!important;color:#fff}
.input-group-addon{padding:0 10px;margin-bottom:0;font-size:1rem;font-weight:400;line-height:1.25;color:#495057;text-align:center;background-color:#e9ecef!important;border:1px solid #e9ecef;border-radius:.25rem}
.bootstrap-select.btn-group .dropdown-menu.inner{display:block}
.bootstrap-select.btn-group .dropdown-menu{min-width:100%;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;z-index:4}
.bootstrap-select.btn-group .dropdown-menu.inner{position:static;float:none;border:0;padding:0;margin:0;border-radius:0;-webkit-box-shadow:none;box-shadow:none}
.bootstrap-select>select{position:absolute!important;bottom:0;left:50%;display:block!important;width:.5px!important;height:100%!important;padding:0!important;opacity:0!important}
.bootstrap-select>.dropdown-toggle{width:100%;padding-right:25px;z-index:1;background:none;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;text-align:left;cursor:pointer}
.form-element.multiselect .form-control{padding:0}
.form-element.multiselect .dropdown-toggle{min-height:40px;border:0;background:none;text-align:left}
.bootstrap-select.btn-group .dropdown-toggle .caret{position:absolute;top:50%;right:12px;margin-top:-2px;vertical-align:middle}
.bs-actionsbox,.bs-donebutton,.bs-searchbox{padding:4px 8px}
.bs-actionsbox{width:100%;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
.bs-actionsbox .btn-group button{width:50%}
.bootstrap-select.btn-group .dropdown-menu li{position:relative;display:block}
.bootstrap-select.btn-group .dropdown-menu li.selected>a{background-color:#ebedee;color:inherit}
.bootstrap-select.btn-group .dropdown-menu li a{cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.bootstrap-select.btn-group .dropdown-menu.inner{position:static;float:none;border:0;padding:0;margin:0;border-radius:0;-webkit-box-shadow:none;box-shadow:none}
.btn-group-sm>.btn,.btn-sm{padding:.35rem .75rem;-webkit-border-radius:2px;border-radius:2px}
.bootstrap-select.btn-group .dropdown-menu li a span.check-mark{display:none}
.bootstrap-select .check-mark{top:50%;margin-top:-8px!important;font-family:themify;font-style:normal;font-weight:400;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased}
.bootstrap-select .check-mark:before{content:"\e64c"}
.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark{position:absolute;display:inline-block;right:15px;margin-top:5px}
.datepicker.dropdown-menu{margin-left:15px}
.datepicker.dropdown-menu.datepicker-orient-left{margin-left:0}
.modal-dialog.modal-sm{max-width:500px;text-align:center}
.modal-dialog.modal-sm .modal-title{display:block;text-align:center;width:100%}
.action-wrap.table-action a.disable{font-size:12px}
.tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before,.tooltip.bs-tooltip-left .arrow::before{border-left-color:#34495f}
.tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before,.tooltip.bs-tooltip-bottom .arrow::before{border-bottom-color:#34495f}
.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,.tooltip.bs-tooltip-top .arrow::before{border-top-color:#34495f}
.add-more-container{position:fixed;right:20px;bottom:15px;z-index:2}
.add-more-container a{width:50px;height:50px;border-radius:50%;display:block;background:#1E5147;-webkit-box-shadow:0 0 20px 4px rgba(0,0,0,.3);box-shadow:0 0 20px 4px rgba(0,0,0,.3);-webkit-transition:all .2s ease-out;-o-transition:all .2s ease-out;transition:all .2s ease-out;text-align:center;line-height:55px;font-size:20px;color:#b4bcc8}
.service-photo{width:100px;height:100px;text-align:center;position:absolute;cursor:pointer;top:0;left:0;outline:none;opacity:0;cursor:pointer;z-index:1}
.img-border{width:100px;height:100px;border:1px dashed rgba(0,0,0,.15);border-radius:2px;float:left;position:relative}
.photo-text{line-height:110px;text-align:center;font-size:12px}
.photo-text i{display:block;position:absolute;top:22px;left:0;right:0;text-align:center}
.form-element label i{font-style:normal;color:#f75a5f}
.form-element label.small-highlights{display:block;font-size:10px;width:100%;float:left;margin-top:4px;word-wrap:break-word;display:block;white-space:inherit}
.ibox.ibox-fullheight{max-width:900px;margin:0 auto}
.repeatedrow .action-wrap a.disable{font-size:14px}
.surge-status-form{width:100%;background:#f9f9f9}
.select-bx .form-control{padding:0;min-height:40px}
.form-element .date label{display:block}
.remove-wrap .repeatedrow .action-wrap a{border:1px solid #f75a5f;background:#f75a5f;color:#fff}
.remove-wrap .form-element{margin-bottom:0}
.surge_div{border-top:1px solid #ccc;padding:10px 0}
.bootstrap-select.btn-group .dropdown-menu.inner{max-height:250px!important;overflow:auto}
.form-cover{position:relative}
.price-label{float:right;position:absolute;right:15px;top:0;border-left:1px solid #ccc;padding:3px 0 3px 10px;font-weight:400;bottom:0;line-height:33px;font-size:12px}
.action-wrap.table-action .las{font-size:17px}
.sub-heading span{display:inline-block;font-weight:500;color:#34495f}
.sub-heading span.sub-detail{font-weight:400;font-size:12px;margin-right:10px}
textarea.form-control{padding:.5rem 0!important;border-bottom:1px solid #ced4da}
.table td{padding:0}
.table td a,.table .action-wrap.table-action{padding:17px 20px}
.table td a{display:block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}
.table td .action-wrap.table-action a{padding:0;display:inline-block;text-align:center!important;margin-right:10px}
.table td .action-wrap.table-action a i{line-height:35px;display:block}
.timeline .timeline-item{position:relative;padding:10px 15px 10px 25px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center}
.timeline .timeline-item .timeline-icon{position:absolute;height:20px;width:20px;left:-10px;text-align:center;line-height:20px;top:50%;margin-top:-10px;font-size:16px;background-color:#fff}
.timeline .timeline-item .timeline-icon:before{color:#adadad}
.nav.navbar-toolbar .slimScrollBar{width:4px!important}
.dropdown .dropdown-menu.admin-dropdown-menu{min-width:200px;transform:translate3d(0px,53.4px,0px)!important}
.header .dropdown .dropdown-menu{transform:inherit!important;top:50px!important;left:inherit!important;right:0!important}
.header .dropdown .dropdown-menu.admin-dropdown-menu{right:15px!important}
.header .admin-dropdown-menu .dropdown-header{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;padding:30px 40px}
.header .admin-dropdown-menu .admin-avatar{height:60px;width:60px;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg);overflow:hidden;margin-right:35px;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center}
.header .admin-dropdown-menu .admin-badge i{height:28px;width:28px;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;-webkit-border-radius:50%;border-radius:50%;background-color:#3a4046;color:#b4bcc8}
.dropdown-user .font-strong{color:#b4bcc8;font-weight:400!important;text-transform:uppercase}
.header .admin-dropdown-menu .admin-avatar img{width:88px;max-width:88px;-webkit-transform:rotate(-45deg);-ms-transform:rotate(-45deg);transform:rotate(-45deg)}
.header .admin-dropdown-menu .admin-badge{color:#b4bcc8}
.header .admin-dropdown-menu .admin-menu-features{padding:25px 0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex}
.header .admin-dropdown-menu .admin-features-item{width:50%;text-align:center;color:#71808f}
.admin-menu-content .h1{font-size:2em!important}
.header .admin-dropdown-menu .admin-features-item:not(:last-child){border-right:1px solid rgba(0,0,0,.1)}
.header .admin-dropdown-menu .admin-features-item i{display:block;font-size:36px;margin-bottom:16px}
.pull-right{float:right}
.header .admin-dropdown-menu .admin-menu-content{padding:10px 30px 30px;border-top:1px solid rgba(0,0,0,.1)}
.text-light{color:#71808f!important}
.header .admin-dropdown-menu .admin-features-item i.ti-view-grid{min-height:30px;font-size:30px;margin-top:5px}
.header .admin-dropdown-menu h1{color:#34495f}
.slimScrollBar{width:0!important}
.sub-heading{margin-bottom:5px;color:#868e96;margin-top:-20px}
.sub-heading.no-margin{margin-bottom:5px!important}
.dropdown-menu .dropdown-divider{margin:0;border:1px solid #EFEFEF}
.ibox-tools ul{margin-bottom:0}
.ibox-tools ul li{list-style:none;display:inline-block;margin-left:5px;vertical-align:top}
.ibox-tools .select2-container{background-color:#f4f5f9;border-color:#f4f5f9;border-radius:50px}
.ibox-tools .select2-container .select2-selection--single .select2-selection__rendered{line-height:40px;padding-left:20px}
.ibox-tools ul li .form-control,.filter-stats .form-control,.reports-stats .form-control{padding:0;background-color:#f4f5f9;border-color:#f4f5f9;border-radius:50px;min-width:100px;border:0;min-height:38px}
.ibox-tools ul li .dropdown-menu ul li{margin-left:0}
.cicrle_select{width:37px;height:40px;background:#f4f5f9;border-radius:50%;padding:0;text-align:center;cursor:pointer;display:block;line-height:40px}
.ibox-tools ul li .col-list-right{margin-left:0}
.ibox-tools ul li .col-list-right .las.la-sort{font-size:18px}
.list-group .stars{display:inline-block}
.ibox-body .list-group.height-auto{min-height:auto}
#chart{width:100%;height:120px;display:block}
#chart #numbers{width:50px;height:100%;margin:0;padding:0;display:inline-block}
#chart #numbers li{text-align:right;padding-right:1em;list-style:none;height:29px;border-bottom:1px solid #444;position:relative;bottom:30px}
#chart #numbers li:last-child{height:30px}
#chart #numbers li span{color:#eee;position:absolute;bottom:0;right:10px}
#chart #bars{display:inline-block;width:100%;height:120px;padding:0;margin:0}
#chart #bars li{display:inline-block;width:19%;height:120px;margin:0;text-align:center;position:relative}
#chart #bars li .bar{display:block;width:40px;margin-right:15px;background:#1E5147;position:absolute;bottom:0}
#chart #bars li .bar:hover{cursor:pointer}
#chart #bars li .bar:before{content:attr(data-percentage)'%';position:relative;bottom:20px}
#chart #bars li span{width:100%;position:absolute;bottom:-2em;left:0;text-align:center}
.rating-section .list-group .list-group-item{padding:13px 0}
.rating-section .list-group{margin-top:15px}
.set-graph{min-height:200px}
.filter-stats .card{min-height:auto;box-shadow:inherit;overflow:inherit}
.filter-stats .card-body{padding:20px 15px 20px 5px;border-right:1px solid #eee}
.filter-stats .btn{text-align:left}
.filter-stats .col-md-3.px-2:last-child .card-body{border-right:0}
.filter-stats .col-md-4.px-2:last-child .card-body{border-right:0}
.reports-stats .col-full{width:100%}
.reports-stats .col-full .btn{text-align:left}
.reports-stats .card{overflow:inherit}
.reports-stats .card-body.text-primary .easypie i{font-size:28px}
.reports-stats .card-body.text-success .easypie i{font-size:24px}
.investor .col-lg-4.fixed-height .card{background-clip:border-box;border-radius:.25rem;height:calc(100% - 20px);padding:10px 5px;position:relative;display:flex;flex-direction:column;color:#fff;align-items:center;justify-content:center;background:#fff}
.investor .col-lg-4.fixed-height .card-body{width:100%}
.investor .col-lg-4.fixed-height .full{width:100%;text-align:center}
.investor .set-graph{min-height:185px}
.investor .card-body.text-primary .easypie i{font-size:26px}
.investor .grid-5{width:100%;margin:0;padding:0}
.investor ul[class*="grid-"]{margin:0 -10px -15px;width:calc(100% + 15px);display:flex;vertical-align:top;flex-wrap:wrap}
.investor .grid-5 li{width:20%;padding:0 10px 15px;list-style:none}
.wrap-countries{display:inline-block;vertical-align:top;width:100%}
.wrap-countries{background:#fff;border-radius:.25rem;height:100%;position:relative;overflow:hidden}
.wrap-countries .countriesName{font-weight:400;margin-top:10px}
.wrap-countries img{border-radius:.25rem}
.wrap-countries .grid2{min-height:50px;padding-left:40px;padding-top:7px}
.wrap-countries .ranking{width:30px;height:35px;color:#d8d8d8;text-align:center;line-height:28px;font-weight:400;display:inline-block;vertical-align:middle;margin-right:7px}
.wrap-countries .grid2 h4{font-size:14px}
.progress-container{padding-bottom:20px}
.progressWrap{display:block;width:calc(100% - 150px);padding:20px 0 0}
.progressWrap .progressBar{background-color:#84d9d2;position:relative;border-radius:0 2px 2px 0;height:25px}
.progressWrap .progressBar span{position:absolute;font-size:14px;line-height:15px;left:100%;width:150px;margin-top:0;padding-left:5px;font-weight:400}
.progressWrap h4{color:#999;font-size:14px;padding-bottom:0;font-weight:400}
.progressWrap .progressBar.red{background-color:#fd9fa2}
.progressWrap .progressBar.green{background-color:#84d9d2}
.progressWrap .progressBar.yellow{background-color:rgba(251,184,79,0.7)}
.progressWrap .progressBar.grey{background-color:rgba(40,45,51,0.8)}
.hide-btn .form-element.multiselect .dropdown-toggle{height:35px}
.sub-heading:after{content:'';display:table;width:100%}
.col-list-right.color-wh{float:right;margin-top:0;margin-bottom:8px}
.col-list-right.color-wh .cicrle_select{background:#fff;font-size:20px;box-shadow:0 6px 0 0 rgba(0,0,0,.01),0 15px 32px 0 rgba(0,0,0,.06)}
.col-list-right.color-wh .circle{background:#fff}
.col-list-right.color-wh ul{margin:0;padding:0}
.col-list-right.color-wh ul li{display:inline-block;vertical-align:top;margin-left:2px;position:relative}
.col-list-right.color-wh ul li.hide-year{display:none}
.col-list-right.color-wh ul li.hide-year.show{display:inline-block}
.col-list-right.color-wh ul li .form-control{padding:0;background-color:#fff;border-color:#fff;border-radius:50px;min-width:100px;border:0;min-height:40px;box-shadow:0 6px 0 0 rgba(0,0,0,.01),0 15px 32px 0 rgba(0,0,0,.06)!important}
.col-list-right.color-wh ul li.hide-year .bootstrap-select .btn{padding:9px 14px}
.tab-outer{max-width:900px;margin:0 auto;vertical-align:top}
.fa-angle-left.arrow{font-family:'themify';font-style:normal;font-weight:400;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;font-size:17px;font-weight:600}
.fa-angle-left.arrow:before{content:"\e64a"}
.MultiCarousel{float:left;overflow:hidden;width:100%;position:relative;padding-top:70px;margin-top:-70px}
.MultiCarousel .MultiCarousel-inner{transition:1s ease all;float:left}
.MultiCarousel .MultiCarousel-inner .item{float:left}
.MultiCarousel .MultiCarousel-inner .item>div{padding:0 10px;margin:0}
.MultiCarousel .leftLst,.MultiCarousel .rightLst{position:absolute;border-radius:50%;top:0;background:#f4f5f9}
.MultiCarousel .leftLst{right:50px}
.MultiCarousel .rightLst{right:10px}
.MultiCarousel .leftLst.over,.MultiCarousel .rightLst.over{pointer-events:none;background:#f4f5f9;color:#d8d8d8}
.MultiCarousel .btn{width:36px;height:36px;line-height:36px;padding:0;text-align:center;border:0;cursor:pointer;color:#34495f}
.fa.fa-calendar{font-family:'Line Awesome Free';font-weight:900;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;display:inline-block;font-style:normal;font-variant:normal;text-rendering:auto;line-height:1;font-size:20px}
.fa.fa-calendar:before{content:"\f133"}
.datepicker table tr td span{display:block;min-width:23%;height:40px;line-height:40px;float:left;margin:1%;cursor:pointer;border-radius:2px}
.col-md-12.set-graph{max-width:200px;margin:0 auto}
.job-container .row{flex-direction:row}
.pagination{margin-top:30px}
.country-selection label.checkbox,label.radio{height:auto;word-wrap:break-word;display:block;white-space:inherit}
.listview dl:after{content:'';display:table;width:100%}
.no-margin{margin:0!important;margin-bottom:0}
.close-btn{float:right;width:32px;height:32px;text-align:center;line-height:32px;cursor:pointer;display:none}
.report .ibox .ibox-head .ibox-title{margin-right:0}
.dropdown-item .ti-printer{margin-right:10px}
.select-box .show-tick.form-control{padding:10px 25px 10px 10px}
.ibox-tools ul li.services .form-control.bootstrap-select{min-width:200px;max-width:200px}
.filter-option.pull-left{display:inline-block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;width:100%}
.ibox-tools ul li .bs-searchbox .form-control{padding:0;background-color:#fff;border:1px solid #e2e2e2;border-radius:2px;padding:0 8px}
.ibox-tools ul li.services .bootstrap-select.btn-group .dropdown-menu{max-width:200px}
.bootstrap-select.btn-group .dropdown-menu li.hidden{display:none}
.bootstrap-select.btn-group .dropdown-menu li.no-results{padding:8px 10px}
.bootstrap-select{border:1px solid rgba(0,0,0,.15);transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;width:100%;border-radius:2px;min-height:39px}
.btn.focus,.btn:focus{box-shadow:inherit}
.dropdown.mobile-dropdown{border:1px solid #e6e6e6;display:inline-block;border-radius:2px;line-height:25px;box-sizing:border-box;margin:-5px 0 20px;width:100%;background:#fff}
.dropdown.mobile-dropdown{background:rgba(43,48,55,0.1);border-radius:2px;display:none}
.dropdown.mobile-dropdown a{position:relative}
.dropdown.mobile-dropdown .ti-angle-down{font-size:12px;position:absolute;top:13px;right:16px}
.dropdown.mobile-dropdown .dropdown-item i{margin-right:6px}
.dropdown.mobile-dropdown .dropdown-menu{width:100%}
#sound1{-webkit-animation:sound1 2s ease-out infinite;animation:sound1 2s ease-out infinite;opacity:0;-o-animation-delay:100ms;-moz-animation-delay:100ms;-webkit-animation-delay:300ms;animation-delay:100ms}
@-webkit-keyframes sound1 {
0%{opacity:0}
40%{opacity:1}
}
@keyframes sound1 {
0%{opacity:0}
40%{opacity:1}
}
#sound2{-webkit-animation:sound2 2s ease-out infinite;animation:sound2 2s ease-out infinite;opacity:0;-o-animation-delay:700ms;-moz-animation-delay:700ms;-webkit-animation-delay:700ms;animation-delay:700ms}
@-webkit-keyframes sound2 {
0%{opacity:0}
50%{opacity:1}
}
@keyframes sound2 {
0%{opacity:0}
50%{opacity:1}
}
#sound3{-webkit-animation:sound3 2s ease-out infinite;animation:sound3 2s ease-out infinite;opacity:0;-o-animation-delay:1100ms;-moz-animation-delay:1100ms;-webkit-animation-delay:1100ms;animation-delay:1100ms}
@-webkit-keyframes sound3 {
0%{opacity:0}
50%{opacity:1}
}
@keyframes sound3 {
0%{opacity:0}
50%{opacity:1}
}
.posDown.open .arrow{transform:rotate(-90deg);-webkit-transform:rotate(-90deg);-moz-transform:rotate(-90deg);-ms-transform:rotate(-90deg)}
.posDown .collapse.open{display:block!important;width:100%;background-color:#333}
.posDown .collapse.open li a.active,.posDown.open{background-color:#333}
.c-donutchart .c-donutchart__legends{display:block!important;justify-content:left!important}
.c-donutchart{width:100%!important;margin:17px auto;text-align:center}
.c-donutchart__legend{display:block!important;flex-flow:column!important;align-items:flex-start!important;margin:0!important;padding:20px 0}
.c-donutchart__legend-bar{display:none!important}
.c-donutchart .c-donutchart__legend-percentage,.c-donutchart .c-donutchart__legend-title{display:inline-block}
.c-donutchart .c-donutchart__legend-title{float:left!important}
.c-donutchart .c-donutchart__legend-percentage{float:right!important}
.modal-body .form-element label{text-align:left}
.width100_margin0{width:100%;margin-left:0}
.circleIcon{border-radius:25px;width:10px;height:10px;background:#1E5147;padding:5px;color:#fff}
.outer_location{padding:20px}
.col-list-right.width80{width:65%}
.col-bx-top button.common-btn{float:right;border-radius:35px}
#example_length{display:none!important}
#example_filter{display:none!important}
.sorting_disabled:after{opacity:0!important}
.sorting_disabled:before{opacity:0!important}
.nodatadiv p{color:#151515;font-weight:600;font-size:16px;padding-bottom:10px}
.nodatadiv{display:flex;align-items:center;justify-content:center;flex-direction:column;padding:40px;height:300px}
.nodatadiv .commonBtn{width:fit-content;background:#fff;color:#EF7E2B;border:1px solid #EF7E2B}
a.nav-link.dropdown-toggle{padding-right:0!important}
.iboxcontent h3{color:#151515;font-weight:600;font-size:18px}
.progress{margin-top:48px}
.progress .progress-bar{background:#EF7E2B;height:10px;border-radius:10px}
.post{margin-top:20px}
.post .grid{display:flex}
.post .card{background-color:#fff;position:relative;box-shadow:none;border:1px solid #c3c3c3;border-radius:12px;cursor:pointer;padding:20px}
.post .radio{cursor:pointer;position:absolute}
.post .radio:checked{background:#EF7E2B;border:1px solid #EF7E2B;overflow:hidden;box-shadow:none}
.plan-type{color:#151515;font-size:16px;font-weight:600;margin-bottom:10px}
.plan-cost{color:#373737;font-weight:400;font-size:14px}
.table-wrapper.user-top{margin-top:10px!important}
.table-wrapper{text-align:left;border-radius:18px;position:relative;margin-bottom:0;font-size:14px;width:100%;background:#fff;margin-top:40px}
.table-wrapper table{border-collapse:collapse}
.table-wrapper th,tfoot th{text-transform:capitalize;padding:17px 20px;letter-spacing:.91px;letter-spacing:0;color:#fff;background:#151515;border-bottom:1px solid #ECECEC}
.table-wrapper tfoot th{background:#676767;font-weight:600}
.table-wrapper tr{border:none!important}
.table-wrapper td span{letter-spacing:.38px;color:#1F1F1F;font-weight:500;display:block;border-radius:50px}
.table-wrapper td a{color:#1F1F1F;font-weight:500;cursor:pointer;text-transform:capitalize}
.table-wrapper thead th:first-child{border-radius:18px 0 0 0!important}
tfoot th:first-child{border:none!important}
.table-wrapper th:first-child,.table-wrapper td:first-child,tfoot th:first-child{width:150px}
.table-wrapper th.width-auto{width:300px!important}
.table-wrapper td.width-auto{width:300px!important}
.table-wrapper th:last-child,.table-wrapper td:last-child{width:150px}
.table-wrapper thead th:last-child{border-radius:0 18px 0 0!important;border:none!important}
.page-content .heading-6{font-size:14px;text-transform:capitalize}
.ft{height:12px;vertical-align:middle}
.planbtn.greyBtn{border:1px solid #727272;border-radius:12px;text-align:center;color:#727272}
.iboxcontent{position:relative}
.stepsnumber{position:absolute;right:0;top:0}
.customradiobox .custom_radio{margin:10px 0}
.customradiobox .custom_radio input[type="radio"]{display:none}
.customradiobox .custom_radio input[type="radio"]+label{position:relative;display:inline-block;padding-left:1.5em;margin-right:4em;cursor:pointer;line-height:1em;-webkit-transition:all .3s ease-in-out;transition:all .3s ease-in-out}
.customradiobox .custom_radio input[type="radio"]+label:before,.customradiobox .custom_radio input[type="radio"]+label:after{content:'';position:absolute;top:0;left:0;width:1em;height:1em;text-align:center;color:#fff;font-family:Times;border-radius:50%;-webkit-transition:all .3s ease;transition:all .3s ease}
.customradiobox .custom_radio input[type="radio"]+label:before{-webkit-transition:all .3s ease;transition:all .3s ease;box-shadow:inset 0 0 0 .2em #E3E3E3,inset 0 0 0 1em #E3E3E3}
.customradiobox .custom_radio input[type="radio"]+label:hover:before{-webkit-transition:all .3s ease;transition:all .3s ease;box-shadow:inset 0 0 0 .3em #E3E3E3,inset 0 0 0 1em #E3E3E3}
.customradiobox .custom_radio input[type="radio"]:checked+label:before{-webkit-transition:all .3s ease;transition:all .3s ease;box-shadow:inset 0 0 0 .2em #fff,inset 0 0 0 1em #EF7E2B}
.to{color:#151515;font-weight:600;padding:0 35px;margin-top:-10px}
.showmore{background:#FFF 0 0 no-repeat padding-box;border:1px solid #EF7E2B;border-radius:27px;padding:7px 10px;color:#EF7E2B}
.showmore img{height:7px;vertical-align:middle}
.borderBtn.planbtn.wdth{width:fit-content;background:#fff}
.input-group-icon .input-icon{position:absolute;left:0;top:0;height:30px;line-height:30px;width:34px;text-align:center;cursor:pointer}
.input-group-icon .form-control{padding-left:33px;padding-right:33px;border:1px solid #D6D6D6;border-radius:12px!important}
.commonBtn2{width:100%;background:#EF7E2B;background:-moz-linear-gradient(left,#EF7E2B 0%,#EF7E2B 100%);background:-webkit-linear-gradient(left,#EF7E2B 0%,#EF7E2B 100%);background:linear-gradient(to right,#EF7E2B 0%,#EF7E2B 100%);color:#fff;font-size:1em;font-weight:400;border-radius:8px;padding:5px 10px;display:inline-block;vertical-align:top;cursor:pointer;text-align:center;-webkit-transition:.3s all ease-in-out;-o-transition:.3s all ease-in-out;transition:.3s all ease-in-out}
.ap{margin-right:2px;height:20px}
.tableimg{float:right}
.table-wrapper td a.jobid{text-decoration:underline;color:#EF7E2B}
.table-wrapper td a.jobid.green{text-decoration:none;color:#5FC76A}
.table-wrapper td a.jobid.red{text-decoration:none;color:#EB4D4D}
td .dropdown-menu{border-radius:4px;padding:5px 0;-webkit-animation-name:dropdown-animate;animation-name:dropdown-animate;-webkit-animation-duration:.2s;animation-duration:.2s;-webkit-animation-fill-mode:both;animation-fill-mode:both;border:0;box-shadow:0 5px 30px 0 rgba(82,63,105,0.2)}
.table-wrapper .dropdown-menu.show{transform:translate3d(-100px,4px,0px)!important}
.table-wrapper table tr:last-child .dropdown-menu.show,.table-wrapper table tr:nth-last-child(2){bottom:0!important;top:auto!important}
.pagination li a{border:1px solid #C7C8C3;border-radius:6px;padding:10px 5px;display:block;width:40px;height:40px;text-align:center;margin:0 auto}
.pagination li a.prev,.pagination li a.next{border:1px solid #DAD8D8;border-radius:6px;padding:10px 5px;width:90px}
.pagination li a.active{background:#EF7E2B 0 0 no-repeat padding-box;border:1px solid #EF7E2B;border-radius:6px;color:#fff}
.post .customradiobox{background:#FFF 0 0 no-repeat padding-box;border:1px solid #C3C3C3;border-radius:12px;padding:20px}
.post .customradiobox .custom_radio .active{border:1px solid #EF7E2B!important}
.post .customradiobox .custom_radio{margin:5px 0}
.post .customradiobox .custom_radio input[type="radio"]+label{padding-left:2.5em}
.post .customradiobox .custom_radio input[type="radio"]+label:before,.post .customradiobox .custom_radio input[type="radio"]+label:after{height:1.5rem;width:1.5rem;top:4px}
.start{height:600px}
.table-wrapper td span input{display:block;position:absolute;top:12px;right:50px;width:100%;visibility:hidden;cursor:pointer}
.action-wrap.table-action a.selected{background-color:#EF7E2B;color:#fff}
.table-wrapper td a.selected span{color:#fff}
.questiondiv{border:.5px solid #C3C3C3;border-radius:20px;padding:20px;margin-bottom:20px}
.question{position:relative}
.question span{position:absolute;right:0;top:-5px}
.questiondiv span img{height:30px}
.questiondiv .para5{float:right}
.questiondiv p{font-size:14px}
.questiondiv .question{color:#151515;font-weight:600;font-size:14px;margin-bottom:20px}
.questiondiv{border:.5px solid #C3C3C3;border-radius:20px;padding:20px;margin-bottom:20px}
.question{position:relative}
.question span{position:absolute;right:0;top:-5px}
.questiondiv span img{height:30px}
.questiondiv .para5{float:right}
.questiondiv p{font-size:14px}
.questiondiv .question{color:#151515;font-weight:600;font-size:14px;margin-bottom:20px}
.selected-template{padding:20px;display:flex;background:#FFF5EE 0 0 no-repeat padding-box;border:1px solid #EF7E2B;border-radius:10px;align-items:center;margin:10px 0}
.selected-template .content h6{font-weight:600;letter-spacing:0;color:#151515;font-size:14px}
.selected-template .content p{font-weight:400;letter-spacing:0;color:#151515;font-size:10px}
.selected-template img{margin-right:10px}
.templatesection{border:1px solid #D1D1D1;border-radius:20px;padding:20px;margin:20px 0}
.heading-4{font-weight:600;font-size:16px;color:#151515;text-transform:capitalize}
.et{height:14px;margin-left:6px;vertical-align:middle}
.templatesection .sub-detail{margin:20px 0;font-weight:600;font-size:14px;color:#151515}
.addedskills li{font-weight:500}
.stepsdes .addedskills li{border:1px solid #D7D7DC;background-color:#F8F8F8}
.stepsdes p{color:#424245}
.para9{color:#424245;font-weight:400;margin-bottom:20px}
.stepsdes .para9 .oraange{text-decoration:underline}
.recruiterpanellayout .time{background:none;border:none}
.recruiterpanellayout .input-icon img{vertical-align:middle}
.page-content .recruiterpanellayout .heading-6{font-size:20px;margin-bottom:10px}
.ibox-tools ul li{vertical-align:middle}
.ibox-tools{display:flex;align-items:center}
.basicbk{border:.5px #F8F8F8;background:#F8F8F8 0 0 no-repeat padding-box;border-radius:12px;padding:10px 20px;display:flex;justify-content:space-between}
.basicbk .heading-6{margin-top:10px}
.basicbk .para6{color:#151515;width:100%}
.on-off .slider{position:absolute;cursor:pointer;top:-1px;left:0;right:0;bottom:0;background-color:#ccc;-webkit-transition:.4s;transition:.4s;height:18px}
.on-off .slider:before{position:absolute;content:"";height:18px;width:18px;left:0;bottom:0;background-color:#fff;-webkit-transition:.4s;transition:.4s}
.on-off .table-bordered td,.table-bordered th{border:none;font-size:14px}
.on-off .table-striped tbody tr:nth-of-type(odd){background-color:transparent}
.on-off input:checked+.slider{background-color:#EF7E2B}
.on-off input:focus+.slider{box-shadow:0 0 1px #2196F3}
.on-off input:checked+.slider:before{-webkit-transform:translateX(26px);-ms-transform:translateX(26px);transform:translateX(22px)}
.on-off select option{color:#444!important}
.on-off .slider.round{border-radius:34px}
.on-off .slider.round:before{border-radius:50%}
.green{font-weight:600;color:#5FC76A;font-size:16px}
.on-off h6{font-size:14px}
.on-off{display:flex;border:.5px solid #AAA;border-radius:12px;padding:27px 5px;align-items:center;justify-content:center}
.switch{position:relative;display:inline-block;width:40px;height:20px;margin:0 0 0 10px;box-shadow:none;padding:0}
.table-bordered td:nth-child(2){text-align:center}
.switch input{opacity:0;width:0;height:0}
.recruiterpanel .job-list{margin:20px 0}
.recruiterpanel .job-list.nav>li>a{color:#151515;font-weight:500;text-transform:capitalize}
.recruiterpanel .job-list>li.active>a,.recruiterpanel .job-list>li.active>a:hover,.recruiterpanel .job-list>li.active>a:focus{color:#EF7E2B}
.action-wrap.table-action a span{color:#EF7E2B}
.editbtn{color:#EF7E2B;border:1px solid #EF7E2B;border-radius:35px;background:#fff;padding:8px 12px;color:#EF7E2B;width:90px;text-align:center}
.editbtn .ht{height:15px;display:inline-block;vertical-align:middle}
.innerdes{margin:20px 0 10px}
.align-items-baseline .innerdes {margin:0px 0 15px}
.innerdes .para6{width:100%}
.innerdes .heading-6{margin-top:10px;font-weight:500}
.innerdes .addedskills li{list-style:none;display:inline-block;padding:5px 10px;font-size:14px;border-radius:50px;margin:3px 3px 3px 0;border:0}
.basicdetails .heading-6{margin:15px 0 0;padding:0}
.basicdetails .para6{width:100%;margin-bottom:5px;margin-top:2px}
.innerdes p.para6{margin:0px 0 15px}
.recruiterpanel .basicbox{box-shadow:0 1px 30px #00000008;border:1px solid #D1D1D1;border-radius:20px;padding:20px}
.contentjob .basicdetails .addedskills li{background:#F8F8F8 0 0 no-repeat padding-box;border:1px solid #D7D7DC;border-radius:30px;padding:10px;list-style:none;display:inline-block;font-size:14px;margin:20px 3px 3px 10px;color:#151515}
.contentjob .el_details{margin-bottom:30px}
.education_added{width:100%}
.education_added .timeline{margin:0;padding:0;padding-left:40px}
.education_added .timeline li{position:relative}
.contentjob .timeline:before{position:absolute;display:block;content:'';width:1.5px;height:60%;top:8px;bottom:0;left:0;background-color:transparent}
.contentjob .timeline li span.timeline-point-circle.fill{background-color:#FE831D}
.contentjob .timeline li span.timeline-point-circle{height:12px;width:12px;background-color:#727272;-webkit-border-radius:100%;border-radius:100%;position:absolute;left:-45px;top:8px;display:inline-block}
.el_details p{display:flex;align-items:baseline;color:#373737}
.ft-wt{font-weight:600}
.customfileupload{height:80px;width:80px;position:relative;margin-right:10px}
.customfileupload span img{width:80px;height:80px;background:#F0F0F0 0 0 no-repeat padding-box;border:1px dashed #C3C3C3;border-radius:10px;position:relative;overflow:hidden;margin:10px 0}
.el_details .blue-text{color:#4691F7;font-size:14px;font-weight:400;text-decoration:underline;display:block}
.expinner{background:#FAFAFA 0 0 no-repeat padding-box;border-radius:8px;padding:10px 20px;margin-bottom:20px}
.expinner h6{font-size:16px;font-weight:600;letter-spacing:0;color:#151515;display:flex;justify-content:space-between}
.expinner p{color:#373737;font-size:12px;font-weight:400;margin:5px 0}
.time{background:#FFF7F1 0 0 no-repeat padding-box;border:1px solid #F8D6B9;margin-left:6px;border-radius:13px;padding:2px 10px;font-size:10px;line-height:1.4;color:#151515;font-weight:500}
.whiteBox .profileupload{display:flex;flex:1.3344}
.whiteBox .profileupload .pofiledetailinner{flex:1.5023}
.profileupload .avatar-preview{width:100px;height:100px;position:relative;display:inline-block;vertical-align:middle;margin-right:5px;overflow:hidden;border-radius:50%;border:1px solid #ddd}
.badge{margin-top:5px;border:1px solid #EFEFEF;border-radius:2px;padding:5px 10px;display:flex;align-items:center}
.badge img{height:20px;margin-right:10px}
.joblist1{display:flex}
.joblist2{display:flex;justify-content:end;flex:auto}
.col-list-right.color-wh{float:right;margin-top:0;margin-bottom:8px}
.color-wh .planbtn{width:fit-content}
textarea.form-control{border:none;border-bottom:1px solid #ccc;border-radius:0;padding:.375rem 0;outline:none;padding-right:30px}
.whiteboxinner{display:flex}
.job-list{border-bottom:1px solid #ddd}
.job-list.nav-tabs>li>a{margin-right:2px;line-height:1.42857143;border:1px solid transparent;border-radius:4px 4px 0 0}
.job-list.nav>li>a{position:relative;display:block;padding:10px 30px}
.job-list.nav>li>a{padding-left:0;margin-right:30px;padding-right:0}
.job-list>li.active>a,.job-list>li.active>a:hover,.job-list>li.active>a:focus{color:#EF7E2B;cursor:pointer;font-weight:500;border-bottom:1px solid #EF7E2B}
.jobs .applybtn{padding:6px 10px;text-align:center;width:120px}
.jobs .right-info{position:relative}
.jobs .right-info .featured{position:inherit}
.para11{font-weight:500!important}
.suggestedskills li.showmore{background:#FFF 0 0 no-repeat padding-box;border:1px solid #EF7E2B;border-radius:27px;padding:7px 10px;color:#EF7E2B}
.suggestedskills li.showmore:after{background:none}
.jobdeslist li{align-items:baseline;display:flex;font-size:16px;font-weight:400;color:#373737}
.jobdeslist li img{margin-right:5px}
.innerdes .addedskills li{list-style:none;display:inline-block;padding:5px 10px;font-size:14px;border-radius:50px;margin:3px 3px 3px 0;border:0;background:#F8F8F8 0 0 no-repeat padding-box}
.recruiterpanel .whiteBox{background:transparent;box-shadow:none;border-radius:none;padding:0;margin-bottom:0}
.heading8{font-size:18px;margin-bottom:10px;color:#151515}
.heading8 span strong{color:#151515}
.breadcrumb-item.active{color:#FE831D}
.contentjob .education_added .timeline:before{background-color:transparent}
.contentjob .whiteBox{margin:20px 0}
.contentjob .el_details{margin-bottom:30px}
.education_added{width:100%}
.education_added .timeline{margin:0;padding:0;padding-left:40px}
.education_added .timeline li{position:relative}
.contentjob .timeline:before{position:absolute;display:block;content:'';width:1.5px;height:60%;top:8px;bottom:0;left:0;background-color:transparent}
.contentjob .timeline li span.timeline-point-circle.fill{background-color:#FE831D}
.contentjob .timeline li span.timeline-point-circle{height:12px;width:12px;background-color:#727272;-webkit-border-radius:100%;border-radius:100%;position:absolute;left:-45px;top:8px;display:inline-block}
.table>tbody tr.backgroundtable,.table>tbody tr.nb{background:#F8F8F8}
.table>tbody tr.backgroundtable td{border-bottom:1px solid transparent}
.table>tbody>tr.backgroundtable>td{border-top:1px solid #fff!important}
.table>tbody tr.nb{border-bottom:4px solid #fff}
.ibox-tools ul li .form-control,.filter-stats .form-control,.reports-stats .form-control{padding:10px;background-color:#fff;border:.5px solid #C3C3C3;border-radius:10px;min-width:120px;width:160px!important}
.mrt{margin-right:15px}
.modal textarea.form-control{padding:0!important}
td .dropdown a img{vertical-align:middle;margin-left:10px}
.paddingside{padding:40px 0}
.jobinnerid{display:flex;justify-content:space-between}
.paddingside .metismenu li{border-bottom:1px solid #EFEFEF;padding:20px 0}
.commonBtn2:hover{color:#fff}
.borderrt th{border-right:1px solid}
.table .nb td a{overflow:none!important;text-overflow:none!important;word-wrap:break-word!important;white-space:wrap!important}
.loginPage.margin-top-2{margin-top:25px}
.verificationWrap p{color:#373737;font-weight:400}
.verificationWrap h2+p{margin-bottom:25px}
table tbody td .dropdown-menu::before{display:none}
@media (max-width: 1599px) {
.verificationWrap h2+p{margin-bottom:18px}
}
@media (max-width: 1400px) {
#chart #bars li{width:18%}
}
@media (max-width: 1230px) {
.adminca-banner{display:inline-block}
.wrap-1{display:block}
.wrap-2{margin-bottom:20px}
.dev-img{position:static}
.wrap-3{-webkit-box-pack:start!important;-webkit-justify-content:start!important;-ms-flex-pack:start!important;justify-content:start!important}
}
@media (max-width: 1200px) {
body:not(.fixed-layout).sidebar-mini .page-brand{width:200px;padding:0 15px}
body:not(.fixed-layout).sidebar-mini .brand-mini{display:none!important}
body:not(.fixed-layout).sidebar-mini .brand{display:block}
.header .page-brand{width:200px}
.page-sidebar{width:1px}
body:not(.fixed-layout).sidebar-mini .page-sidebar,body:not(.fixed-layout).sidebar-mini .page-sidebar:hover{width:100%;margin-top:56px;background:rgba(0,0,0,0.5)}
.page-sidebar ul li{margin:7px 10px}
.page-sidebar #sidebar-collapse{width:230px;padding:15px 0;background:#fff;min-height:100%}
.side-menu .heading{display:none}
body:not(.fixed-layout).sidebar-mini .side-menu>li>a>.nav-label{display:block}
.side-menu li .arrow{right:5px}
.content-wrapper,body:not(.fixed-layout).sidebar-mini .content-wrapper{margin-left:0;padding-bottom:20px}
.page-sidebar:hover .side-menu .heading{display:block}
.page-sidebar:hover .side-menu li .nav-label{display:block}
.header .sidebar-toggler .icon-bar:first-child,.header .sidebar-toggler .icon-bar:nth-child(2){width:20px}
.job-container .row .col-4{order:1;max-width:100%;flex:0 0 100%;padding-left:15px}
.job-container .row .col-8{order:2;max-width:100%;flex:0 0 100%}
.job-container .ibox.sticky-top{position:inherit;top:0;z-index:0}
.job-container .col-4:before{display:none}
.timeline .timeline-item{padding:10px 15px 10px 25px}
.media .text-muted.float-right{float:none!important}
.country-selection .dropdown-menu-media{width:700px}
}
@media (max-width: 991px) {
.header .dropdown.country-selection .dropdown-menu{right:-200px!important}
.col-lg-8.pd-8{padding-right:15px}
.dropdown .dropdown-menu.admin-dropdown-menu{min-width:400px}
.header .admin-dropdown-menu .admin-menu-features{padding:15px 0}
.header .admin-dropdown-menu .dropdown-header{padding:25px 30px}
.header .admin-dropdown-menu .admin-features-item i{font-size:24px;margin-bottom:10px}
.header .text-muted{font-size:14px}
.admin-menu-content .h1{font-size:1.75em!important}
.country-selection .dropdown-menu-media{width:700px}
.card-body .text-muted{font-size:14px}
.wrap-countries .grid2{padding-left:35px;padding-top:2px}
.wrap-countries .ranking{width:29px;height:29px;line-height:24px;background-size:25px auto}
.data-scroller.scroller{max-height:inherit}
.form-element .select2-container{width:100%!important}
.header .dropdown .dropdown-menu.admin-dropdown-menu{top:47px!important}
.country-selection .slimScrollDiv,.country-selection .scroller{height:400px!important}
.col-lg-4.pd-8{padding-right:15px}
.tab-section ul li{min-width:160px}
.tab-section ul li a{padding:10px}
.on-off{margin-top:10px}
}
@media (max-width: 767px) {
.close-btn{display:block}
.header .dropdown.country-selection .dropdown-menu{top:-2px!important;left:0!important;right:0!important;bottom:2px!important;position:fixed!important;width:100%;height:100%;overflow:auto}
.card-body h3{font-size:20px}
.repeatedrow h3{font-size:18px}
.repeatedrow .ad-spa h3{font-size:13px}
.listview .list dt:first-child{width:35%}
.listview .list dt{width:64%}
.col-md-4.px-2,.col-md-6.px-2{padding-left:15px!important;padding-right:15px!important}
.filter-stats .card-body{border-right:0}
.filter-stats .card-body{padding:20px 5px}
/* body:not(.fixed-layout).sidebar-mini .page-sidebar{width:0}
body:not(.fixed-layout).sidebar-mini .page-sidebar:hover{width:100%} */
/* .page-sidebar:hover{width:100%} */
/* .page-sidebar:hover .side-menu .heading{display:block}
.page-sidebar .side-menu .heading{display:block} */
body:not(.fixed-layout).sidebar-mini .content-wrapper{margin-left:0}
.page-sidebar .side-menu .heading{display:block}
.page-sidebar .side-menu li .nav-label{display:block}
.export-select .ti-angle-down:before{font-size:16px;margin-left:0}
.export-select a{font-size:0;min-height:35px}
.export-select .ti-angle-down:before{content:"\e681"}
.export-select a.dropdown-item{font-size:14px}
.page-content .flexbox.mb-4 .input-group-icon .form-control{width:190px}
.page-content .flexbox.mb-4 .mb-0.mr-2{margin-right:0!important;font-size:0}
.page-heading .page-title{font-size:18px;margin:20px 0}
.tab-section ul li{min-width:130px}
.tab-section ul li a{padding:8px 5px;font-size:13px}
.listview .list dt{font-size:13px}
.listview .list dt:first-child{padding-left:0}
.ibox.set-spacing .left-panel{padding:10px}
.listview dl{margin-bottom:12px}
.timeline .timeline-item{padding:8px 15px 8px 25px;font-size:13px}
.dropdown-user .font-strong{font-size:14px;text-transform:capitalize}
.country-selection .dropdown-menu-media{width:500px}
.heading-bar,.country-selection ul li{width:49%}
.navbar-toolbar li a{position:relative}
/* .header .sidebar-toggler .icon-bar{transition:inherit}
.header .sidebar-toggler .icon-bar:first-child{width:20px;transform:rotate(45deg);position:absolute;top:6px}
.header .sidebar-toggler .icon-bar:last-child{transform:rotate(-45deg);top:2px;position:absolute}
.header .sidebar-toggler .icon-bar:nth-child(2){width:20px;display:none}
.sidebar-mini .header .sidebar-toggler .icon-bar:first-child{width:7px;transform:inherit;position:inherit;top:0}
.sidebar-mini .header .sidebar-toggler .icon-bar:nth-child(2){width:14px;transform:inherit;position:inherit;display:block}
.sidebar-mini .header .sidebar-toggler .icon-bar:last-child{top:inherit;transform:inherit;position:inherit} */
.table-scroll .table-bordered td a,.table-scroll .table-bordered th{width:150px}
}
@media (max-width: 599px) {
/* body:not(.fixed-layout).sidebar-mini .brand{display:none}
body:not(.fixed-layout).sidebar-mini .brand-mini{display:block!important;padding:0 5px}
body:not(.fixed-layout).sidebar-mini .page-brand{width:70px;padding:0} */
.dropdown-user .dropdown-toggle span{display:none}
.sidebar-mini .header .sidebar-toggler .icon-bar:first-child,.sidebar-mini .header .sidebar-toggler .icon-bar:nth-child(2){width:20px}
.header .page-brand .brand{display:none}
.header{height:60px}
.total-stats .col{width:100%;margin:5px 0}
.total-stats .col span{float:right;min-width:100px;font-size:16px;text-align:right;padding:0 0 0 5px}
.header .brand-mini svg{line-height:60px;display:block;width:50px;margin-left:6px}
.page-sidebar{margin-top:60px}
.ibox.set-spacing .left-panel{display:block;width:100%;border-right:0}
.ibox.set-spacing .user-details{display:block;width:100%}
.left-panel.light .image-box{float:none;text-align:left;display:inline-block}
.ibox.set-spacing .left-panel img{max-width:100px}
.ibox.set-spacing .listview .list dt:first-child{width:150px;float:left;margin-left:0}
.ibox.set-spacing .listview .list dt{width:auto;margin-left:0;float:none;margin-left:160px;display:inherit}
.buttons .common-btn{min-width:120px;padding:5px}
.form-element ul.col-custome4 li{width:49%}
.modal-header,.modal-body,.modal-footer{padding:15px 25px}
.sub-heading{font-size:13px}
.form-element ul.col-custome3 li{width:49%}
.easypie.mr-4{margin-right:12px!important}
.form-element label{font-size:12px}
.media-body .text-muted.float-right{font-size:11px}
.list-group .list-group-item{padding:15px 0}
.header .font-13.text-muted{font-size:13px}
.dropdown-menu-header .font-18{font-size:16px}
.country-selection .dropdown-menu-media{width:400px}
.tab-section ul li{min-width:106px}
.heading-bar,.country-selection ul li{width:100%}
.tab-section{display:none;border-radius:0}
.dropdown.mobile-dropdown{display:block;margin-bottom:0}
.dropdown.mobile-dropdown.filter-mg-bottom{margin-bottom:15px}
.tab-section ul li{min-width:49%}
.tab-section ul li a{border-radius:0}
.tab-section ul li:nth-child(even){float:right}
.dropdown-menu-media{min-width:300px}
.country-selection .slimScrollDiv,.country-selection .scroller{height:100%!important}
}
@media (max-width: 467px) {
.page-content .flexbox.mb-4,.page-content .flexbox.mb-4 .flexbox{display:block!important}
.page-content .flexbox.mb-4 .select-box{display:inline-block}
.export-select{display:inline-block;vertical-align:top}
.page-content .flexbox.mb-4 .input-group-icon-left{margin-top:15px}
.total-stats .col{width:100%;text-align:left}
.total-stats .col span{min-width:100%;display:block;text-align:left;border-left:0 solid #e6e6e6;border-top:1px solid#f9f9f9;margin-top:6px;padding-top:6px;margin-left:0;padding-bottom:6px}
label.checkbox,label.radio{font-size:13px!important}
.col-list-right{margin-left:0;margin-right:5px}
.header .dropdown-user a.dropdown-toggle{padding:0 5px}
.header .toolbar-icon{margin:0;padding:.5rem 0}
.repeatedrow .action-wrap a{width:30px;height:30px;line-height:30px;font-size:14px}
.ibox.set-spacing .listview .list dt:first-child{width:130px}
.ibox.set-spacing .listview .list dt{margin-left:130px}
.col-6{-ms-flex:0 0 50%;flex:0 0 100%;max-width:100%}
.add-more-container a{width:40px;height:40px;line-height:42px;font-size:16px}
.ibox-tools ul{margin:0;padding-left:0;margin-top:10px}
.ibox-tools .select2-container{display:block;width:250px!important}
.progressWrap h4,.progressWrap .progressBar span{font-size:12px}
.progressWrap .progressBar{height:18px}
.progressWrap{padding:15px 0 0}
.wrap-countries .ranking{width:24px;height:24px;line-height:20px;background-size:22px auto;font-size:11px}
.wrap-countries .grid2 h4{font-size:13px}
.wrap-countries .grid2{padding-left:30px}
.ibox .ibox-head .ibox-title{font-size:15px}
.card-body h3{font-size:16px}
.card-body.text-success .easypie i{font-size:20px}
.card-body .easypie i{font-size:30px;width:50px;height:50px;line-height:50px}
.header .admin-dropdown-menu .dropdown-header{padding:15px}
.header .admin-dropdown-menu .admin-avatar{margin-right:20px;-webkit-transform:inherit;-ms-transform:inherit;transform:inherit}
.header .admin-dropdown-menu .admin-menu-content{padding:10px 15px 30px}
.admin-menu-content .h1{font-size:1.5em!important}
.dropdown .dropdown-menu.admin-dropdown-menu{min-width:280px}
.dropdown-user .font-strong{font-size:14px;text-transform:capitalize}
.header .admin-dropdown-menu .admin-avatar img{-webkit-transform:inherit;-ms-transform:inherit;transform:inherit}
.header .text-muted{display:block;width:100%}
.dropdown-menu-media{min-width:250px}
.dropdown-menu-media .float-right.text-muted.ml-2{margin-left:0!important}
.timeline::before{display:none}
.country-selection .dropdown-menu-media{width:300px}
.country-selection .dropdown-menu-media{padding:15px}
.select2-container--default .select2-results__option{padding-left:15px}
.header .dropdown .dropdown-menu.dropdown-menu-media{right:-40px!important}
#date_1 .date{margin-bottom:20px}
#date_1 .date:nth-child(2){margin-bottom:0}
}
@media (max-width: 320px) {
.table-full-bx .flexbox.mb-4{padding:20px 15px 0}
.page-content .flexbox.mb-4 .input-group-icon .form-control{width:170px}
.header .toolbar-icon{width:35px;height:35px;line-height:25px}
.ibox.set-spacing .listview .list dt:first-child{width:100%;font-size:14px}
.ibox.set-spacing .listview .list dt{margin-left:0}
.listview dl{margin-bottom:15px}
.timeline .timeline-item span{display:block;width:100%}
.form-element ul.col-custome3 li{width:100%}
.modal-header,.modal-body,.modal-footer{padding:15px}
#chart #bars li .bar{width:32px}
.form-element ul.col-custome4 li{width:100%}
.dropdown .dropdown-menu.admin-dropdown-menu{max-width:260px;min-width:260px}
.header .dropdown .dropdown-menu.dropdown-menu-media{max-width:260px;right:-50px!important;top:47px!important}
.header .dropdown-notification .dropdown-menu.dropdown-menu-media{right:-20px!important}
.dropdown .dropdown-menu.admin-dropdown-menu{max-width:260px}
}
.modal-body .form-element label{text-align:left}
.width100_margin0{width:100%;margin-left:0}
.circleIcon{border-radius:25px;width:10px;height:10px;background:#1E5147;padding:5px;color:#fff}
.outer_location{padding:20px}
.col-list-right.width80{width:65%}
.col-bx-top button.common-btn{float:right;border-radius:35px}
#example_length{display:none!important}
#example_filter{display:none!important}
.sorting_disabled:after{opacity:0!important}
.sorting_disabled:before{opacity:0!important}
.nodatadiv p{color:#151515;font-weight:600;font-size:16px;padding-bottom:10px}
.nodatadiv{display:flex;align-items:center;justify-content:center;flex-direction:column;padding:40px;height:300px}
.nodatadiv .commonBtn{width:fit-content;background:#fff;color:#EF7E2B;border:1px solid #EF7E2B}
a.nav-link.dropdown-toggle{padding-right:0!important}
.iboxcontent h3{color:#151515;font-weight:600;font-size:18px}
.post{margin-top:20px}
.post .grid{display:flex}
.post .card{background-color:#fff;position:relative;box-shadow:none;border:1px solid #c3c3c3;border-radius:12px;cursor:pointer;padding:20px}
.post .radio{cursor:pointer;position:absolute}
.post .radio:checked{background:#EF7E2B;border:1px solid #EF7E2B;overflow:hidden;box-shadow:none}
.plan-type{color:#151515;font-size:16px;font-weight:600;margin-bottom:10px}
.plan-cost{color:#373737;font-weight:400;font-size:14px}
.table-wrapper.user-top{margin-top:10px!important}
.table-wrapper{text-align:left;border-radius:18px;position:relative;margin-bottom:0;font-weight:600}
.el_details .blue-text{color:#4691F7;font-size:14px;width:100%;background:#fff;margin-top:10px}
.table-wrapper table{border-collapse:collapse}
.table-wrapper th,tfoot th{text-transform:capitalize;padding:17px 20px;letter-spacing:.91px;letter-spacing:0;color:#fff;background:#151515;border-bottom:1px solid #ECECEC}
.table-wrapper tfoot th{background:#676767;font-weight:600}
.table-wrapper tr{border:none!important}
.table-wrapper td span{letter-spacing:.38px;color:#1F1F1F;font-weight:500;display:block;border-radius:50px}
.table-wrapper td a{color:#1F1F1F;font-weight:500;cursor:pointer;text-transform:capitalize}
.table-wrapper thead th:first-child{border-radius:18px 0 0 0!important}
tfoot th:first-child{border:none!important}
.table-wrapper th:first-child,.table-wrapper td:first-child,tfoot th:first-child{width:150px}
.table-wrapper th.width-auto{width:300px!important}
.table-wrapper td.width-auto{width:300px!important}
.table-wrapper th:last-child,.table-wrapper td:last-child{width:150px}
.table-wrapper thead th:last-child{border-radius:0 18px 0 0!important;border:none!important}
.page-content .heading-6{font-size:14px;text-transform:capitalize}
.ft{height:12px;vertical-align:middle}
.planbtn.greyBtn{border:1px solid #727272;border-radius:12px;text-align:center;color:#727272}
.iboxcontent{position:relative}
.stepsnumber{position:absolute;right:0;top:0}
.customradiobox .custom_radio{margin:10px 0}
.customradiobox .custom_radio input[type="radio"]{display:none}
.customradiobox .custom_radio input[type="radio"]+label{position:relative;display:inline-block;padding-left:1.5em;margin-right:4em;cursor:pointer;line-height:1em;-webkit-transition:all .3s ease-in-out;transition:all .3s ease-in-out}
.customradiobox .custom_radio input[type="radio"]+label:before,.customradiobox .custom_radio input[type="radio"]+label:after{content:'';position:absolute;top:0;left:0;width:1em;height:1em;text-align:center;color:#fff;font-family:Times;border-radius:50%;-webkit-transition:all .3s ease;transition:all .3s ease}
.customradiobox .custom_radio input[type="radio"]+label:before{-webkit-transition:all .3s ease;transition:all .3s ease;box-shadow:inset 0 0 0 .2em #E3E3E3,inset 0 0 0 1em #E3E3E3}
.customradiobox .custom_radio input[type="radio"]+label:hover:before{-webkit-transition:all .3s ease;transition:all .3s ease;box-shadow:inset 0 0 0 .3em #E3E3E3,inset 0 0 0 1em #E3E3E3}
.customradiobox .custom_radio input[type="radio"]:checked+label:before{-webkit-transition:all .3s ease;transition:all .3s ease;box-shadow:inset 0 0 0 .2em #fff,inset 0 0 0 1em #EF7E2B}
.to{color:#151515;font-weight:600;padding:0 35px;margin-top:-10px}
.showmore{background:#FFF 0 0 no-repeat padding-box;border:1px solid #EF7E2B;border-radius:27px;padding:7px 10px;color:#EF7E2B}
.showmore img{height:7px;vertical-align:middle}
.borderBtn.planbtn.wdth{width:fit-content;background:#fff}
.input-group-icon .input-icon{position:absolute;left:0;top:0;height:30px;line-height:30px;width:34px;text-align:center;cursor:pointer}
.input-group-icon .form-control{padding-left:33px;padding-right:33px;border:1px solid #D6D6D6;border-radius:12px!important}
.commonBtn2{width:100%;background:#EF7E2B;background:-moz-linear-gradient(left,#EF7E2B 0%,#EF7E2B 100%);background:-webkit-linear-gradient(left,#EF7E2B 0%,#EF7E2B 100%);background:linear-gradient(to right,#EF7E2B 0%,#EF7E2B 100%);color:#fff;font-size:1em;font-weight:400;border-radius:8px;padding:5px 10px;display:inline-block;vertical-align:top;cursor:pointer;text-align:center;-webkit-transition:.3s all ease-in-out;-o-transition:.3s all ease-in-out;transition:.3s all ease-in-out}
.ap{margin-right:2px;height:20px}
.tableimg{float:right}
.table-wrapper td a.jobid{text-decoration:underline;color:#EF7E2B}
.table-wrapper td a.jobid.green{text-decoration:none;color:#5FC76A}
.table-wrapper td a.jobid.red{text-decoration:none;color:#EB4D4D}
td .dropdown-menu{border-radius:4px;padding:5px 0;-webkit-animation-name:dropdown-animate;animation-name:dropdown-animate;-webkit-animation-duration:.2s;animation-duration:.2s;-webkit-animation-fill-mode:both;animation-fill-mode:both;border:0;box-shadow:0 5px 30px 0 rgba(82,63,105,0.2)}
/* .table-wrapper .dropdown-menu.show{transform:translate3d(-100px,4px,0px)!important} */
.table-wrapper .dropdown-menu.show {
    transform: translate3d(-100px,4px,0px)!important;
    left: 0px !important;
    top: 50% !important;
    margin-top: -35% !important;
}
.table-wrapper table tr:last-child .dropdown-menu.show,.table-wrapper table tr:nth-last-child(2){bottom:0!important;top:auto!important}
.pagination li a{border:1px solid #C7C8C3;border-radius:6px;padding:10px 5px;display:block;width:40px;height:40px;text-align:center;margin:0 auto}
.pagination li a.prev,.pagination li a.next{border:1px solid #DAD8D8;border-radius:6px;padding:10px 5px;width:90px}
.pagination li a.active{background:#EF7E2B 0 0 no-repeat padding-box;border:1px solid #EF7E2B;border-radius:6px;color:#fff}
.post .customradiobox{background:#FFF 0 0 no-repeat padding-box;border:1px solid #C3C3C3;border-radius:12px;padding:20px}
.post .customradiobox .custom_radio .active{border:1px solid #EF7E2B!important}
.post .customradiobox .custom_radio{margin:5px 0}
.post .customradiobox .custom_radio input[type="radio"]+label{padding-left:2.5em}
.post .customradiobox .custom_radio input[type="radio"]+label:before,.post .customradiobox .custom_radio input[type="radio"]+label:after{height:1.5rem;width:1.5rem;top:4px}
.start{height:600px}
.table-wrapper td span input{display:block;position:absolute;top:12px;right:50px;width:100%;visibility:hidden;cursor:pointer}
.action-wrap.table-action a.selected{background-color:#EF7E2B;color:#fff}
.table-wrapper td a.selected span{color:#fff}
@media (min-width: 768px) {
.dropdown-menu::before{content:"";width:13px;height:13px;position:absolute;top:-7px;right:34px;transform:rotate(45deg);border-top:0 solid #fff;border-left:0 solid #fff;box-shadow:-1px -1px 2px rgba(0,0,0,.1)}
}
@media (max-width: 767px) {
.close-btn{display:block}
.header .dropdown.country-selection .dropdown-menu{top:-2px!important;left:0!important;right:0!important;bottom:2px!important;position:fixed!important;width:100%;height:100%;overflow:auto}
.card-body h3{font-size:20px}
.repeatedrow h3{font-size:18px}
.repeatedrow .ad-spa h3{font-size:13px}
.listview .list dt:first-child{width:35%}
.listview .list dt{width:64%}
.col-md-4.px-2,.col-md-6.px-2{padding-left:15px!important;padding-right:15px!important}
.filter-stats .card-body{border-right:0}
.filter-stats .card-body{padding:20px 5px}
.page-sidebar:hover .side-menu .heading{display:block}
.page-sidebar .side-menu .heading{display:block}
.page-sidebar .side-menu li .nav-label{display:block}
.export-select .ti-angle-down:before{font-size:16px;margin-left:0}
.export-select a{font-size:0;min-height:35px}
.export-select .ti-angle-down:before{content:"\e681"}
.export-select a.dropdown-item{font-size:14px}
.page-content .flexbox.mb-4 .input-group-icon .form-control{width:190px}
.page-content .flexbox.mb-4 .mb-0.mr-2{margin-right:0!important;font-size:0}
.page-heading .page-title{font-size:18px;margin:20px 0}
.tab-section ul li{min-width:130px}
.tab-section ul li a{padding:8px 5px;font-size:13px}
.listview .list dt{font-size:13px}
.listview .list dt:first-child{padding-left:0}
.ibox.set-spacing .left-panel{padding:10px}
.listview dl{margin-bottom:12px}
.timeline .timeline-item{padding:8px 15px 8px 25px;font-size:13px}
.dropdown-user .font-strong{font-size:14px;text-transform:capitalize}
.country-selection .dropdown-menu-media{width:500px}
.heading-bar,.country-selection ul li{width:49%}
.navbar-toolbar li a{position:relative}
.table-scroll .table-bordered td a,.table-scroll .table-bordered th{width:150px}
}
@media (max-width: 599px) {
body:not(.fixed-layout).sidebar-mini .brand{display:none}
body:not(.fixed-layout).sidebar-mini .brand-mini{display:block!important}
body:not(.fixed-layout).sidebar-mini .page-brand{width:70px}
.dropdown-user .dropdown-toggle span{display:none}
.sidebar-mini .header .sidebar-toggler .icon-bar:first-child,.sidebar-mini .header .sidebar-toggler .icon-bar:nth-child(2){width:20px}
.header .page-brand .brand{display:none}
.header{height:60px}
.header .page-brand .brand-mini{display:block}
.header .page-brand{transition:inherit;width:70px}
.total-stats .col{width:100%;margin:5px 0}
.total-stats .col span{float:right;min-width:100px;font-size:16px;text-align:right;padding:0 0 0 5px}
.header .brand-mini svg{line-height:60px;display:block;width:50px;margin-left:6px}
.page-sidebar{margin-top:60px} 
.ibox.set-spacing .left-panel{display:block;width:100%;border-right:0}
.ibox.set-spacing .user-details{display:block;width:100%}
.left-panel.light .image-box{float:none;text-align:left;display:inline-block}
.ibox.set-spacing .left-panel img{max-width:100px}
.ibox.set-spacing .listview .list dt:first-child{width:150px;float:left;margin-left:0}
.ibox.set-spacing .listview .list dt{width:auto;margin-left:0;float:none;margin-left:160px;display:inherit}
.buttons .common-btn{min-width:120px;padding:5px}
.form-element ul.col-custome4 li{width:49%}
.modal-header,.modal-body,.modal-footer{padding:15px 25px}
.sub-heading{font-size:13px}
.form-element ul.col-custome3 li{width:49%}
.easypie.mr-4{margin-right:12px!important}
.form-element label{font-size:12px}
.media-body .text-muted.float-right{font-size:11px}
.list-group .list-group-item{padding:15px 0}
.header .font-13.text-muted{font-size:13px}
.dropdown-menu-header .font-18{font-size:16px}
.country-selection .dropdown-menu-media{width:400px}
.tab-section ul li{min-width:106px}
.heading-bar,.country-selection ul li{width:100%}
.tab-section{display:none;border-radius:0}
.dropdown.mobile-dropdown{display:block;margin-bottom:0}
.dropdown.mobile-dropdown.filter-mg-bottom{margin-bottom:15px}
.tab-section ul li{min-width:49%}
.tab-section ul li a{border-radius:0}
.tab-section ul li:nth-child(even){float:right}
.dropdown-menu-media{min-width:300px}
.country-selection .slimScrollDiv,.country-selection .scroller{height:100%!important}
}
@media (max-width: 580px) {
.header .search-toggler span,.dropdown-user>a>span{display:none}
.dropdown-user>a>img{margin-left:0!important}
}
@media (max-width: 467px) {
.page-content .flexbox.mb-4,.page-content .flexbox.mb-4 .flexbox{display:block!important}
.page-content .flexbox.mb-4 .select-box{display:inline-block}
.export-select{display:inline-block;vertical-align:top}
.page-content .flexbox.mb-4 .input-group-icon-left{margin-top:15px}
.total-stats .col{width:100%;text-align:left}
.total-stats .col span{min-width:100%;display:block;text-align:left;border-left:0 solid #e6e6e6;border-top:1px solid#f9f9f9;margin-top:6px;padding-top:6px;margin-left:0;padding-bottom:6px}
label.checkbox,label.radio{font-size:13px!important}
.col-list-right{margin-left:0;margin-right:5px}
.header .dropdown-user a.dropdown-toggle{padding:0 5px}
.header .toolbar-icon{margin:0;padding:.5rem 0}
.repeatedrow .action-wrap a{width:30px;height:30px;line-height:30px;font-size:14px}
.ibox.set-spacing .listview .list dt:first-child{width:130px}
.ibox.set-spacing .listview .list dt{margin-left:130px}
.col-6{-ms-flex:0 0 50%;flex:0 0 100%;max-width:100%}
.add-more-container a{width:40px;height:40px;line-height:42px;font-size:16px}
.ibox-tools ul{margin:0;padding-left:0;margin-top:10px}
.ibox-tools .select2-container{display:block;width:250px!important}
.progressWrap h4,.progressWrap .progressBar span{font-size:12px}
.progressWrap .progressBar{height:18px}
.progressWrap{padding:15px 0 0}
.wrap-countries .ranking{width:24px;height:24px;line-height:20px;background-size:22px auto;font-size:11px}
.wrap-countries .grid2 h4{font-size:13px}
.wrap-countries .grid2{padding-left:30px}
.ibox .ibox-head .ibox-title{font-size:15px}
.card-body h3{font-size:16px}
.card-body.text-success .easypie i{font-size:20px}
.card-body .easypie i{font-size:30px;width:50px;height:50px;line-height:50px}
.header .admin-dropdown-menu .dropdown-header{padding:15px}
.header .admin-dropdown-menu .admin-avatar{margin-right:20px;-webkit-transform:inherit;-ms-transform:inherit;transform:inherit}
.header .admin-dropdown-menu .admin-menu-content{padding:10px 15px 30px}
.admin-menu-content .h1{font-size:1.5em!important}
.dropdown .dropdown-menu.admin-dropdown-menu{min-width:280px}
.dropdown-user .font-strong{font-size:14px;text-transform:capitalize}
.header .admin-dropdown-menu .admin-avatar img{-webkit-transform:inherit;-ms-transform:inherit;transform:inherit}
.header .text-muted{display:block;width:100%}
.dropdown-menu-media{min-width:250px}
.dropdown-menu-media .float-right.text-muted.ml-2{margin-left:0!important}
.timeline::before{display:none}
.country-selection .dropdown-menu-media{width:300px}
.country-selection .dropdown-menu-media{padding:15px}
.select2-container--default .select2-results__option{padding-left:15px}
.header .dropdown .dropdown-menu.dropdown-menu-media{right:-40px!important}
#date_1 .date{margin-bottom:20px}
#date_1 .date:nth-child(2){margin-bottom:0}
}
@media (max-width: 320px) {
.table-full-bx .flexbox.mb-4{padding:20px 15px 0}
.page-content .flexbox.mb-4 .input-group-icon .form-control{width:170px}
.header .toolbar-icon{width:35px;height:35px;line-height:25px}
.ibox.set-spacing .listview .list dt:first-child{width:100%;font-size:14px}
.ibox.set-spacing .listview .list dt{margin-left:0}
.listview dl{margin-bottom:15px}
.timeline .timeline-item span{display:block;width:100%}
.form-element ul.col-custome3 li{width:100%}
.modal-header,.modal-body,.modal-footer{padding:15px}
#chart #bars li .bar{width:32px}
.form-element ul.col-custome4 li{width:100%}
.dropdown .dropdown-menu.admin-dropdown-menu{max-width:260px;min-width:260px}
.header .dropdown .dropdown-menu.dropdown-menu-media{max-width:260px;right:-50px!important;top:47px!important}
.header .dropdown-notification .dropdown-menu.dropdown-menu-media{right:-20px!important}
.dropdown .dropdown-menu.admin-dropdown-menu{max-width:260px}
}