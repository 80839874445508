*{-webkit-box-sizing:border-box;box-sizing:border-box;font-family:'Work Sans',sans-serif;font-weight:400;margin:0;padding:0;border:0;outline:none}
p{color:#373737;font-weight:400}
input.form-control{border:none;border-bottom:1px solid #ccc;border-radius:0;padding:.375rem 0;outline:none;padding-right:30px}
.loginTitle{color:#1A1918;font-size:25px;font-weight:600}
.loginText{margin-bottom:30px}
.form-group label{margin-bottom:0px;color:#373737;text-transform:capitalize}
.form-control:focus{color:#495057;background-color:#fff;border-color:#495057;outline:0;box-shadow:none}
.form-group img.hide{position:absolute;right:0;bottom:10%}
.form-group .forgot-btn{color:#EF7E2B;font-weight:500}
.commonBtn{width:100%;background:#EF7E2B;background:-moz-linear-gradient(left,#EF7E2B 0%,#EF7E2B 100%);background:-webkit-linear-gradient(left,#EF7E2B 0%,#EF7E2B 100%);background:linear-gradient(to right,#EF7E2B 0%,#EF7E2B 100%);color:#fff;font-size:1em;font-weight:400;border-radius:8px;padding:10px 20px;display:inline-block;vertical-align:top;cursor:pointer;text-align:center;-webkit-transition:.3s all ease-in-out;-o-transition:.3s all ease-in-out;transition:.3s all ease-in-out}
.commonBtn:hover{color:#fff}
.commonBtn.unfill{background:#F8F8F8;color:#151515}
.commonBtn.unfill:hover{background:#EF7E2B}
.socialMediaLink{text-align:center;padding:10px 20px;background:#F8F8F8;color:#151515;display:block;align-items:center;border-radius:12px}
.socialMediaLink .icon{width:25px;margin-right:15px;position:absolute;left:25px}
.loginImg{width:100%;height:600px;background:url(../images/ill_login.svg) no-repeat center;background-size:cover;text-align:center}
.ht{height:20px}
.socialMediaLink .text{font-weight:500;font-size:14px;color:#151515}
a.register{color:#FE831D;font-weight:500}
a.register:hover{color:#FE831D}
.navbar-nav .nav-link{float:right}
.navbar-nav .nav-link i.ic_profile{width:30px;height:30px;background:#ccc;border-radius:50px;display:inline-block;vertical-align:middle;text-align:center;padding:5px}
.navbar-nav .nav-link i.ic_profile img{width:20px;height:20px}
.dropdown-menu{padding:0}
.dropdown-menu ul a{letter-spacing:.28px;color:#151515;font-size:14px;display:inline-block;width:100%;padding:10px 25px;font-weight:500}
li.nav-item.dropdown .dropdown-toggle::after{display:none}
.nav-profile img.arrow{height:6px;margin-right:0}
a.nav-link.dropdown-toggle{padding-right:20px}
#header{box-shadow:none;border-bottom:1px solid #D7D7DC}
.registerModal .modal-content{border-radius:20px!important}
.registerModal .modal-body{padding:50px 20px!important}
ul.selectUser{margin:35px 0}
ul.selectUser li{text-align:left}
.registerModal button.close{position:absolute;right:20px;top:10px;width:20px;height:20px;font-size:35px}
.selectUser input[type="radio"]{display:none}
.selectUser :checked+label{border-color:#FE831D}
.selectUser label{border:1px solid #EBEAEA;padding:15px;display:block;position:relative;margin:10px;cursor:pointer;border-radius:10px}
.selectUser label:before{background-color:#fff;color:#fff;content:" ";display:block;border-radius:50%;border:1px solid #EF7E2B;position:absolute;top:30px;right:22px;z-index:1;width:20px;height:20px;text-align:center;line-height:22px;transition-duration:.4s;transform:scale(0)}
.selectUser :checked+label::before{content:"✓";background-color:#EF7E2B;transform:scale(1);font-size:14px}
.selectUser label img{height:50px;display:inline-block;vertical-align:middle}
.selectUser label span{vertical-align:middle;display:inline-block;margin-left:15px}
.mainTitle{color:#151515;font-size:25px;font-weight:600;margin-bottom:8px}
.selectAvtara{display:flex;justify-content:space-between;margin:40px 0}
.selectAvtara input[type="radio"]{display:none}
.selectAvtara :checked+label{border-color:#FE831D;color:#FE831D}
.selectAvtara :checked+label span img{fill:#EF7E2B;filter:invert(49%) sepia(30%) saturate(6866%) hue-rotate(351deg) brightness(107%) contrast(88%)}
.selectAvtara label{font-weight:500;color:#151515;border:1px solid #D7D7DC;border-radius:20px;padding:15px 25px;display:block;position:relative;cursor:pointer;text-align:center;margin-bottom:0}
.selectAvtara label:before{background-color:#fff;color:#fff;content:" ";display:block;border-radius:50%;border:1px solid #EF7E2B;position:absolute;top:22px;right:22px;z-index:1;width:20px;height:20px;text-align:center;line-height:22px;transition-duration:.4s;transform:scale(0)}
.selectAvtara label span{display:block}
.selectAvtara label span img{max-width:50px}
.selectAvtara_radio{appearance:none;display:none}
.orangeText{color:#EF7E2B}
.verificationWrap{max-width:500px;margin:0 auto;padding:20px}
.otpFild{display:flex;justify-content:space-between;margin-top:30px}
.otpInput{border-bottom:1px solid #ccc;max-width:100px;height:40px;text-align:center;font-weight:600}
.subHeader{margin-bottom:25px}
.selectpasswordType{margin:0;padding:0}
.selectpasswordType li{display:inline-block;margin-top:10px;margin-right:7px;border:1px solid #D9D9D9;padding:5px 10px 5px 30px;position:relative;border-radius:50px;text-align:center;margin-bottom:0;font-size:14px}
.selectpasswordType li:after{content:'';width:15px;height:8px;background:url(../images/ic_correct.svg) no-repeat;position:absolute;left:13px;top:12px}
.icon_div{position:relative}
.form-group .custom-file-button input[type=file]::-webkit-file-upload-button{display:none}
.form-group .custom-file-button input[type=file]::file-selector-button{display:none}
.form-group .custom-file-button:hover label{background-color:transparent;cursor:pointer}
.form-group .input-group{position:relative;display:flex;flex-wrap:wrap;align-items:stretch;width:100%;border:1px dashed #FE831D;border-radius:10px;justify-content:center}
.form-group .input-group-text{height:38px;line-height:26px;margin-bottom:0!important;border-radius:2px 0 0 2px;border:transparent;background:transparent;color:#EF7E2B;font-size:14px;font-weight:500;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;width:100%;display:block}
.input-group.custom-file-button input.form-control{border:transparent;border-radius:10px;display:none}
select.form-control{border:none;border-bottom:1px solid #ced4da;border-radius:0}
.carousel-inner .carousel-item{background:#eee;padding:15px;border-radius:3px}
.explain{color:#373737;font-weight:600;font-size:14px;padding-top:15px;text-align:center}
.form-group .input-group-text img{display:inline-block;vertical-align:middle;width:15px;margin-right:10px}
.topHeader{position:relative;margin-bottom:50px}
.topHeader .skipBtn{position:absolute;top:3px;right:20px;color:#727272;font-size:16px;font-weight:500}
.profileInfo{display:flex}
.profileName{margin-left:40px}
ul.profilechecks li{display:inline-block;margin-right:30px}
.profile-upload{position:relative;max-width:100px}
.profile-upload .proEdit{position:absolute;top:-8px;right:-8px;margin:auto;z-index:1}
.profile-upload .proEdit input{position:absolute;width:100%;height:100%;opacity:0;left:0;right:0;top:0;bottom:0;margin:auto;z-index:1}
.profile-upload .proEdit input+label{width:30px;height:30px;line-height:30px;margin-bottom:0;background:#FFF;cursor:pointer;font-weight:400;transition:all .2s ease-in-out;text-align:center;font-size:20px;border-radius:100px}
.profile-upload .avatar-preview{width:100px;height:100px;position:relative;display:inline-block;vertical-align:middle;margin-right:5px;overflow:hidden;border-radius:50%}
.profile-upload .avatar-preview div{position:absolute;left:0;right:0;margin:auto;top:0;bottom:0;background-position:center;background-size:cover;border-radius:50px}
.profile-upload .avatar-preview div{display:block!important}
.profileName p.email{position:relative;display:inline-block;margin-bottom:20px}
.profileName p.email:after{content:'';width:19px;height:19px;position:absolute;right:-25px;top:2px;background:#FE831D url(../images/ic_correct-w.svg) no-repeat;border-radius:100px;background-position:center}
.subTitle{font-size:20px;margin-bottom:25px;font-weight:600;color:#151515}
.enterManually span{font-size:16px;font-weight:500;margin:20px 0;display:inline-block;vertical-align:middle}
.completeProfile textarea.form-control{border:none;border-bottom:1px solid #ced4da;border-radius:0;padding:.375rem 0}
.d-flex.spaceRight label{padding-right:20px}
.radioWrap [type="radio"]:checked,.radioWrap [type="radio"]:not(:checked){position:absolute;left:-9999px}
.radioWrap article{width:100%;height:200px;display:none}
.radioWrap article.on{display:inline-block}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before{background-color:#EF7E2B;border-color:#EF7E2B}
.custom-control-input:focus~.custom-control-label::before{box-shadow:none}
.custom-control-label::before{border:1px solid #EF7E2B}
.bluebox{display:flex;justify-content:space-between;width:100%;padding:12px 20px;background:#006599 0 0 no-repeat padding-box;box-shadow:0 1px 5px #0000000A;border-radius:12px;margin:40px 0}
.fetchBtn{background:#fff;padding:10px;border-radius:10px;font-size:16px;font-weight:600;color:#006599}
.fetchBtn img{width:25px}
.bluebox p{color:#fff;display:inline-block;vertical-align:middle;margin-top:10px;font-weight:600}
.addressWrap .detectLocation{border:1px dashed #F8F8F8;background:#F8F8F8;border-radius:10px;display:inline-block;padding:15px 20px;color:#151515;font-weight:500;max-width:450px;width:100%;position:relative;min-height:56px}
.addressWrap .detectLocation .allowBtn{border:1px solid #FE831D;padding:7px 10px;background:#F8F8F8;border-radius:5px;margin-left:50px;color:#FE831D;font-size:15px;position:absolute;font-weight:600;right:18px;top:8px}
.navbar-nav .nav-link .ic_down_arrow{position:absolute;top:20px;right:0}
.grayBox{background:#F8F8F8;border-radius:5px;padding:10px 20px}
.grayBox.d-flex{justify-content:space-between}
.grayBox p{font-weight:500}
.nav-profile{color:#012970}
.nav-profile img{max-height:36px;width:36px;height:36px;margin-right:10px}
.nav-profile span{font-size:14px;font-weight:600}
.nav-profile{color:#151515;font-weight:600}
.dropdown-menu{border-radius:20px;padding:10px 0;-webkit-animation-name:dropdown-animate;animation-name:dropdown-animate;-webkit-animation-duration:.2s;animation-duration:.2s;-webkit-animation-fill-mode:both;animation-fill-mode:both;border:0;box-shadow:0 3px 16px #0000001A}
.dropdown-menu .dropdown-header,.dropdown-menu .dropdown-footer{text-align:center;font-size:15px;padding:10px 25px}
.dropdown-menu .dropdown-footer a{color:#444;text-decoration:underline}
.dropdown-menu .dropdown-footer a:hover{text-decoration:none}
.dropdown-menu .dropdown-divider{color:#a5c5fe;margin:0;border:1px solid #D6D6D6}
.dropdown-menu .dropdown-item{font-size:14px;padding:10px 15px;transition:.3s}
.dropdown-menu .dropdown-item i{margin-right:10px;font-size:18px;line-height:0}
.dropdown-menu .dropdown-item:hover{background-color:#f6f9ff}

/* Common css Start */



input.form-control {
	border: none;
	border-bottom: 1px solid #ccc;
	border-radius: 0px;
	padding: 0.375rem 0;
	outline: none;
	padding-right: 30px;
}

.loginTitle {
	color: #1A1918;
	font-size: 25px;
	font-weight: 600;
}

.loginText {
	margin-bottom: 30px;
}


.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #495057;
	outline: 0;
	box-shadow: none;
}

.form-group img.hide {
	position: absolute;
	right: 0;
	bottom: 10%;
}

.form-group .forgot-btn {
	color: #EF7E2B;
	font-weight: 500;
}

.commonBtn {
	width: 100%;
	background: #EF7E2B;
	background: -moz-linear-gradient(left, #EF7E2B 0%, #EF7E2B 100%);
	background: -webkit-linear-gradient(left, #EF7E2B 0%, #EF7E2B 100%);
	background: linear-gradient(to right, #EF7E2B 0%, #EF7E2B 100%);
	color: #fff;
	font-size: 1em;
	font-weight: 400;
	border-radius: 8px;
	padding: 10px 20px;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	text-align: center;
	-webkit-transition: .3s all ease-in-out;
	-o-transition: .3s all ease-in-out;
	transition: .3s all ease-in-out;
}

.commonBtn:hover {
	color: #fff;
}

.commonBtn.unfill {
	background: #F8F8F8;
	color: #151515;
}

.commonBtn.unfill:hover {
	background: #EF7E2B;
}

.socialMediaLink {
	text-align: center;
	padding: 10px 20px;
	background: #F8F8F8;
	color: #151515;
	display: block;
	align-items: center;
	border-radius: 12px;
}

.socialMediaLink .icon {
	width: 25px;
	margin-right: 15px;
	position: absolute;
	left: 25px;
}

.loginImg {
	width: 100%; min-height: 400px; background: url(../images/ill_login.svg) no-repeat center;  height: 100vh;max-height: 500px;  background-size: contain; background-position: bottom; position: absolute; left: 0; top: 0; text-align: center; }

.ht {
	height: 20px;
}

.socialMediaLink .text {
	font-weight: 500;
	font-size: 14px;
	color: #151515
}

a.register {
	color: #FE831D;
	font-weight:500;
}

a.register:hover {
	color: #FE831D;
}

/*Header css */
.navbar-nav .nav-link {
	float: right;
}

.navbar-nav .nav-link i.ic_profile {
	width: 30px;
	height: 30px;
	background: #ccc;
	border-radius: 50px;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	padding: 5px;
}

.navbar-nav .nav-link i.ic_profile img {
	width: 20px;
	height: 20px;
}

.dropdown-menu {
	padding: 0px;
}

.dropdown-menu ul a {
	letter-spacing: 0.28px;
	color: #151515;
	font-size: 14px;
	display: inline-block;
	width: 100%;
	padding: 10px 25px;
	font-weight:500;
}

li.nav-item.dropdown .dropdown-toggle::after {
	display: none;
}

.nav-profile img.arrow {
	height: 6px;
	margin-right: 0px;
}

a.nav-link.dropdown-toggle {
	padding-right: 20px;
}

#header {
	box-shadow: none;
	border-bottom: 1px solid #D7D7DC;
}

/*=== registerModal === */
.registerModal .modal-content {
	border-radius: 20px !important;
}

.registerModal .modal-body {
	padding: 50px 20px !important;
}

ul.selectUser {
	margin: 35px 0px;
}

ul.selectUser li {
	text-align: left;
}

.registerModal button.close {
	position: absolute;
	right: 20px;
	top: 10px;
	width: 20px;
	height: 20px;
	font-size: 35px;
}

.selectUser input[type="radio"] {
	display: none;
}

.selectUser :checked+label {
	border-color: #FE831D;
}

.selectUser label {
	border: 1px solid #EBEAEA;
	padding: 15px;
	display: block;
	position: relative;
	margin: 10px;
	cursor: pointer;
	border-radius: 10px;
}

.selectUser label:before {
	background-color: #fff;
	color: #fff;
	content: " ";
	display: block;
	border-radius: 50%;
	border: 1px solid #EF7E2B;
	position: absolute;
	top: 30px;
	right: 22px;
	z-index: 1;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 22px;
	transition-duration: 0.4s;
	transform: scale(0);
}

.selectUser :checked+label::before {
	content: "✓";
	background-color: #EF7E2B;
	transform: scale(1);
	font-size: 14px;
}

.selectUser label img {
	height: 50px;
	display: inline-block;
	vertical-align: middle;
}

.selectUser label span {
	vertical-align: middle;
	display: inline-block;
	margin-left: 15px;
}

/* .selectUser label:first-child span{margin-left:60px;} */
/*==== choose-yourself ======*/
.progress {
	margin-top: 70px;
	height: 6px;
	border-radius: 0px; 
	box-shadow: none;
	border-radius: 2px;
	height: auto;
}
.progress .progress-bar {
	background: #EF7E2B;
	height: 6px;
	border-radius: 0px;
}

.mainTitle {
	color: #151515;
	font-size: 25px;
	font-weight: 600;
	margin-bottom: 8px;
}

.selectAvtara {
	display: flex;
	justify-content: space-between;
	margin: 40px 0px;
}

.selectAvtara input[type="radio"] {
	display: none;
}

.selectAvtara :checked+label {
	border-color: #FE831D;
	color: #FE831D;
}

.selectAvtara :checked+label span img {
	fill: #EF7E2B;
	filter: invert(49%) sepia(30%) saturate(6866%) hue-rotate(351deg) brightness(107%) contrast(88%)
}

.selectAvtara label {
	font-weight: 500;
	color: #151515;
	border: 1px solid #D7D7DC;
	border-radius: 20px;
	padding: 15px 25px;
	display: block;
	position: relative;
	cursor: pointer;
	text-align: center;
	margin-bottom: 0px;
}

.selectAvtara label:before {
	background-color: #fff;
	color: #fff;
	content: " ";
	display: block;
	border-radius: 50%;
	border: 1px solid #EF7E2B;
	position: absolute;
	top: 22px;
	right: 22px;
	z-index: 1;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 22px;
	transition-duration: 0.4s;
	transform: scale(0);
}

.selectAvtara label span {
	display: block;
}

.selectAvtara label span img {
	max-width: 50px;
}

.selectAvtara_radio {
	appearance: none;
	display: none;
}

/*==== Create account ====*/
.orangeText {
	color: #EF7E2B;
}

.verificationWrap {
	max-width: 500px;
	margin: 0 auto;
	padding: 20px;
}

.otpFild {
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
}

.otpInput {
	border-bottom: 1px solid #ccc;
	max-width: 100px;
	height: 40px;
	text-align: center;
	font-weight: 600;
}

/*======== set Password  ========*/
.subHeader {
	margin-bottom: 25px;
}

.selectpasswordType {
	margin: 0px;
	padding: 0px;
}

.selectpasswordType li {
	display: inline-block;
	margin-top: 10px;
	margin-right: 7px;
	border: 1px solid #D9D9D9;
	padding: 5px 10px 5px 30px;
	position: relative;
	border-radius: 50px;
	text-align: center;
	margin-bottom: 0px;
	font-size: 14px;
}

.selectpasswordType li:after {
	content: '';
	width: 15px;
	height: 8px;
	background: url(../images/ic_correct.svg) no-repeat;
	position: absolute;
	left: 13px;
	top: 12px;
}

/*====== upload document =======*/
.icon_div {
	position: relative;
}

/* .input-group { position: relative; display: flex; flex-wrap: wrap; align-items: stretch; width: 100%;} */
.form-group .custom-file-button input[type=file]::-webkit-file-upload-button {
	display: none;
}

.form-group .custom-file-button input[type=file]::file-selector-button {
	display: none;
}

.form-group .custom-file-button:hover label {
	background-color: transparent;
	cursor: pointer;
}

.form-group .input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
	border: 1px dashed #FE831D;
	border-radius: 10px;
	justify-content: center;
}

.form-group .input-group-text {
	height: 38px;
	line-height: 26px;
	margin-bottom: 0px !important;
	border-radius: 2px 0px 0px 2px;
	border: transparent;
	background: transparent;
	color: #EF7E2B;
	font-size: 14px;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
	display: block;
}

.input-group.custom-file-button input.form-control {
	border: transparent;
	border-radius: 10px;
	display: none;
}

select.form-control {
	border: none;
	border-bottom: 1px solid #ced4da;
	border-radius: 0px;
}

.carousel-inner .carousel-item {
	background: #eee;
	padding: 15px;
	border-radius: 3px;
}

.explain {
	color: #373737;
	font-weight: 600;
	font-size: 14px;
	padding-top: 15px;
	text-align: center;
}

.form-group .input-group-text img {
	display: inline-block;
	vertical-align: middle;
	width: 15px;
	margin-right: 10px;
}


/*========= complete Profile CSS  =========*/
.topHeader {
	position: relative;
	margin-bottom: 50px;
}

.topHeader .skipBtn {
	position: absolute;
	top: 3px;
	right:20px;
	color: #727272;
	font-size: 16px;
	font-weight: 500;
}

.profileInfo {
	display: flex;
}

.profileName {
	margin-left: 40px;
}

ul.profilechecks li {
	display: inline-block;
	margin-right: 30px;
}

.profile-upload {
	position: relative;
	max-width: 100px;
}

.profile-upload .proEdit {
	position: absolute;
	top: -8px;
	right: -8px;
	margin: auto;
	z-index: 1;
}

.profile-upload .proEdit input {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	z-index: 1;
}

.profile-upload .proEdit input+label {
	width: 30px;
	height: 30px;
	line-height: 30px;
	margin-bottom: 0;
	background: #FFFFFF;
	cursor: pointer;
	font-weight: normal;
	transition: all 0.2s ease-in-out;
	text-align: center;
	font-size: 20px;
	border-radius: 100px;
}

.profile-upload .avatar-preview {
	width: 100px;
	height: 100px;
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
	overflow: hidden;
	border-radius: 50%;
	/* box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%); */
}

.profile-upload .avatar-preview div {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: 0;
	bottom: 0;
	background-position: center;
	background-size: cover;
	border-radius: 50px;
}

.profile-upload .avatar-preview div {
	display: block !important;
}

.profileName p.email {
	position: relative;
	display: inline-block;
	margin-bottom: 20px;
}

.profileName p.email:after {
	content: '';
	width: 19px;
	height: 19px;
	position: absolute;
	right: -25px;
	top: 2px;
	background: #FE831D url(../images/ic_correct-w.svg) no-repeat;
	border-radius: 100px;
	background-position: center;
}


.subTitle {
	font-size: 20px;
	margin-bottom: 25px;
	font-weight: 600;
	color: #151515;
}

.enterManually span {
	font-size: 16px;
	font-weight: 500;
	margin: 20px 0px;
	display: inline-block;
	vertical-align: middle;
}

.completeProfile textarea.form-control {
	border: none;
	border-bottom: 1px solid #ced4da;
	border-radius: 0px;
	padding: 0.375rem 0rem;
}

/**check box**/

/*========= radioWrap ========= */
.d-flex.spaceRight label {
	padding-right: 20px;
}

.radioWrap [type="radio"]:checked,
.radioWrap [type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}

/* .radioWrap [type="radio"]:checked+label,
.radioWrap [type="radio"]:not(:checked)+label {
	position: relative;
	padding-left: 22px;
	cursor: pointer;
	line-height: 16px;
	font-size: 18px;
	display: inline-block;
	color: #151515;
	font-weight: 500;
}

.radioWrap [type="radio"]:checked+label:before,
.radioWrap [type="radio"]:not(:checked)+label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 2px;
	width: 16px;
	height: 16px;
	border: 1px solid #C3C3C3;
	border-radius: 4px;
	background: #fff;
} */

/* .radioWrap [type="radio"]:checked+label:after,
.radioWrap [type="radio"]:not(:checked)+label:after {
	position: absolute;
	top: 0px;
	left: 0px;
	border-radius: 4px;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	content: "✓";
	background-color: #EF7E2B;
	font-size: 12px;
	width: 20px;
	height: 20px;
	color: #fff;
	text-align: center;
}

.radioWrap [type="radio"]:not(:checked)+label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.radioWrap [type="radio"]:checked+label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
} */

.radioWrap article {
	width: 100%;
	height: 200px;
	display: none;
}

.radioWrap article.on {
	display: inline-block;
}

/*=== custom-checkbox ===*/
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
	background-color: #EF7E2B;
	border-color: #EF7E2B;
}

.custom-control-input:focus~.custom-control-label::before {
	box-shadow: none;
}

.custom-control-label::before {
	border: 1px solid #EF7E2B;
}

/*=== custom-checkbox end ===*/


.bluebox {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 12px 20px;
	background: #006599 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 5px #0000000A;
	border-radius: 12px;
	margin: 40px 0px;
}

.fetchBtn {
	background: #fff;
	padding: 10px;
	border-radius: 10px;
	font-size: 16px;
	font-weight: 600;
	color: #006599;
}

.fetchBtn img {
	width: 25px;
}

.bluebox p {
	color: #fff;
	display: inline-block;
	vertical-align: middle;
	margin-top: 10px;
	font-weight: 600;
}

.addressWrap .detectLocation {
	border: 1px dashed #F8F8F8;
	background: #F8F8F8;
	border-radius: 10px;
	display: inline-block;
	padding: 15px 20px;
	color: #151515;
	font-weight: 500;
	max-width: 450px;
	width: 100%;
	position: relative;
	min-height: 56px;
}

.addressWrap .detectLocation .allowBtn {
	border: 1px solid #FE831D;
	padding: 7px 10px;
	background: #F8F8F8;
	border-radius: 5px;
	margin-left: 50px;
	color: #FE831D;
	font-size: 15px;
	position: absolute;
	font-weight: 600;
	right: 18px;
	top: 8px;
}

.navbar-nav .nav-link .ic_down_arrow {
	position: absolute;
	top: 20px;
	right: 0px;
}



/*===== upload-document-family =======*/
.grayBox {
	background: #F8F8F8;
	border-radius: 5px;
	padding: 10px 20px;
}

.grayBox.d-flex {
	justify-content: space-between;
}

.grayBox p {
	font-weight: 500;
}




/* login css update */
/* Dropdown menus */
/* 21 feb */
.nav-profile {
	color: #012970;
}

.nav-profile img {
	max-height: 36px;
	width: 36px;
	height: 36px;
	margin-right: 10px;
}

.nav-profile span {
	font-size: 14px;
	font-weight: 600;
}

.nav-profile {
	color: #151515;
	font-weight: 600;
}

.dropdown-menu {
	border-radius: 20px;
	padding: 10px 0;
	-webkit-animation-name: dropdown-animate;
	animation-name: dropdown-animate;
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	border: 0;
	box-shadow: 0px 3px 16px #0000001A;
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer {
	text-align: center;
	font-size: 15px;
	padding: 10px 25px;
}

.dropdown-menu .dropdown-footer a {
	color: #444444;
	text-decoration: underline;
}

.dropdown-menu .dropdown-footer a:hover {
	text-decoration: none;
}

.dropdown-menu .dropdown-divider {
	color: #a5c5fe;
	margin: 0;
	border: 1px solid #D6D6D6;
}

.dropdown-menu .dropdown-item {
	font-size: 14px;
	padding: 10px 15px;
	transition: 0.3s;
}

.dropdown-menu .dropdown-item i {
	margin-right: 10px;
	font-size: 18px;
	line-height: 0;
}

.dropdown-menu .dropdown-item:hover {
	background-color: #f6f9ff;
}




@-webkit-keyframes dropdown-animate {
0%{opacity:0}
100%{opacity:1}
0%{opacity:0}
}
@keyframes dropdown-animate {
0%{opacity:0}
100%{opacity:1}
0%{opacity:0}
}
.bkbtn{display:flex;align-items:center}
.heading2{color:#151515;font-size:28px;font-weight:600}
.maindiv{display:flex;align-items:flex-start;justify-content:space-evenly}
.linksent{margin:0 auto;text-align:center;width:500px}
.linksent img{height:150px;width:150px;margin-bottom:20px}
.linksent .para3{margin-bottom:100px}
.form-control::placeholder{color:rgba(21,21,21,0.4);font-weight:400}
.form-control:-ms-input-placeholder{color:rgba(21,21,21,0.4);font-weight:400}
.form-control::-ms-input-placeholder{color:rgba(21,21,21,0.4);font-weight:400}
.form-control{padding:0}
.upload-document-family{width:900px;margin:0 auto}
.upload-document-family .custom-file-button{margin-top:10px}
.auth{margin:40px 0}
.datepicker img{position:absolute;top:0;right:0}
.blue{color:#2E76D8;font-weight:600;font-size:15px}
.name{color:#151515;font-weight:500;text-transform:capitalize}
.btnDiv{display:flex}
.btnDiv .form-group{margin-right:20px}
.btnDiv .form-group .commonBtn{width:200px;padding:8px 20px}
.ng-otp-input-wrapper{display:flex;justify-content:space-between;margin-top:30px;margin-bottom:1rem}
.otp-input{border:none!important;border-bottom:1px solid #ccc!important;width:100px!important;height:40px!important;text-align:center;border-radius:0!important;margin:0!important}
input:autofill{background:#fff}
input:-internal-autofill-selected{background:transparent}
.registerModal h5{letter-spacing:0;color:#151515;font-weight:600}
.registerModal .close{color:#AFAFAF}
.registerModal p{letter-spacing:0;color:#373737;font-weight:400}
.selectUser li:first-child span{margin-left:60px}
.employerlayout .loginTitle{color:#151515;font-weight:600}
.loginImage img{width:inherit;height:550px;margin:0 auto}
.example{color:#727272;font-size:12px;font-weight:400;margin-top:5px}
.employerlayout a.register{color:#EF7E2B}
.linksent .orangeText{font-weight:600}
.email-verify{margin-top:60px}
.email-verify .linksent img{height:200px;width:200px}
.addModal .list{justify-content:center}
.addModal .list a{display:block}
.greyBtn{border:1px solid #676767;border-radius:12px;margin-right:10px;width:150px}
.profileName .para3{width:60%}
.addModal h3{color:#151515;font-weight:600;font-size:20px}
.addModal p{font-size:14px;color:#373737;width:75%;margin:10px auto;text-align:center}
.para5{color:#727272;font-size:10px;margin-top:4px}
.uppercasetext{color:#151515;font-size:19px;font-weight:600;margin-bottom:10px}
.haveAccount p{margin-bottom:10px}
/* --00 */

p{color:#373737;font-weight:400}
.scrolled-offset{margin-top:70px}
input.form-control{border:none;border-bottom:1px solid #ccc;border-radius:0;padding:.375rem 0;outline:none;padding-right:30px}
.form-group{position:relative;margin-bottom:25px}
.form-control:focus{color:#495057;background-color:#fff;border-color:#495057;outline:0;box-shadow:none}
.form-group .forgot-btn{color:#EF7E2B;font-weight:500}
.commonBtn{width:100%;background:#EF7E2B;background:-moz-linear-gradient(left,#EF7E2B 0%,#EF7E2B 100%);background:-webkit-linear-gradient(left,#EF7E2B 0%,#EF7E2B 100%);background:linear-gradient(to right,#EF7E2B 0%,#EF7E2B 100%);color:#fff;font-size:1em;font-weight:400;border-radius:8px;padding:10px 20px;display:inline-block;vertical-align:top;cursor:pointer;text-align:center;-webkit-transition:.3s all ease-in-out;-o-transition:.3s all ease-in-out;transition:.3s all ease-in-out}
.commonBtn:hover{color:#fff}
.commonBtn.unfill{background:#F8F8F8;color:#151515}
.commonBtn.unfill:hover{background:#EF7E2B}
.socialMediaLink{text-align:center;padding:10px 20px;background:#F8F8F8;color:#151515;display:block;align-items:center;border-radius:12px}
.socialMediaLink .icon{width:25px;margin-right:15px;position:absolute;left:25px}
.loginImg{width:100%;height:100%;background:url(../images/ill_login.svg) no-repeat;background-size:contain;background-position:bottom;position:absolute;left:0;top:0;text-align:center}
.ht{height:20px}
.socialMediaLink .text{font-weight:500;font-size:14px;color:#151515}
.navbar-nav .nav-link{float:right}
.navbar-nav .nav-link i.ic_profile{width:30px;height:30px;background:#ccc;border-radius:50px;display:inline-block;vertical-align:middle;text-align:center;padding:5px}
.navbar-nav .nav-link i.ic_profile img{width:20px;height:20px}
.dropdown-menu{padding:0}
.dropdown-menu ul a{letter-spacing:.28px;color:#151515;font-size:14px;display:inline-block;width:100%;padding:10px 25px}
li.nav-item.dropdown .dropdown-toggle::after{display:none}
.nav-profile img.arrow{height:6px;margin-right:0;right:0}
a.nav-link.dropdown-toggle{padding-right:20px}
#header{box-shadow:none;border-bottom:1px solid #D7D7DC}
.registerModal{border-radius:20px}
.registerModal .modal-body{padding:50px 30px!important}
ul.selectUser{margin:35px 0}
ul.selectUser li{text-align:left}
.registerModal button.close{position:absolute;right:20px;top:10px;width:20px;height:20px;font-size:35px}
.selectUser input[type="radio"]{display:none}
.selectUser :checked + label{border-color:#FE831D}
.selectUser label{border:1px solid #EBEAEA;padding:15px;display:block;position:relative;margin:10px;cursor:pointer;border-radius:10px;font-size:20px;font-weight:500}
.selectUser label:before{background-color:#fff;color:#fff;content:" ";display:block;border-radius:50%;border:1px solid #EF7E2B;position:absolute;top:30px;right:22px;z-index:1;width:20px;height:20px;text-align:center;line-height:22px;transition-duration:.4s;transform:scale(0)}
.selectUser :checked + label::before{content:"✓";background-color:#EF7E2B;transform:scale(1);font-size:14px}
.selectUser label .img,.selectUser li:first-child span.img{height:50px;display:inline-block;vertical-align:middle;max-width:100px;text-align:center;margin:0;min-width:80px}
.selectUser li:first-child span.ml-0{margin-left:15px!important}
.selectUser label img{width:90px}
.selectUser label span{vertical-align:middle;display:inline-block;margin-left:15px}
.mainTitle{color:#151515;font-size:25px;font-weight:600;margin-bottom:8px}
.selectAvtara{display:flex;justify-content:space-between;margin:40px 0}
.selectAvtara input[type="radio"]{display:none}
.selectAvtara :checked + label{border-color:#FE831D}
.selectAvtara :checked + label span svg{fill:#EF7E2B}
.selectAvtara label{border:1px solid #EBEAEA;padding:15px 25px;display:block;position:relative;cursor:pointer;border-radius:10px;text-align:center;margin-bottom:0}
.selectAvtara label:before{background-color:#fff;color:#fff;content:" ";display:block;border-radius:50%;border:1px solid #EF7E2B;position:absolute;top:22px;right:22px;z-index:1;width:20px;height:20px;text-align:center;line-height:22px;transition-duration:.4s;transform:scale(0)}
.selectAvtara label span{display:block}
.selectAvtara label span img{max-width:50px}
.selectAvtara_radio{appearance:none;display:none}
.orangeText{color:#EF7E2B}
.verificationWrap{max-width:500px;margin:0 auto;padding:20px}
.otpFild{display:flex;justify-content:space-between;margin-top:30px}
.otpInput{border-bottom:1px solid #ccc;max-width:100px;height:40px;text-align:center;font-weight:600}
.subHeader{margin-bottom:25px}
.selectpasswordType{margin:0;padding:0}
.selectpasswordType li{display:inline-block;margin-top:10px;margin-right:7px;border:1px solid #D9D9D9;padding:5px 10px 5px 30px;position:relative;border-radius:50px;text-align:center;margin-bottom:0;font-size:14px}
.selectpasswordType li:after{content:'';width:15px;height:8px;background:url(../images/ic_correct.svg) no-repeat;position:absolute;left:13px;top:12px}
.icon_div{position:relative}
.form-group .custom-file-button input[type=file]::-webkit-file-upload-button{display:none}
.form-group .custom-file-button input[type=file]::file-selector-button{display:none}
.form-group .custom-file-button:hover label{background-color:transparent;cursor:pointer}
.form-group .input-group{position:relative;display:flex;flex-wrap:wrap;align-items:stretch;width:100%;border:1px dashed #FE831D;border-radius:10px;justify-content:center}
.form-group .input-group-text{height:38px;line-height:26px;margin-bottom:0!important;border-radius:2px 0 0 2px;border:transparent;background:transparent;color:#EF7E2B;font-size:14px;font-weight:500}
.input-group.custom-file-button input.form-control{border:transparent;border-radius:10px;display:none}
select.form-control{border:none;border-bottom:1px solid #ced4da;border-radius:0}
.carousel-inner .carousel-item{background:#eee;padding:15px;border-radius:3px}
p.explain{color:#373737;font-weight:500;font-size:14px;padding-top:15px;text-align:center}
.form-group .input-group-text img{display:inline-block;vertical-align:middle;width:15px;margin-right:10px}
.topHeader{position:relative;margin-bottom:50px}
.profileInfo{display:flex}
.profileName{margin-left:40px}
ul.profilechecks li{display:inline-block;margin-right:30px}
.profile-upload{position:relative;max-width:100px}
.profile-upload .proEdit{position:absolute;top:-8px;right:-8px;margin:auto;z-index:1}
.profile-upload .proEdit input{position:absolute;width:100%;height:100%;opacity:0;left:0;right:0;top:0;bottom:0;margin:auto;z-index:1}
.profile-upload .proEdit input + label{width:30px;height:30px;line-height:30px;margin-bottom:0;background:#FFF;cursor:pointer;font-weight:400;transition:all .2s ease-in-out;text-align:center;font-size:20px;border-radius:100px}
.profile-upload .avatar-preview{width:100px;height:100px;position:relative;display:inline-block;vertical-align:middle;margin-right:5px;overflow:hidden;border-radius:50%}
.profile-upload .avatar-preview div{position:absolute;left:0;right:0;margin:auto;top:0;bottom:0;background-position:center;background-size:cover;border-radius:50px}
.profile-upload .avatar-preview div{display:block!important}
.profileName p.email{position:relative;display:inline-block;margin-bottom:20px}
.profileName p.email:after{content:'';width:19px;height:19px;position:absolute;right:-25px;top:2px;background:#FE831D url(../images/ic_correct-w.svg) no-repeat;border-radius:100px;background-position:center}
.subTitle{font-size:20px;margin-bottom:25px;font-weight:600;color:#151515}
.enterManually span{font-size:16px;font-weight:500;margin:20px 0;display:inline-block;vertical-align:middle}
.completeProfile textarea.form-control{border:none;border-bottom:1px solid #ced4da;border-radius:0;padding:.375rem 0}
.d-flex.spaceRight label{padding-right:20px}
.radioWrap [type="radio"]:checked,.radioWrap [type="radio"]:not(:checked){position:absolute;left:-9999px}
.radioWrap [type="radio"]:checked + label:before,.radioWrap [type="radio"]:not(:checked) + label:before{content:'';position:absolute;left:0;top:0;width:16px;height:16px;border:1px solid #C3C3C3;border-radius:4px;background:#fff}
.radioWrap [type="radio"]:checked + label:after,.radioWrap [type="radio"]:checked + label:after{opacity:1;-webkit-transform:scale(1);transform:scale(1)}
.radioWrap article{width:100%;height:200px;display:none}
.radioWrap article.on{display:inline-block}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{background-color:#EF7E2B;border-color:#EF7E2B}
.custom-control-input:focus~.custom-control-label::before{box-shadow:none}
.custom-control-label::before{border:1px solid #EF7E2B}
.bluebox{display:flex;justify-content:space-between;width:100%;padding:12px 20px;background:#006599 0 0 no-repeat padding-box;box-shadow:0 1px 5px #0000000A;border-radius:12px;margin:40px 0}
.fetchBtn{background:#fff;padding:10px;border-radius:10px;font-size:16px;font-weight:600;color:#006599}
.fetchBtn img{width:25px}
.bluebox p{color:#fff;display:inline-block;vertical-align:middle;margin-top:10px;font-weight:600}
.navbar-nav .nav-link .ic_down_arrow{position:absolute;top:20px;right:0}
.grayBox{background:#F8F8F8;border-radius:5px;padding:10px 20px}
.grayBox.d-flex{justify-content:space-between}
.grayBox p{font-weight:500}
.nav-profile{color:#012970}
.nav-profile img{max-height:36px;width:36px;height:36px;margin-right:10px}
.nav-profile span{font-size:14px;font-weight:600}
.nav-profile{color:#151515;font-weight:600}
.dropdown-menu{border-radius:20px;padding:10px 0;-webkit-animation-name:dropdown-animate;animation-name:dropdown-animate;-webkit-animation-duration:.2s;animation-duration:.2s;-webkit-animation-fill-mode:both;animation-fill-mode:both;border:0;box-shadow:0 3px 16px #0000001A;width:180px;margin-top:20px}
.dropdown-menu ul li{padding:0}
.dropdown-menu .dropdown-header,.dropdown-menu .dropdown-footer{text-align:center;font-size:15px;padding:10px 25px}
.dropdown-menu .dropdown-footer a{color:#444;text-decoration:underline}
.dropdown-menu .dropdown-footer a:hover{text-decoration:none}
.dropdown-menu .dropdown-divider{color:#a5c5fe;margin:0;border:1px solid #D6D6D6}
.dropdown-menu .dropdown-item{font-size:14px;padding:10px 15px;transition:.3s}
.dropdown-menu .dropdown-item i{margin-right:10px;font-size:18px;line-height:0}
.dropdown-menu .dropdown-item:hover{background-color:#f6f9ff}
@-webkit-keyframes dropdown-animate {
0%{opacity:0}
100%{opacity:1}
0%{opacity:0}
}
@keyframes dropdown-animate {
0%{opacity:0}
100%{opacity:1}
0%{opacity:0}
}
.para3{color:#373737}
.linksent{margin:0 auto;text-align:center;width:500px}
.linksent img{height:150px;width:150px;margin-bottom:20px}
.linksent .para3{margin-bottom:100px}
.form-control::placeholder{color:#151515;font-weight:400;color:rgba(21,21,21,0.4)}
.form-control:-ms-input-placeholder{color:#151515;font-weight:400;color:rgba(21,21,21,0.4)}
.form-control::-ms-input-placeholder{color:#151515;font-weight:400;color:rgba(21,21,21,0.4)}
.form-control{padding:0}
.upload-document-family{width:900px;margin:0 auto}
.upload-document-family .custom-file-button{margin-top:10px}
.auth{margin:40px 0}
.datepicker{position:relative}
.datepicker img{position:absolute;top:0;right:0}
.blue{color:#2E76D8;font-weight:600;font-size:15px}
.btnDiv{display:flex}
.btnDiv .form-group{margin-right:20px}
.btnDiv .form-group .commonBtn{width:180px;padding:8px 20px}
.ng-otp-input-wrapper{display:flex;justify-content:space-between;margin-top:30px;margin-bottom:1rem}
.otp-input{border:none!important;border-bottom:1px solid #ccc!important;width:100px!important;height:40px!important;text-align:center;border-radius:0!important;margin:0!important}


@media (max-width: 1599px) {
	.loginTitle{font-size:20px}
	.haveAccount p{font-size:14px;margin-bottom:8px}
	.commonBtn{padding:8px 20px}
	.loginText,.subHeader{margin-bottom:18px}
	.mainTitle,.heading2{font-size:20px}
}

.para3 {
	color: #373737;
}

.maindiv {
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
}

.linksent {
	margin: 0 auto;
	text-align: center;
	width: 500px;
}

.linksent img {
	height: 150px;
	width: 150px;
	margin-bottom: 20px;
}

.linksent .para3 {
	margin-bottom: 100px;
}


.form-control::placeholder {
	color: rgba(21, 21, 21, 0.4);
	font-weight: 400;

}

.form-control:-ms-input-placeholder {
	color: rgba(21, 21, 21, 0.4);
	font-weight: 400;
}

.form-control::-ms-input-placeholder {
	color: rgba(21, 21, 21, 0.4);
	font-weight: 400;
}

.form-control {
	padding: 0;
}

/* 22 feb */

.upload-document-family {
	width: 900px;
	margin: 0 auto;
}

.upload-document-family .custom-file-button {
	margin-top: 10px;
}

.auth {
	margin: 40px 0px;
}



.datepicker img {
	position: absolute;
	top: 0;
	right: 0;
}

.blue {
	color: #2E76D8;
	font-weight: 600;
	font-size: 15px;
}

.name {color: #151515;font-weight: 500;text-transform: capitalize;}

.btnDiv {
	display: flex;
}

.btnDiv .form-group {
	margin-right: 20px;
}

.btnDiv .form-group .commonBtn {
	width: 200px;
	padding: 8px 20px;
}




/* otp screen*/
.ng-otp-input-wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
	margin-bottom: 1rem;
}

.otp-input {
	border: none !important;
	border-bottom: 1px solid #ccc !important;
	width: 100px !important;
	height: 40px !important;
	text-align: center;
	border-radius: 0px !important;
	margin: 0 !important;
}


/* 1 March */
input:autofill {
	background: #fff;
}

input:-internal-autofill-selected {
	background: transparent;
}

.registerModal h5 {
	letter-spacing: 0px;
	color: #151515;
	font-weight: 600;
}

.registerModal .close {
	color: #AFAFAF;
	;
}

.registerModal p {
	letter-spacing: 0px;
	color: #373737;
	font-weight: 400;
}

.selectUser li:first-child span {
	margin-left: 60px;
}



/* end */

/* 13 March employer login changes*/
.employerlayout .loginTitle {
	color: #151515;
	font-weight: 600;
}

.loginImage img {
	width: inherit;
	/* height: 550px; */
	margin: 0 auto;
}

.example {
	color: #727272;
	font-size: 12px;
	font-weight: 400;
	margin-top: 5px
}

.employerlayout a.register {
	color: #EF7E2B;
}

.linksent .orangeText {
	font-weight: 600;
}

.email-verify {
	margin-top: 60px;
}

.email-verify .linksent img {
	height: 200px;
	width: 200px;
}

.addModal .list {
	justify-content: center;
}

.addModal .list a {
	display: block
}
.greyBtn {
	border: 1px solid #676767;
	border-radius: 12px;
	margin-right: 10px;
	width: 150px;

}

.profileName .para3 {
	width: 60%;
}

.addModal h3 {
	color: #151515;
	font-weight: 600;
	font-size: 20px;
}

.addModal p {
	font-size: 14px;
	color: #373737;
	width: 75%;
	margin: 10px auto;
	text-align: center;
}

.para5 {
	color: #727272;
	font-size: 10px;
	margin-top: 4px;
}

.uppercasetext {
	color: #151515;
	font-size: 19px;
	font-weight: 600;
	margin-bottom: 10px;
}
.haveAccount p{margin-bottom: 10px;}
/* responsive */
@media (max-width: 1599px) {
  /* login css */
  .loginTitle {
    font-size: 20px;
} 
.haveAccount p{font-size: 14px;margin-bottom: 8px;}
.commonBtn{padding: 8px 20px;}
} 

@media (max-width: 1199px) {
	.loginTitle{font-size:18px}
	.loginText{margin-bottom:15px}
	.commonBtn{padding:8px 20px}
	.selectpasswordType li{font-size:13px}
}
@media (max-width:996px) {
	.upload-document-family{width:auto}
	.addressWrap .detectLocation .allowBtn{margin-left:0;font-size:12px}
}
@media(max-width: 992px) {
	.loginPage{padding:25px 0;margin-top:25px}
	.topHeader{margin-bottom:25px}
	.loginPage .col-lg-4.col-md-4{max-width:100%}
	.profileInfo{margin-bottom:25px!important}
	.profileName .d-flex.spaceRight.radioWrap{display:block!important}
	.profileName{margin-left:30px}
	.subTitle{margin-bottom:10px}
	.topHeader{margin-bottom:25px}
	.loginPage .col-lg-4.col-md-4{max-width:100%}
	.profileInfo{margin-bottom:25px!important}
	.profileName .d-flex.spaceRight.radioWrap{display:block!important}
	.profileName{margin-left:30px}
	.subTitle{margin-bottom:10px}

}
@media (min-width: 768px) {
	.dropdown-menu::before{content:"";width:13px;height:13px;background:#fff;position:absolute;top:-7px;right:34px;transform:rotate(45deg);border-top:1px solid #fff;border-left:1px solid #fff}


}
@media (max-width:767px) {
	
	.otp-input{max-width:60px}
	.email-verify{margin-top:20px}
	.addressWrap .detectLocation{margin-bottom:20px}
}
@media (max-width:520px) {
	.maindiv{display:block}
	.dropdown-menu ul a{padding:10px 5px}
	.linksent,.upload-document-family{width:auto}
	.auth{margin:20px 0}
	.profileInfo{flex-wrap:wrap}
	.profileName{margin-left:0}
	.bluebox{flex-wrap:wrap;justify-content:center}
	.fetchBtn{margin:10px 0}
	.addressWrap .detectLocation .allowBtn{margin-left:5px}
	.btnDiv{flex-wrap:wrap;justify-content:center}
	.btnDiv .form-group .commonBtn{width:180px}
	.selectUser label::before{top:20px}
	#registerModal .modal-dialog-centered{min-height:calc(100% - 15rem)}
	.otp-input{max-width:55px;width:55px!important}
	.selectUser li:first-child span,.selectUser li span{margin-left:0}
	.verificationWrap p strong{word-wrap:break-word}
}
@media (max-width:480px) {
	.selectUser li:first-child span,.selectUser li span,.selectUser label .img,.selectUser li:first-child span.img{margin-left:0;width:100%;display:block;margin:auto;text-align:center}
	}
@media (max-width:420px) {
	.mainTitle{font-size:20px!important}
	.loginText{margin-top:10px;margin-bottom:10px}
	.selectpasswordType li{padding:5px!important;font-size:12px!important}
	.selectpasswordType li.active::after,.selectpasswordType li::after{display:none!important}
	.selectUser li:first-child span,.selectUser li span{font-size:14px}
}
.ngx-slider.animate .ngx-slider-bubble.ngx-slider-limit{
	display: none !important;
}
