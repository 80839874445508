@import "https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap";

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none
}

ul,
li,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
  margin: 0;
  padding: 0
}

li {
  list-style: none
}

button,
button:focus {
  cursor: pointer;
  box-shadow: none;
  outline: none;
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -ms-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out
}

a,
a:hover,
a:active {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  outline: none;
  box-shadow: none;
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -ms-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out
}

body {
  background: #fff;
  position: relative
}

p {
  font-size: 16px;
  color: #373737;
  font-weight: 400;
  letter-spacing: 0
}

img {
  vertical-align: top
}

.firstlCaptial {
  display: inline-block;
  text-align: left;
}

.firstlCaptial:first-letter {
  text-transform: uppercase
}

.text-right {
  text-align: right
}

html {
  height: 100%
}

.innerpage {
  background-color: #F8F8F8
}

.pd-tb {
  padding: 60px 0 0
}

.borderBtn {
  border: 1px solid #FE831D;
  border-radius: 12px;
  color: #FE831D
}

.orangeBtn {
  border: 1px solid #FE831D;
  border-radius: 12px;
  background: #EF7E2B 0 0 no-repeat padding-box;
  display: inline-block
}

#header {
  height: 70px;
  transition: all .5s;
  z-index: 997;
  transition: all .5s;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1)
}

#header .logo h1 {
  font-size: 26px;
  padding: 0 0 0 8px;
  margin: 11px 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  border-left: 8px solid #e96b56
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #545454;
  text-decoration: none
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 50px
}

.scrolled-offset {
  margin-top: 70px
}

.headerContainer {
  position: relative
}

.navbar ul,
.list {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center
}

.navbar a,
.navbar a:focus,
.list a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  font-size: 14px;
  color: #151515;
  white-space: nowrap;
  transition: .3s;
  font-weight: 500
}

.navbar .li-center li {
  padding: 10px 20px
}

.navbar .li-center a,
.navbar .li-center a:focus {
  padding: 0
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #e96b56;
  border-bottom-color: #e96b56
}

.navbar .li-center li {
  position: relative
}

.navbar .li-center li a::after {
  content: "";
  width: 35%;
  height: 2px;
  background: #e96b56;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -15px;
  opacity: 0;
  transition: all ease-in-out .4s;
  -ms-transition: all ease-in-out .4s;
  -moz-transition: all ease-in-out .4s;
  -webkit-transition: all ease-in-out .4s
}

.navbar .li-center li a.active::after,
.navbar .li-center li:hover a::after {
  opacity: 1
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #e96b56;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #ec7f6d
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0 3px 16px #0000001A;
  transition: .3s;
  border: 0
}

.navbar .dropdown ul li {
  min-width: 200px
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #545454
}

.navbar .dropdown ul a i {
  font-size: 12px
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #e96b56
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible
}

.nav-profile img {
  max-height: 36px
}

.rounded-circle {
  border-radius: 50% !important
}

.nav-profile span {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  color: #151515
}

.profile .dropdown-item:hover {
  background-color: #fff
}

.mobile-nav-toggle {
  color: #545454;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: .5s
}

.mobile-nav-toggle.bi-x {
  color: #fff
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(59, 59, 59, 0.9);
  transition: .3s;
  z-index: 999
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: .3s
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #545454
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #e96b56;
  border-bottom-color: #e96b56
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0 0 30px rgba(127, 137, 161, 0.25)
}

.navbar-mobile .dropdown ul li {
  min-width: 200px
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #e96b56;
  border-bottom-color: #e96b56
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block
}

#header .search-bar {
  min-width: 260px;
  padding: 0 20px;
  position: relative;
  padding-left: 40px
}

#header .search-bar img {
  height: 20px;
  width: 16px
}

#header .search-form {
  width: 100%;
  position: relative
}

#header .search-form input {
  border: 0;
  font-size: 14px;
  color: #012970;
  border: 1px solid #D6D6D6;
  border-radius: 28px;
  padding: 7px 8px 8px 38px;
  transition: .3s;
  width: 100%
}

#header.search-form input:focus,
#header.search-form input:hover {
  outline: none;
  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
  border: 1px solid rgba(1, 41, 112, 0.3)
}

#header .search-form button {
  border: 0;
  padding: 0;
  position: absolute;
  background: none;
  left: 10px
}

.list li {
  margin-left: 15px;
  padding: 0
}

a.borderBtn {
  color: #FE831D
}

a.orangeBtn,
.navbar .list li a.orangeBtn:hover {
  color: #fff
}

a.addMoreBtn.orangeBtn:hover {
  color: #fff
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 500px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0)
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  padding: 20px 40px
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px
}

.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity .3s linear, left .3s ease-out;
  -moz-transition: opacity .3s linear, left .3s ease-out;
  -o-transition: opacity .3s linear, left .3s ease-out;
  transition: opacity .3s linear, left .3s ease-out
}

.modal.left.fade.in .modal-dialog {
  left: 0
}

.modal.right.fade .modal-dialog {
  right: 0;
  -webkit-transition: opacity .3s linear, right .3s ease-out;
  -moz-transition: opacity .3s linear, right .3s ease-out;
  -o-transition: opacity .3s linear, right .3s ease-out;
  transition: opacity .3s linear, right .3s ease-out
}

.modal.right.fade.in .modal-dialog {
  right: 0
}

.modal-content {
  border-radius: 0;
  border: none
}

.modal h2,
h2 span {
  color: #1A1918;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 10px
}

.btn-main.borders {
  background: transparent;
  color: #EE7E2B;
  border: 1px solid #FE831D
}

.orange {
  color: #EE7E2B
}

.para {
  font-size: 16px;
  font-weight: 400;
  color: #5A5A5A
}

.modal-footer {
  border: 0;
  letter-spacing: 0;
  color: #5A5A5A;
  font-size: 14px;
  display: flex;
  justify-content: flex-start
}

.modal-footer ul li {
  margin: 0 15px 0 0
}

.back {
  font-size: 16px;
  display: flex;
  align-items: center
}

.back img {
  height: 20px
}

.form-element label {
  margin-bottom: 5px
}

.form-element input[type="text"],
.form-element input[type="password"],
.form-element input[type="email"],
.form-element input[type="search"] {
  display: block;
  width: 100%;
  height: 25px;
  padding: 0;
  font-size: 14px;
  line-height: 1.42857143;
  color: #666;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.form-element {
  position: relative;
  margin: 15px 0 5px;
  display: inline-block;
  width: 100%
}

.form-element select:focus {
  border: 0;
  border-bottom: solid 1px #FFC840
}

.form-element input:focus {
  border-bottom: solid 1px #35A2E8;
  outline: none
}

.form-element textarea:focus {
  border-bottom: solid 1px #000
}

.form-element .hide {
  position: absolute;
  right: 0;
  bottom: 10%
}

.rt {
  text-align: right
}

.fgt {
  color: #151515;
  font-weight: 600;
  font-size: 14px
}

.error {
  letter-spacing: .28px;
  color: #EA0909;
  font-size: 12px;
  display: none
}

.btn-main {
  display: inline-block;
  padding: 9px 28px;
  background: #EF7E2B 0 0 no-repeat padding-box;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 35px;
  transition: all ease-In-Out .3s;
  margin: 20px 0 10px;
  width: 100%
}

.btn-main .icon {
  height: 20px
}

.btn-main.transparent {
  border: 1px solid #DFDFDF;
  background: transparent;
  color: #434346;
  display: flex;
  justify-content: space-evenly;
  align-items: center
}

.select-btn {
  position: relative;
  margin: 20px 0;
  padding: 0 5px;
  color: #151515
}

.select-btn input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0
}

.color-1 {
  width: 100%;
  display: block;
  border: 1px solid #D7D7DC;
  border-radius: 20px;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  background-color: #fcfcfc
}

.color-1 img {
  height: 60px;
  width: 60px
}

.color-1:before {
  left: 0;
  top: 0;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  -webkit-transform: scale3d(0, 0, 0);
  -ms-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
  -webkit-transition: all 250ms;
  -o-transition: all 250ms;
  transition: all 250ms;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 0
}

.role input[type="checkbox"]:checked~.color-1 {
  background: #fff !important;
  color: #FA9365;
  border: 1px solid #FA9365
}

.wrapper.employers {
  margin-top: 48px
}

.banner {
  position: relative;
  padding: 70px 0 0;
  background: linear-gradient(180deg, rgba(244, 232, 226, 1) 0%, rgba(243, 243, 236, 1) 100%)
}

.bannerContent .content {
  padding: 100px 0 120px;
  width: 100%;
  max-width: 672px
}

.bk {
  position: absolute;
  bottom: 0;
  margin: auto;
  width: 100%;
  left: 15%
}

.content .top-cta {
  font-size: 22px;
  font-weight: 600;
  color: #FE831D;
  margin-bottom: 10px
}

.mainheading {
  color: #1A1918;
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
  margin-bottom: 40px
}

.swiper-container_1 {
  position: relative
}

.para2 {
  color: #5A5A5A;
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 50px
}

.pd {
  padding: 10px 20px
}

.swiper-pagination-bullet {
  opacity: .8;
  background: #fff;
  color: #fff
}

.slick-dots li {
  width: 8px;
  height: 8px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: #fff
}

.slick-dots li button:before {
  color: #fff
}

.swiper-pagination-bullet-active,
.slick-dots li.slick-active button:before {
  background: #F6703B !important;
  color: #F6703B !important
}

.swiper-button-next,
.swiper-button-prev {
  background-image: none;
  background-size: 0;
  background-repeat: no-repeat;
  background-position: 0
}

.swiper-button-next .arrow-icon,
.swiper-button-prev .arrow-icon {
  font-size: 4rem;
  color: #fff
}

.swiper-pagination-bullet-active,
.slick-dots li.slick-active button:before {
  opacity: 1;
  background: #F6703B !important;
  color: #F6703B !important
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction,
.slick-dots {
  position: absolute;
  bottom: 20px !important;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%
}

.subheading {
  color: #1A1918;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: capitalize
}

.subheading a {
  font-size: 14px
}

.flexcss {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.mainJob {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap
}

.greyBox {
  background: #F8F8F8 0 0 no-repeat padding-box;
  border: 1px solid #F8F8F8;
  border-radius: 40px;
  padding: 20px 30px;
  margin: 10px 0
}

.greyBox h3 {
  color: #1A1918;
  font-size: 16px
}

.choice .greyBox {
  width: 200px;
  text-align: center;
  border-radius: 8px;
  padding: 0
}

.choice .greyBox img {
  padding: 20px 50px
}

.choice .greyBox .clientname {
  text-align: center;
  border-top: 1px solid #fff;
  background: #fff;
  padding: 10px
}

.employer {
  background: url(assets/images/talent-2.png) no-repeat;
  padding: 80px 40px 180px;
  border: 1px solid #D7D7DC;
  border-radius: 34px;
  background-position: bottom right;
  background-size: contain
}

.employerContent {
  width: 45%
}

.second-heading {
  color: #1A1918;
  font-weight: 600;
  font-size: 40px
}

.employerContent h5 {
  font-weight: 500;
  margin-bottom: 10px
}

.employerContent .second-heading {
  font-size: 55px;
  margin-bottom: 25px
}

.employerContent p {
  font-size: 22px;
  margin-bottom: 30px
}

.employerContent .orangeBtn {
  font-size: 20px;
  font-weight: 600
}

.card-grid-2 {
  border-radius: 20px;
  border: 1px solid #D7D7DC;
  overflow: hidden;
  margin-bottom: 24px;
  position: relative;
  background: #fff
}

.hover-up:hover {
  transform: translateY(-3px)
}

.card-grid-2:hover {
  border-color: #b4c0e0;
  background-color: #f8faff
}

.hover-up,
.hover-up:hover {
  transition: all .25s cubic-bezier(.02, .01, .47, 1)
}

.card-grid-2 .card-grid-2-image-left {
  padding: 30px 10px 15px;
  display: flex;
  position: relative
}

.greyBox {
  position: relative
}

.greyBox .featured,
.card-grid-2 .featured {
  position: absolute;
  top: 30px;
  padding: 4px;
  color: #fff;
  font-size: 10px;
  background: #EF7E2B 0 0 no-repeat padding-box;
  border-radius: 5px;
  line-height: 15px;
  margin-left: 10px
}

.greyBox .featured {
  right: 78%;
  top: 12px;
  font-size: 7px
}

.card-grid-2 .card-grid-2-image-left .image-box {
  min-width: 52px;
  max-width: 100%;
  width: 52px;
  margin-right: 15px
}

.card-grid-2 .card-grid-2-image-left .image-box img {
  border-radius: 50%
}

.card-grid-2 .card-grid-2-image-left .right-info .name-job {
  font-size: 18px;
  line-height: 26px;
  color: #1A1918;
  font-weight: 600;
  display: block
}

.card-grid-2 .card-grid-2-image-left .right-info .location-small {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #737374
}

.card-block-info h6 {
  color: #151515;
  font-size: 18px;
  font-weight: 500
}

.card-block-info p.salary {
  color: #151515;
  font-size: 14px;
  font-weight: 400
}

.card-block-info span {
  color: #5A5A5A;
  font-size: 13px
}

.card-grid-2 .card-block-info {
  display: inline-block;
  width: 100%;
  padding: 5px 10px 20px
}

.card-block-info .mt-3 {
  height: 30px
}

.job-description {
  margin-top: 20px
}

.job-description h6,
.client-content h6,
.job-description p {
  color: #151515;
  font-weight: 500;
  letter-spacing: .28px;
  font-size: 18px;
  margin-bottom: 10px
}

.job-description p {
  font-size: 16px
}

.job_detail {
  letter-spacing: 0;
  color: #5A5A5A;
  font-size: 14px
}

.client-content .para {
  color: #292929;
  margin: 10px 0
}

.job-description p {
  color: #5A5A5A;
  font-weight: 500;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 110px
}

.clientimg {
  max-width: 400px;
  width: 100%;
  padding: 40px 0
}

.clientimg img {
  border-radius: 20px;
  max-width: 100% !important
}

.apply {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px
}

.apply img {
  height: 35px
}

.applybtn {
  padding: 8px 10px;
  text-align: center;
  width: 160px;
  font-weight: 600
}

.hire {
  background: url(assets/images/hire.png) 0 0/cover no-repeat;
  padding: 80px 40px;
  height: 400px
}

.hire-content {
  width: 60%
}

.third-heading,
.third-heading span {
  text-align: left;
  font-size: 46px;
  font-weight: 800;
  letter-spacing: 0
}

.white {
  color: #fff
}

.para-3 {
  letter-spacing: .08px;
  color: #FFF;
  font-size: 18px;
  margin: 20px 0
}

.feature-content {
  margin-right: 25px
}

.feature-content .para {
  font-size: 14px
}

.small-heading {
  letter-spacing: 0;
  color: #171821;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px
}

.steps .feature-content {
  background: #FAFAFA;
  padding: 40px;
  margin: 0;
  border-radius: 10px;
  position: relative
}

.steps .feature-content img {
  height: 38px
}

.board-range .feature-content img {
  height: 40px
}

.board-range .feature-content {
  margin-right: 0;
  padding-left: 40px
}

.board-range .feature-content .small-heading {
  font-size: 20px;
  line-height: 30px;
  color: #171821;
  margin-top: 20px
}

.resume {
  background: #EAEAEA url(assets/images/resume.png) no-repeat;
  padding: 80px 40px;
  height: auto;
  background-size: contain;
  background-position: right
}

.number {
  position: absolute;
  right: 40px;
  font-size: 30px;
  top: 30px;
  color: #D4D4D4;
  font-weight: 700
}

.upload {
  background: #FFF 0 0 no-repeat padding-box;
  border-radius: 30px;
  margin: 0 auto;
  text-align: center;
  width: 600px;
  max-width: 800px;
  padding: 40px;
  margin-top: 60px
}

.doc-haeding {
  color: #1A1918;
  font-size: 18px;
  font-weight: 600
}

.doc {
  background: #F8F8F8 0 0 no-repeat padding-box;
  border: 1px dashed #EBEBEB;
  border-radius: 10px
}

.upload p {
  margin-bottom: 10px
}

.documnet-name h6 {
  letter-spacing: 0;
  color: #151515;
  font-size: 16px
}

.documnet-name p {
  letter-spacing: 0;
  color: #5A5A5A
}

.doc {
  justify-content: space-between;
  display: flex;
  padding: 20px;
  align-items: center
}

#footer {
  background: #1A1918;
  padding: 0 0 30px;
  color: #fff;
  font-size: 14px
}

.footer-top {
  padding-top: 60px;
  padding-bottom: 40px
}

#footer p {
  letter-spacing: .3px;
  color: #C2BCBC;
  font-size: 14px
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
  color: #D9D9D9;
  letter-spacing: .28px
}

#footer h4 {
  letter-spacing: 0;
  color: #FFF;
  opacity: 1;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px
}

#footer .media {
  margin-top: 10px;
  display: flex
}

#footer .media img {
  height: 20px;
  margin-top: 2px;
  margin-right: 10px
}

footer ul {
  list-style: none;
  margin-top: 5px
}

ul[class*="list-"] {
  font-family: "Work Sans", sans-serif;
  font-size: 15px
}

.list-2 {
  display: table
}

.list-2 li {
  color: #D9D9D9;
  letter-spacing: .3px;
  float: left;
  padding: 10px 5px 5px 0;
  width: 50%;
  position: relative
}

footer .list-2 li a:hover,
.media-body a:hover {
  color: #e96b56
}

select#state,
select.form-control,
.select.form-control {
  background: url(assets/images/ic_down_arrow.svg) no-repeat;
  background-position: center right 15px;
  background-size: 11px;
  padding-right: 40px
}

bs-datepicker-container.bottom.ng-tns-c140-1.ng-star-inserted {
  transform: translate3d(798px, 410px, 0px) !important
}

.bs-datepicker-container {
  padding: 0 !important;
  padding-bottom: 10px !important
}

.theme-green .bs-datepicker-head {
  background-color: #EF7E2B
}

.theme-green .btn-today-wrapper .btn-success,
.theme-green .btn-clear-wrapper .btn-success {
  background-color: #EF7E2B;
  border-color: #EF7E2B
}

* {
  letter-spacing: .4px
}

.orangeText,
.orangeText:focus,
.orangeText:visited {
  color: #EF7E2B
}

.left-sidebar {
  background: #F8F8F8;
  width: 350px;
  padding: 40px 20px 40px 40px;
  height: 100vh;
  position: fixed
}

ul.timeline {
  position: relative
}

.timeline li:after {
  position: absolute;
  display: block;
  content: '';
  width: 1.5px;
  height: 100%;
  top: 30px;
  bottom: 0;
  left: 0;
  background-color: #727272;
  z-index: -1
}

.timeline .timeline-item span.timeline-point-circle.fill:after {
  background-color: #EF7E2B
}

.timeline li:last-child::after {
  background: transparent
}

.timeline .timeline-item {
  position: relative;
  color: #151515;
  padding: 25px 5px 10px 25px
}

.timeline .timeline-item span {
  font-size: 14px;
  color: #151515
}

.timeline .timeline-item span.bold {
  font-weight: 600;
  color: #151515
}

.timeline .timeline-item span.timeline-point-circle {
  height: 16px;
  width: 16px;
  background-color: #727272;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  left: -7px;
  top: 28px;
  display: inline-block
}

.timeline .timeline-item span.timeline-point-circle.fill {
  background: url(assets/images/filled.svg) no-repeat center
}

section.skillsWrap.scrolled-offset {
  display: flex;
  justify-content: flex-start
}

.pageTitle {
  margin-bottom: 25px;
  color: #151515;
  font-weight: 600;
  font-size: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center
}

.right-panel {
  padding: 70px 80px;
  width: calc(100% - 350px);
  margin-left: 350px
}

.form-group label span {
  display: inline-block
}

input.form-control {
  color: rgba(21, 21, 21);
  border: none;
  border-bottom: 1px solid #d6d6d6;
  border-radius: 0;
  padding: .375rem 0;
  outline: none;
  padding-right: 30px
}

textarea.form-control {
  color: rgba(21, 21, 21, 0.6);
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  padding: .375rem 0;
  outline: none;
  padding-right: 30px
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #495057;
  outline: 0;
  box-shadow: none
}

.form-group label.small-highlights {
  font-size: 12px;
  color: #373737;
  width: 100%;
  float: left;
  margin: 5px 0 15px;
  word-wrap: break-word;
  display: block;
  white-space: inherit;
  text-transform: inherit;
}

.subTitle {
  margin-top: 20px;
  font-size: 18px;
  margin-bottom: 10px
}

.form-group ul {
  margin: 0;
  padding: 0
}

.addedskills li {
  list-style: none;
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 50px;
  margin: 3px 3px 3px 0;
  border: 1px solid #EF7E2B
}

.addedskills li a {
  display: inline-block;
  vertical-align: middle;
  padding: 0 4px;
  background: #EF7E2B;
  color: #fff;
  border-radius: 35px;
  margin-left: 5px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px
}

.suggestedskills li {
  display: inline-block;
  font-size: 15px;
  margin-top: 10px;
  margin-right: 7px;
  border: 1px solid #d7d7d7;
  padding: 5px 10px 5px 30px;
  position: relative;
  border-radius: 50px;
  text-align: center;
  margin-bottom: 0;
  cursor: pointer
}

.suggestedskills li:after {
  content: '';
  width: 15px;
  height: 15px;
  background: url(assets/images/ic_add_skill.svg) no-repeat;
  position: absolute;
  left: 7px;
  top: 7px;
  background-size: 18px
}

.suggestedskills li.selected:after {
  content: '';
  width: 15px;
  height: 15px;
  background: url(assets/images/ic_correct.svg) no-repeat;
  position: absolute;
  left: 13px;
  top: 12px
}

.suggestedskills li.selected {
  color: #EF7E2B
}

.addedskills li {
  margin: 3px 15px 10px 0;
  cursor: pointer
}

.subTitle2 {
  color: #151515;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px
}

.commonBtn.unfill {
  background: #F8F8F8;
  color: #151515
}

.commonBtn.unfill:hover {
  background: #EF7E2B
}

.commonBtn.width50 {
  max-width: 250px
}

select.form-control {
  border: none;
  padding-left: 0;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  color: rgba(21, 21, 21, 0.6);
  position: relative
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #EF7E2B;
  border-color: #EF7E2B
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none
}

.custom-control-label::before {
  border: 1px solid #EF7E2B
}

.justify-content-between .form-group {
  width: 47%
}

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle
}

.datepicker table tr td.new,
.datepicker table tr td.old {
  color: #999
}

.datepicker table tr td,
.datepicker table tr th {
  width: 35px;
  height: 35px
}

.datepicker tfoot th,
.datepicker thead th {
  font-weight: 500
}

.datepicker .datepicker-switch {
  width: 145px
}

.datepicker .datepicker-switch,
.datepicker .next,
.datepicker .prev,
.datepicker tfoot tr th {
  cursor: pointer
}

.datepicker table tr td,
.datepicker table tr th {
  text-align: center;
  width: 35px;
  height: 32px;
  border-radius: 0;
  border: none
}

.datepicker table tr td.range-start,
.datepicker table tr td.range-end {
  background: #f75a5f;
  color: #fff
}

.datepicker table tr td.range {
  background: #ffb3b6
}

.datepicker table tr td {
  cursor: pointer
}

.datepicker thead th.next,
.datepicker thead th.prev {
  font-size: 0;
  font-family: themify;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased
}

.datepicker thead th.prev::before {
  content: "\e64a";
  font-size: 12px
}

.datepicker thead th.next::before {
  content: "\e649";
  font-size: 12px
}

.datepicker table tr td span.active,
.datepicker table tr td.today {
  background-color: #34495f !important;
  border-color: #34495f !important;
  color: #fff
}

.input-group-addon {
  padding: 0 10px;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  background-color: #e9ecef !important;
  border: 1px solid #e9ecef;
  border-radius: .25rem
}

.bootstrap-select.btn-group .dropdown-menu.inner {
  display: block
}

.ic_calendar img {
  width: 20px
}

.education_added {
  width: 100%
}

.education_added .timeline {
  margin: 0;
  padding: 0;
  padding-left: 40px
}

.education_added .timeline li {
  position: relative
}

.el_details {
  margin-bottom: 30px
}

.el_details p {
  display: flex;
  align-items: baseline;
  color: #373737
}

.el_details span {
  color: #373737
}

.timeline li:after span.timeline-point-circle.fill.active {
  background-color: #FE831D
}

.education_added .timeline li:after {
  left: -20px
}

.education_added .timeline li span.timeline-point-circle.fill {
  background-color: #FE831D;
  z-index: 1
}

.education_added .timeline li span.timeline-point-circle {
  height: 12px;
  width: 12px;
  background-color: #727272;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  left: -45px;
  top: 6px;
  display: inline-block
}

.addMoreBtn {
  border: 1px solid #FE831D;
  padding: 7px 10px;
  border: 1px solid #FE831D;
  border-radius: 12px;
  margin-left: 50px;
  color: #FE831D;
  font-size: 14px;
  font-weight: 600
}

.addMoreBtn .pluseIcon {
  display: inline-block;
  vertical-align: middle;
  width: 15px
}

.addMoreBtn .pluseIcon img {
  width: 15px
}

.lang .topHeader .skipBtn {
  right: 15%
}

span.ft-wt {
  font-weight: 600;
  color: #373737
}

.bottom .commonBtn {
  position: fixed;
  bottom: 5%
}

.el_details .blue-text {
  color: #4691F7;
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
  display: block
}

.text_count {
  text-align: right;
  display: block
}

.bs-datepicker-container {
  padding: 0 !important;
  padding-bottom: 10px !important
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1080
}

.datepicker .ic_calendar {
  bottom: 28px
}

.left-sidebar ul li {
  cursor: pointer
}

.upperLetter input {
  text-transform: capitalize
}

.datepicker input {
  position: relative;
  z-index: 2;
  background: transparent !important;
  background-color: transparent !important
}

.datepicker span {
  z-index: 1;
  cursor: pointer
}

.selectpasswordType li.active:after {
  content: '';
  width: 15px;
  height: 8px;
  background: url(assets/images/ic_correct-green.svg) no-repeat;
  position: absolute;
  left: 13px;
  top: 12px
}

.addModal h5 {
  letter-spacing: 0;
  color: #151515;
  font-weight: 600
}

.addModal .close {
  color: #AFAFAF
}

.addModal p {
  letter-spacing: 0;
  color: #373737;
  font-weight: 400
}

.addModal .modal-content {
  border-radius: 20px
}

.addModal .modal-body {
  padding: 30px
}

.addModal button.close {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 20px;
  height: 20px;
  font-size: 35px;
  line-height: 19px;
  overflow: hidden;
}

.datepicker input:disabled {
  background: #e9ecef !important
}

.education_added .timeline:after {
  background-color: #D7D7DC
}

.addedskills li {
  margin: 3px 15px 10px 0;
  cursor: pointer
}

a.addMoreBtn:hover {
  color: #FE831D
}

.timeline .timeline-item span.timeline-point-circle.fill {
  background: url(assets/images/filled.svg) no-repeat center
}

img {
  max-width: 100%
}

.maxWidth {
  max-width: 850px
}

.salaryDiv input {
  margin-right: 15px
}

.form-control.upper {
  text-transform: capitalize
}

input:disabled {
  cursor: default;
  opacity: .5
}

.subscription {
  margin: 0 auto;
  text-align: center
}

.pd-tb.subscription {
  margin-top: 40px;
}

.heading-5 {
  color: #151515;
  font-weight: 600;
  font-size: 20px
}

.heading-5 {
  position: relative
}

.heading-5 a {
  letter-spacing: 0;
  color: #EF7E2B;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  top: 5px;
  right: 0
}

.sub {
  border: .5px solid #C3C3C3;
  border-radius: 85px;
  padding: 6px;
  width: fit-content;
  margin: 30px auto
}

.sub li {
  padding: 10px;
  display: inline-block
}

.sub li.active {
  background: #EF7E2B 0 0 no-repeat padding-box;
  border-radius: 35px;
  color: #FFF
}

.plan {
  text-align: center;
  margin-top: 30px
}

.pill {
  background: #FFF7F1 0 0 no-repeat padding-box;
  border-radius: 32px;
  display: inline-block;
  padding: 5px;
  width: 160px
}

.pill span {
  letter-spacing: 0;
  color: #373737;
  font-weight: 600;
  font-size: 14px
}

.plan .card-grid-2 {
  background: #fff;
  padding: 40px 30px;
  background: #FFF 0 0 no-repeat padding-box;
  border: 1px solid #C3C3C3;
  border-radius: 20px;
  height: 450px
}

.plan h6.orangetext {
  color: #EF7E2B;
  font-weight: 600;
  font-size: 30px
}

.plan h6 span {
  color: #151515;
  font-weight: 500
}

.plan .pill {
  padding: 15px
}

.plan .pill span {
  color: #373737;
  font-weight: 600;
  font-size: 18px
}

.planlist li {
  color: #151515;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  margin-bottom: 10px;
  position: relative
}

.planlist li:last-child {
  margin-bottom: 0
}

.planlist {
  border-top: 1px solid #E6E6E6;
  padding: 30px;
  padding-bottom: 10px !important;
  margin-top: 20px
}

.planlist li span {
  font-weight: 700;
  color: #151515;
  font-size: 20px
}

.planbtn {
  border: 1px solid #EF7E2B;
  border-radius: 12px
}

.planbtn {
  font-weight: 600;
  color: #EF7E2B;
  font-size: 16px;
  padding: 10px 20px;
  display: inline-block;
  width: 200px
}

.planbtn.largeBtn {
  min-width: 200px;
  max-width: 250px;
  width: 100%
}

.checkmark {
  position: relative;
  padding-left: .8em
}

ul.checkmark li::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -13px;
  top: 10px;
  transform: rotate(45deg);
  height: .8em;
  width: .4em;
  color: #EF7E2B;
  border-bottom: .2em solid #EF7E2B;
  border-right: .2em solid #EF7E2B
}

.checkmark:is(li) {
  margin-left: 1em
}

.checkmark:is(li):not(:first-child) {
  margin-top: 1em
}

.plan .orangetext {
  margin-top: 10px;
  font-size: 20px
}

.planbtn.fill {
  background: #EF7E2B;
  color: #fff;
  text-align: center;
  letter-spacing: .2px
}

.buttonWrap {
  display: flex
}

.price {
  margin-left: 10px
}

.plan .card-grid-2.selected {
  border: 1px solid #EF7E2B
}

.planfooter {
  background: #151515 0 0 no-repeat padding-box;
  padding: 30px 20px
}

.selectedplan {
  color: #E8E8E8;
  font-weight: 400;
  font-size: 14px
}

.money {
  color: #FFF;
  font-weight: 400;
  font-size: 16px
}

.para6 {
  color: #151515;
  font-weight: 400;
  font-size: 14px;
  margin-top: 20px;
  width: 70%
}

.para6 img {
  vertical-align: text-bottom;
  height: 15px
}

.selectnumber p {
  font-size: 16px;
  color: #151515;
  margin: 10px 0
}

.planfooter {
  position: absolute;
  width: 100%;
  bottom: 0
}

.swal2-styled.swal2-cancel {
  background-color: #6e7881 !important
}

.success-stories .owl-theme .owl-nav {
  top: -100px;
  position: absolute;
  width: 150px;
  margin: 0;
  right: -10px
}

.success-stories .owl-prev,
.success-stories .owl-next,
.success-stories .owl-theme .owl-nav [class*=owl-] {
  width: 67px;
  height: 67px;
  border-radius: 100%;
  padding: 0;
  opacity: 0;
  margin: 0;
  z-index: 1;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle
}

.success-stories .owl-prev,
.success-stories .owl-theme .owl-nav [class*=owl-]:nth-child(1) {
  margin-right: 15px
}

.success-stories .owl-carousel .owl-stage {
  margin-left: -15px;
  margin-right: -15px
}

.success-stories .owl-carousel .owl-item {
  padding: 0 15px;
  overflow: hidden
}

.success-stories .owl-carousel .owl-item img {
  max-width: 100% !important;
  max-height: 223px;
  min-height: 223px;
  height: 100%
}

.success-stories .owl-theme .owl-dots .owl-dot {
  display: none
}

.success-stories {
  padding: 60px 0
}

.success-stories .spacer.por {
  position: relative
}

.success-stories .title {
  width: 100%;
  max-width: 70%
}

.test-arrowbox {
  top: 50px;
  position: absolute;
  right: 0;
  width: 150px
}

.test-arrowbox .button {
  display: inline-block;
  vertical-align: middle
}

.test-arrowbox .button.prev {
  margin-right: 15px
}

.client-content h6,
.client-content p {
  padding-left: 10px
}

.choice .greyBox {
  padding: 20px
}

.choice .greyBox img {
  padding: 0;
  max-height: 70px;
  width: auto;
  max-width: 100px
}

.hire-content .orangeBtn {
  width: 200px;
  display: inline-block;
  text-align: center
}

.paymentinfo h6 {
  color: #151515;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px
}

.paymentinfo .custom-checkbox {
  padding-bottom: 20px
}

.paymentinfo .basic {
  background: #F8F8F8 0 0 no-repeat padding-box;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative
}

.paymentinfo .basic h6 {
  color: #151515;
  font-weight: 500;
  font-size: 18px;
  position: relative
}

.paymentinfo .basic h6 span {
  position: absolute;
  right: 0;
  color: #151515;
  font-weight: 600;
  font-size: 16px
}

.paymentinfo .basic p {
  width: 60%;
  color: #151515
}

.basic .remove {
  color: #EF7E2B;
  text-decoration: underline;
  text-decoration: underline;
  position: absolute;
  right: 20px;
  bottom: 10px
}

.para7 {
  color: #151515;
  font-size: 16px;
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center
}

.para7 span {
  display: flex;
  align-items: center
}

.para7 span img {
  vertical-align: middle;
  margin-right: 4px;
  height: 16px
}

.form-group .choose {
  position: absolute;
  position: absolute;
  right: 0;
  bottom: 5px
}

.form-group .choose .planbtn {
  width: 100px;
  text-align: center
}

.plan .card-grid-2 .choose .planbtn:first-child {
  display: inline-block
}

.plan .card-grid-2 .choose .planbtn:nth-child(2) {
  display: none
}

.plan .card-grid-2.selected .choose .planbtn:first-child {
  display: none
}

.plan .card-grid-2.selected .choose .planbtn:nth-child(2) {
  display: inline-block
}

.text-center .pageTitle {
  display: block
}

.addModal .list a {
  margin: 0 10px
}

.addModal p.ft-wt {
  font-weight: 600;
  font-size: 20px;
  margin: 0 auto
}

.completeProfileloged {
  background: #FFF 0 0 no-repeat padding-box;
  border-radius: 20px;
  padding: 20px 30px;
  margin-top: 100px
}

.bkgrey {
  background: #FAFAFA;
  padding: 30px 0
}

.heading-6 {
  color: #151515;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: capitalize
}

.companydetail h5 {
  color: #151515;
  font-size: 14px;
  font-weight: 600
}

.companydetail .content h5 {
  word-wrap: break-word;
  word-break: break-word
}

.companydetail p {
  color: #373737;
  font-size: 12px;
  font-weight: 500
}

.companydetail {
  display: flex;
  align-items: center
}

.companydetail img {
  height: 24px;
  margin-right: 10px
}

.completeProfilecontent {
  background: #F8F8F8 0 0 no-repeat padding-box;
  border: 1px solid #D7D7DC;
  border-radius: 20px;
  padding: 20px
}

.pdtb {
  padding: 40px 0
}

.remove {
  color: #EF7E2B;
  text-decoration: underline
}

.btns a {
  margin-right: 10px
}

.gLog {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  opacity: 0
}

div#google-button {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  opacity: 0
}

.completeProfile {
  padding: 100px 0;
}

.jobseeker .completeProfile,
.completeProfileloged .completeProfile {
  padding: 0 0 100px;
}

.CroperImages .modal-header {
  padding-bottom: 0;
}

.CroperImages .modal-body {
  padding: 0;
}

.modal-body image-cropper {
  width: 100%;
  height: 400px;
  margin: auto;
}

.countryCode.job {
  position: relative
}

.countryCode {
  position: relative
}

.countryCode span {
  top: 0;
  position: absolute;
  width: 40px;
  left: 0;
  font-size: 1rem;
  line-height: 33px;
  height: 33px;
  color: #151515;
  text-align: center;
  border-bottom: 1px solid #ced4da;
  font-weight: 500;
  padding: 0;
  text-align: center
}

.countryCode input {
  padding-left: 40px
}

.countryCode.job span {
  width: 80px
}

.countryCode .greeTick {
  position: absolute;
  left: 140px;
  top: 8px
}

.countryCode .otpButton {
  position: absolute;
  right: 0;
  top: -8px;
  color: #EF7E2B;
  border: 1px solid #EF7E2B;
  border-radius: 8px;
  width: 100px;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 500;
  text-align: center
}

.countryCode.job span img {
  width: 27px;
  position: absolute;
  left: 0
}

.countryCode span input {
  padding: 0;
  text-align: center
}

.countryCode input {
  padding-left: 40px
}

.countryCode.job input.pl {
  padding-left: 90px
}

.countryCode.job span input {
  padding-left: 30px
}

.modal.fade.open {
  display: block;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  opacity: 1
}

.modal-content.thankyou {
  text-align: center;
  border-radius: 20px
}

.thankyouScreen {
  position: fixed;
  background: var(--bs-modal-bg) !important;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background: #fff !important;
  border-radius: 0px;
}

.thankyouScreen .modal-content.thankyou {
  background: #fff;
  border-radius: 0px;
  height: 100%;
}

.thankyouScreen .modal-content.thankyou .content {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.padding {
  padding: 20px 0 30px
}

input:disabled {
  cursor: default;
  opacity: .5
}

.loginPage {
  margin-top: 50px
}

.capitalize {
  text-transform: capitalize
}

.upperLetter input {
  text-transform: capitalize
}

.innerWrapper {
  background-color: #FAFAFA
}

.ngx-pagination .pagination-previous a:before,
.ngx-pagination .pagination-previous.disabled:before,
.ngx-pagination .pagination-next a:after,
.ngx-pagination .pagination-next.disabled:after {
  display: none !important
}

.ngx-pagination li.pagination-previous,
.ngx-pagination li.pagination-next {
  border: 1px solid #DAD8D8;
  border-radius: 6px;
  padding: 10px 5px;
  width: 90px;
  text-align: center;
  vertical-align: top;
  height: 40px;
  line-height: normal
}

.ngx-pagination li.pagination-previous a,
.ngx-pagination li.pagination-next a {
  border: none;
  border-radius: 0;
  padding: 0;
  width: auto;
  height: auto
}

.ngx-pagination li {
  margin-right: 5px !important;
  padding: 0
}

.ngx-pagination li a {
  border: 1px solid #C7C8C3;
  border-radius: 6px;
  padding: 10px 5px;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  line-height: normal
}

.ngx-pagination .current {
  background: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  width: auto;
  height: auto;
  text-align: center;
  margin: 0 auto;
  margin-right: 5px !important
}

.ngx-pagination .current span span {
  background: #EF7E2B !important;
  border: 1px solid #EF7E2B !important;
  border-radius: 6px;
  padding: 10px 5px;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  font-size: 16px;
  line-height: normal
}

.table-wrapper td a.jobid {
  text-decoration: underline;
  color: #EF7E2B
}

.table-wrapper td a.jobid img,
.table-wrapper td a.jobid span {
  display: inline-block;
  vertical-align: middle
}

.table-wrapper td a.jobid span {
  margin-right: 10px
}

.green {
  color: #5FC76A !important
}

.red {
  color: #EB4D4D !important
}

.noDataFound {
  text-align: center;
  margin: 10px 0
}

.noDataFound h2 {
  color: #151515;
  font-size: 16px;
  font-weight: 500;
  padding: 50px 0;
}

.content-wrapper .progress {
  box-shadow: none;
  border-radius: 2px;
  height: auto;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto
}

.table-wrapper td a span {
  display: inline-block;
  vertical-align: middle
}

.table-wrapper td a.linkedIn {
  position: relative;
  padding-right: 20px
}

.table-wrapper td a i.icons {
  position: absolute;
  top: 7px;
  right: 5px
}

.table-wrapper td a i.icons img {
  stroke: #000
}

.details-page .breadcrumb-item+.breadcrumb-item::before {
  content: "//" !important;
  color: #727272
}

.details-page .page-heading .breadcrumb li {
  color: #EF7E2B
}

.details-page .page-heading {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px
}

.details-page .page-heading .breadcrumb li a {
  display: inline-block;
  vertical-align: middle;
  color: #727272
}

.permissionsBox h3 {
  color: #151515;
  font-weight: 600;
  font-size: 16px
}

.permissionsBox ul li span {
  display: block;
  font-size: 12px
}

.permissionsBox ul li strong {
  display: block;
  font-size: 14px
}

.details-page .ibox-title span {
  font-weight: 600;
  margin-right: 3px;
  font-size: 16px
}

.add-permissions {
  width: 100%;
  margin-top: 10px
}

.checkboxWrap input[type="checkbox"] {
  display: none
}

.permissions-popup .checkboxWrap input[type="checkbox"]+label,
.checkboxWrap input[type="checkbox"]+label,
.radioWrap [type="radio"]:checked+label,
.radioWrap [type="radio"]:not(:checked)+label {
  display: block;
  margin: 0 10px 10px 0;
  cursor: pointer;
  padding-left: 30px;
  position: relative;
  line-height: 1.3;
  font-size: 18px
}

.checkboxWrap input[type="checkbox"]+label:before,
.permissions-popup .checkboxWrap input[type="checkbox"]+label:before,
.radioWrap [type="radio"]:checked+label:before,
.radioWrap [type="radio"]:not(:checked)+label:before {
  content: "\2714";
  border: 1px solid #C3C3C3;
  margin-right: 7px;
  border-radius: 4px;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding-left: .2em;
  padding-bottom: .3em;
  vertical-align: bottom;
  color: transparent;
  transition: .2s;
  position: absolute;
  left: 0
}

.checkboxWrap input[type="checkbox"]+label:active:before,
.permissions-popup .checkboxWrap input[type="checkbox"]+label:active:before,
.radioWrap [type="radio"]:checked+label:active:before {
  transform: scale(0)
}

.checkboxWrap input[type="checkbox"]:checked+label:before,
.radioWrap [type="radio"]:checked+label:before {
  background-color: #ed820a;
  border-color: #ed820a;
  color: #fff
}

.checkboxWrap input[type="checkbox"]:disabled+label:before,
.radioWrap [type="radio"]:disabled+label:before {
  transform: scale(1);
  border-color: #aaa
}

.checkboxWrap input[type="checkbox"]:checked:disabled+label:before,
.radioWrap [type="radio"]:checked:disabled+label:before {
  transform: scale(1);
  background-color: #f7c28f;
  border-color: #f7c28f
}

.jobswrap h6.sub_title {
  font-size: 14px;
  margin-top: 10px
}

.details-page input.form-control {
  height: 40px;
  font-weight: 600;
  color: rgba(21, 21, 21, 1) !important
}

.details-page input.form-control::placeholder {
  color: rgba(21, 21, 21, 0.4) !important;
  font-weight: 400
}

.btnwrap {
  margin: 10px 0
}

.btnwrap .common-btn {
  background: #EF7E2B;
  color: #FFF;
  border-radius: 6px;
  padding: 10px;
  font-weight: 500;
  width: 120px
}

.btnwrap .common-btn.unfill {
  border: 1px solid #727272;
  background: transparent;
  border-radius: 8px;
  color: #727272
}

.text-orange {
  text-decoration: underline !important;
  color: #EF7E2B !important
}

.details-page .listview .list dt:first-child {
  padding-left: 0;
  width: 30%;
  font-weight: 400;
  color: #373737;
  font-size: 16px
}

.details-page .listview .list dt {
  font-weight: 500;
  width: 70%;
  color: #151515;
  font-size: 16px;
  flex-grow: 0;
  flex-shrink: 0;
}

.details-page .page-heading {
  margin-bottom: 10px
}

.details-page .repeatedrow h4 {
  color: #151515;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 20px
}

.details-page .text-orange {
  text-decoration: underline;
  color: #EF7E2B
}

.details-page .listview .list dt a {
  padding: 0;
  text-decoration: none !important
}

.details-page .permissionsBox {
  max-width: 100%;
  margin: 30px 0
}

.details-page .permissionsBox h3 {
  color: #151515;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 10px
}

.details-page .permissionsBox ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: stretch
}

.details-page .permissionsBox ul li {
  padding: 0;
  flex-grow: 1
}

.footer-top .logo {
  margin-bottom: 30px
}

.footer-top .logo img {
  width: 100%;
  max-width: 193px
}

.footer-top .social-links.mt-3 a {
  margin-right: 10px
}

.footer-top .social-links.mt-3 a:last-child {
  margin-right: 0
}

span.closeSearch {
  float: right;
  top: -3px;
  margin-top: -30px;
  margin-right: 10px;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer
}

.input-group-icon .input-icon {
  top: 8px
}

.input-count {
  float: right;
  font-size: 14px;
  font-weight: 600
}

.header .dropdown-user a.dropdown-toggle {
  padding-right: 25px;
  position: relative
}

.header .dropdown-user a.dropdown-toggle .ic_down_arrow {
  position: absolute;
  top: 14px;
  right: 5px;
  width: 15px !important
}

.permissions-popup {
  border-radius: 20px
}

.permissions-popup .add-permissions {
  width: 100%;
  margin-top: 10px
}

.permissions-popup .modal-title {
  font-weight: 600;
  padding: 0
}

.permissions-popup .checkboxWrap input[type="checkbox"] {
  display: none
}

.permissions-popup .checkboxWrap input[type="checkbox"]:checked+label:before {
  background-color: #ed820a;
  border-color: #ed820a;
  color: #fff
}

.permissions-popup .checkboxWrap input[type="checkbox"]:disabled+label:before {
  transform: scale(1);
  border-color: #aaa
}

.permissions-popup .checkboxWrap input[type="checkbox"]:checked:disabled+label:before {
  transform: scale(1);
  background-color: #f7c28f;
  border-color: #f7c28f
}

.permissions-popup .jobswrap {
  max-width: 100%;
  display: inline-block;
  margin-top: 15px
}

.permissions-popup .jobswrap h6.sub_title {
  font-size: 14px;
  margin-top: 10px
}

.permissions-popup .details-page input.form-control {
  height: 40px;
  font-weight: 600
}

.permissions-popup .details-page input.form-control::placeholder {
  color: rgba(21, 21, 21, 0.4) !important;
  font-weight: 400
}

.permissions-popup .btnwrap {
  margin: 20px 0
}

.permissions-popup .btnwrap .common-btn {
  background: #EF7E2B;
  color: #FFF;
  border-radius: 6px;
  padding: 10px;
  font-weight: 500;
  width: 120px
}

.permissions-popup .btnwrap .common-btn.unfill {
  border: 1px solid #727272;
  background: transparent;
  border-radius: 8px;
  color: #727272
}

.edit-permissions-popup {
  border-radius: 20px
}

.edit-permissions-popup .btnwrap {
  margin: 20px 0
}

.edit-permissions-popup .btnwrap .common-btn {
  background: #EF7E2B;
  color: #FFF;
  border-radius: 6px;
  padding: 10px;
  font-weight: 500;
  width: 120px
}

.edit-permissions-popup .btnwrap .common-btn.unfill {
  border: 1px solid #727272;
  background: transparent;
  border-radius: 8px;
  color: #727272
}

.details-page input.form-control::placeholder {
  color: rgba(21, 21, 21, 0.4) !important;
  font-weight: 600
}

.slash_breadcrumb {
  display: block;
  margin-bottom: 10px
}

.slash_breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  content: "//" !important;
  color: #727272
}

.slash_breadcrumb .breadcrumb li a {
  display: inline-block;
  vertical-align: middle;
  color: #727272
}

.slash_breadcrumb .breadcrumb li {
  color: #EF7E2B;
  font-size: 14px
}

.details-page .ibox-title span {
  display: block;
  font-weight: 600;
  margin-bottom: 10px
}

.permissionsBox {
  max-width: 600px;
  margin: 30px 0
}

.permissionsBox h3 {
  color: #151515;
  font-weight: 600;
  font-size: 16px
}

.permissionsBox ul li span {
  display: block;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 5px
}

.permissionsBox ul li strong {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #151515
}

.details-page .ibox-title span {
  font-weight: 600;
  margin-right: 3px;
  font-size: 16px;
  color: #151515
}

.add-permissions {
  width: 100%;
  margin-top: 10px
}

.checkboxWrap input[type="checkbox"] {
  display: none
}

.checkboxWrap input[type="checkbox"]:checked+label:before {
  background-color: #ed820a;
  border-color: #ed820a;
  color: #fff
}

.checkboxWrap input[type="checkbox"]:disabled+label:before {
  transform: scale(1);
  border-color: #aaa
}

.checkboxWrap input[type="checkbox"]:checked:disabled+label:before {
  transform: scale(1);
  background-color: #f7c28f;
  border-color: #f7c28f
}

.jobswrap {
  max-width: 49%;
  display: inline-block
}

.jobswrap h6.sub_title {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #151515
}

.jobswrap label {
  color: #151515
}

.details-page input.form-control {
  height: 40px;
  font-weight: 600
}

.details-page .form-control::placeholder {
  color: rgba(21, 21, 21, 0.4) !important;
  font-weight: 400
}

.details-page textarea.form-control {
  min-height: 40px;
  height: 40px;
  padding: .5rem 0 !important
}

.btnwrap {
  margin: 10px 0
}

.btnwrap .common-btn {
  background: #EF7E2B;
  color: #FFF;
  border-radius: 6px;
  padding: 10px;
  font-weight: 500;
  width: 120px
}

.btnwrap .common-btn.unfill {
  border: 1px solid #727272;
  background: transparent;
  border-radius: 8px;
  color: #727272
}

.table-wrapper td a.remove-user {
  border: 1px solid #EF7E2B;
  border-radius: 8px;
  margin-left: 10px;
  color: #EF7E2B;
  text-decoration: none;
  display: inline-block;
  padding: 4px 10px
}

.table-wrapper td a i.icons {
  margin-left: 20px
}

.edit-permissions-popup .modal-title {
  font-weight: 600;
  padding: 0
}

.edit-permissions-popup p {
  margin-bottom: 20px
}

.edit-permissions-popup textarea.form-control {
  padding: .5rem 0 !important;
  min-height: 80px
}

.edit-permissions-popup .form-group {
  position: relative
}

.edit-permissions-popup .form-group .text_count {
  position: absolute;
  right: 0;
  bottom: -25px;
  font-weight: 600;
  font-size: 14px
}

.edit-permissions-popup .form-control::placeholder {
  color: rgba(21, 21, 21, 0.4) !important;
  font-weight: 400
}

.table-scroll .table-bordered td.show {
  position: relative !important
}

.table-scroll .table-bordered td.show .dropdown-menu.show {
  position: absolute !important;
  top: 0 !important;
  left: 10px !important
}

.input-group-icon .form-control {
  width: 265px;
  padding-right: 22px
}

.content-wrapper.ml-0 {
  margin-left: 0;
  padding-left: 0;
  padding-right: 0
}

.form-group .amount {
  position: relative;
  font-size: 14px
}

.form-group .amount input {
  padding-left: 20px
}

.form-group .amount span {
  position: absolute;
  left: 8px;
  font-size: 15px;
  top: 6px
}

.adressbox {
  position: relative
}

.adressbox .close {
  position: absolute;
  right: 0;
  bottom: 5px
}

.adressbox .choose {
  position: absolute;
  display: none
}

.adressbox .box:last-child .choose {
  position: absolute;
  display: block
}

.left-sidebar ul.timeline {
  padding-left: 0
}

.bg-white {
  background-color: #fff;
  padding: 0
}

.friend-list {
  list-style: none
}

.friend-list li {
  border-bottom: 1px solid #EFEFEF
}

.friend-list li a img {
  float: left;
  height: 45px;
  margin-right: 10px;
  width: 45px;
  border-radius: 50%
}

.friend-list li a {
  position: relative;
  display: block;
  padding: 15px 20px;
  transition: all .2s ease;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease
}

.friend-list li.active a {
  background-color: #FDF8F5;
  border-left: 4px solid #EF7E2B
}

.friend-list li a .friend-name,
.friend-list li a .friend-name:hover {
  color: #151515;
  font-weight: 500
}

.friend-list li a .last-message {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 210px;
}

.friend-list li a .time {
  position: absolute;
  top: 15px;
  right: 8px
}

small,
.small {
  font-size: 85%
}

.friend-list li a .chat-alert, .unreadMsgCount {
  position: absolute;
  right: 8px;
  bottom: 20px;
  font-size: 10px;
  padding: 3px 4px 4px 4px;
  background: #EF7E2B;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: #fff;
  margin: 0 auto;
  text-align: center
}

.chat-message {
  padding: 60px 20px 115px
}

.chat {
  list-style: none;
  margin: 0
}

.chat-message {
  background: #f9f9f9
}

.chat li img {
  width: 45px;
  height: 45px;
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em
}

img {
  max-width: 100%
}

.chat li.left .chat-body {
  margin-left: 70px;
  background-color: #fff
}

.chat li .chat-body {
  position: relative;
  font-size: 11px;
  padding: 10px;
  border: 1px solid #f1f5fc;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05)
}

.chat li .chat-body .header {
  padding-bottom: 5px;
  border-bottom: 1px solid #f1f5fc
}

.chat li .chat-body p {
  margin: 0
}

.chat li.left .chat-body:before {
  position: absolute;
  top: 10px;
  left: -8px;
  display: inline-block;
  background: #fff;
  width: 16px;
  height: 16px;
  border-top: 1px solid #f1f5fc;
  border-left: 1px solid #f1f5fc;
  content: '';
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg)
}

.chat li.right .chat-body:before {
  position: absolute;
  top: 10px;
  right: -8px;
  display: inline-block;
  background: #fff;
  width: 16px;
  height: 16px;
  border-top: 1px solid #f1f5fc;
  border-right: 1px solid #f1f5fc;
  content: '';
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg)
}

.chat li {
  margin: 15px 0
}

.chat li.right .chat-body {
  margin-right: 70px;
  background-color: #fff
}

.chat-box {
  padding: 15px;
  border-top: 1px solid #eee;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease
}

.primary-font {
  color: #3c8dbc
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: 0
}

.people-list .chat-list img {
  width: 45px;
  border-radius: 50%
}

.padding-sm {
  padding: 40px 15px;
  margin: 0
}

.highlite {
  color: #151515;
  font-weight: 500;
  letter-spacing: 0
}

.pd-tb-3 {
  padding-top: 30px;
  padding-bottom: 30px
}

.padding-sm {
  position: relative
}

.action-header {
  position: relative;
  padding: 15px 13px 15px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EFEFEF
}

.open.innelist1.dropdown .dropdown-toggle {
  position: relative
}

.chattop img {
  margin-right: 10px;
  height: 48px
}

.chattop {
  display: flex
}

.bgwhite {
  background: #fff
}

.outgoing_msg {
  overflow: hidden;
  margin: 26px 0
}

.sent_msg_image,
.sent_msg_pdf {
  float: right;
  width: 46%;
  background: transparent 0 0 no-repeat padding-box;
  border-radius: 12px 12px 0 12px;
  padding: 5px 10px 5px 12px;
  text-align: right
}

.sent_msg_text {
  float: right;
  width: 46%;
  background: #FCEBDC 0 0 no-repeat padding-box;
  border-radius: 12px 12px 0 12px;
  padding: 5px 10px 5px 12px;
  text-align: right
}

.sent_msg_text p {
  font-size: 14px;
  margin: 0;
  color: #151515;
  width: 100%
}

.incoming_msg_img {
  display: inline-block;
  width: 6%
}

.received_msg .received_msg_text {
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 46%;
  background: #ebebeb none repeat scroll 0 0;
  margin: 0;
  padding: 5px 10px 5px 12px;
  background: #F7F7F7 0 0 no-repeat padding-box;
  border-radius: 0 12px 12px 12px;
  margin: 10px 0
}

.received_msg .received_msg_image,
.received_msg .received_msg_pdf {
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 46%;
  background: transparent none repeat scroll 0 0;
  margin: 0;
  padding: 5px 10px 5px 12px;
  border-radius: 0 12px 12px 12px;
  margin: 10px 0
}

.modal-content {
  border-radius: 20px
}

.received_msg_text p {
  color: #151515;
  font-size: 14px;
  width: 100%
}

.mesgs {
  padding: 30px 15px 0 25px
}

.time_date {
  color: #373737;
  font-size: 10px
}

.msg_history {
  height: 408px;
  overflow-y: auto
}

.type_msg {
  background: #F6F6F6 0 0 no-repeat padding-box;
  border-radius: 28px;
  width: 100%
}

.type_msg textarea.form-control,
.type_msg input.form-control {
  border: none;
  background: #F6F6F6 0 0 no-repeat padding-box;
  border-radius: 28px;
  height: 55px;
  line-height: 1.4;
  padding: 18px 43px !important;
  resize: none;
  overflow: hidden
}

.type_msg textarea.form-control[readonly],
.type_msg input.form-control[readonly] {
  background: #F6F6F6 0 0 no-repeat padding-box !important;
  height: 0
}

.input_msg_write {
  position: relative
}

.input_msg_write button.msg_send_btn {
  position: absolute;
  bottom: 8px;
  right: 8px;
  margin: auto
}

.input_msg_write input {
  background: #F6F6F6 0 0 no-repeat padding-box;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 55px;
  border-radius: 28px;
  padding: 0 15px
}

.msg_send_btn {
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  position: absolute;
  right: 0;
  width: 40px
}

.msgbottom a img {
  margin-right: 10px
}

.basicdetails .para6 span {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-right: 8px
}

.basicdetails .para6 span::after {
  position: absolute;
  right: -10px
}

.msgbottom {
  display: flex;
  align-items: center;
  justify-content: baseline;
  padding-bottom: 15px;
  position: relative
}

.msgbottom .upload-pic {
  position: absolute;
  opacity: 0;
  max-width: 20px
}

.msgbottom a img {
  margin-right: 10px
}

.day {
  display: flex;
  justify-content: center;
  font-size: 12px;
  letter-spacing: 0;
  color: #000;
  font-weight: 400
}

.basicdetails .para6 span {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-right: 8px
}

.basicdetails .para6 span::after {
  position: absolute;
  content: ",";
  right: 3px;
  font-size: 13px
}

.basicdetails .para6 span:last-child:after {
  display: none
}

.table-wrapper td i.icons {
  position: relative !important;
  display: inline-block;
  vertical-align: middle;
  top: 0 !important;
  right: 0 !important;
  margin-left: 0 !important;
  cursor: pointer
}

td.td-linked {
  position: relative;
  margin-right: 20px !important
}

.lastupdated p {
  font-weight: 500
}

.profiledescription p {
  font-weight: 600;
  margin-bottom: 10px
}

.profiledescription h4 {
  color: #151515;
  font-weight: 600
}

.profiledescription p.para {
  font-weight: 400;
  color: #373737;
  font-size: 14px;
  margin-top: 10px
}

.profiledescription .allowBtn {
  border: 1px solid #FE831D;
  border-radius: 12px;
  color: #EF7E2B;
  font-weight: 600;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 25px
}

.card-grid-2.active {
  border: 2px solid #EF7E2B
}

.jobDesp .card-grid-2:hover {
  background-color: transparent
}

.jobDetails {
  padding: 20px 0;
  padding-bottom: 40px
}

.heading-6.questiontext {
  text-transform: none !important
}

.form-control::placeholder {
  color: #151515;
  font-weight: 400;
  color: rgba(21, 21, 21, 0.4)
}

.form-control:-ms-input-placeholder {
  color: #151515;
  font-weight: 400;
  color: rgba(21, 21, 21, 0.4)
}

.form-control::-ms-input-placeholder {
  color: #151515;
  font-weight: 400;
  color: rgba(21, 21, 21, 0.4)
}

.para11.questiontext {
  text-transform: none !important
}

.jobsdiv {
  display: flex
}

.jobs .card-grid-2 .card-grid-2-image-left {
  justify-content: space-between
}

.jobs .card-grid-2 .card-block-info {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 30px
}

.jobs .apply img {
  margin-right: 15px
}

.subheading span {
  color: #151515;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px
}

.innerpage-heading {
  letter-spacing: 0;
  color: #151515;
  font-size: 24px;
  font-weight: 600
}

.job-list.nav-tabs>li>a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0
}

.job-list.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 30px
}

.job-list.nav>li>a {
  padding-left: 0;
  margin-right: 30px;
  padding-right: 0
}

.job-list>li.active>a,
.job-list>li.active>a:hover,
.job-list>li.active>a:focus {
  color: #EF7E2B;
  cursor: pointer;
  font-weight: 500;
  border-bottom: 1px solid #EF7E2B
}

.jobs img.wt {
  height: 28px;
  margin-left: 15px
}

.jobs .applybtn {
  padding: 6px 10px;
  text-align: center;
  width: 120px;
  display: inline-block
}

.jobs a .applybtn {
  color: #FE831D
}

.jobs .right-info {
  position: relative
}

.jobs .right-info .featured {
  position: inherit
}

.greybk {
  background: #F8F8F8
}

.whitebox {
  background: #FFF 0 0 no-repeat padding-box;
  border-radius: 10px;
  padding: 30px 20px
}

.minheading h6 {
  color: #373737;
  font-weight: 400;
  font-size: 18px
}

.data {
  color: #151515;
  font-size: 24px;
  font-weight: 600
}

.whitebox img.wt {
  height: 50px;
  width: 50px;
  margin-right: 15px
}

.whitebox.flexcss {
  align-items: flex-start
}

.pd-tb-2 {
  padding: 40px 0
}

.greybk.pd-tb-2 {
  padding: 40px 0 30px
}

.greyBox {
  position: relative
}

.greyBox .featured,
.card-grid-2 .featured {
  position: absolute;
  top: 36px;
  padding: 2px 10px;
  color: #fff;
  font-size: 8px;
  background: #EF7E2B 0 0 no-repeat padding-box;
  border-radius: 5px
}

.greyBox .featured {
  right: 70%;
  top: 12px;
  font-size: 7px
}

.infinite-scroll {
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden
}

.addMoreBtn.pd {
  padding: 10px 20px
}

.temptext {
  color: #151515;
  font-weight: 500;
  font-size: 14px
}

.tablist .nav-link {
  padding: .5rem 1rem
}

.tablist .nav-link {
  color: #151515;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400
}

.textmargin.basicdetails .heading-6 {
  margin-top: 0
}

.borderrt {
  border-right: 1px solid #EFEFEF
}

.breadcrumb {
  background: transparent;
  padding: 0
}

.breadcrumb-item.active {
  color: #FE831D
}

.cards-inner {
  padding: 0 20px 30px
}

.jobDetails .card-grid-2 {
  border: none;
  background-color: none !important
}

.descrpinner span {
  font-weight: 400;
  letter-spacing: 0;
  font-size: 14px;
  color: #373737
}

.descrpinner img {
  height: 16px;
  vertical-align: middle;
  margin-right: 5px
}

.descrpinner {
  padding: 5px 0;
  display: flex;
  align-items: center
}

.jobDetails .card-grid-2 .card-grid-2-image-left {
  padding-left: 0
}

.cards-inner2 {
  color: #373737;
  font-size: 14px;
  padding: 20px 0
}

.jobdeslist li {
  align-items: baseline;
  display: flex;
  color: #151515;
  font-weight: 400;
  font-size: 14px;
}

.jobdeslist li img {
  margin-right: 5px
}

.innerdes .addedskills li {
  background: #F8F8F8 0 0 no-repeat padding-box;
  border: 1px solid #F8F8F8;
  border-radius: 40px;
  padding: 10px;
  margin: 3px 3px 3px 0;
  list-style: none;
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  color: #000;
  font-weight: 500
}

.applybtn.borderBtn.fill {
  background: #FE831D;
  color: #fff;
  margin-left: 15px
}

.jobDesp .card-grid-2-image-left {
  padding-top: 0
}

.addModal .modal-content {
  border-radius: 20px
}

.para12 {
  color: #151515;
  font-weight: 500;
  font-size: 15px;
  margin-top: 5px
}

.card-grid-2 .arrow {
  position: absolute;
  right: 20px;
  top: 45%
}

.companydetailpage.card-grid-2 {
  border-radius: 0;
  border: none;
  overflow: hidden;
  margin-bottom: 0;
  position: relative;
  background: transparent
}

.completeProfile .para6 {
  width: 100%
}

.companydetailpage.card-grid-2 .card-grid-2-image-left .right-info .location-small {
  width: 70%
}

.applybtn.borderBtn.fill {
  background: #FE831D;
  color: #fff;
  margin-left: 15px
}

.jobDesp .card-grid-2-image-left {
  padding-top: 0
}

.addModal .modal-content {
  border-radius: 20px
}

.heading-16 {
  color: #373737;
  font-size: 13px;
  font-weight: 400
}

.para-16 {
  color: #151515;
  font-weight: 500;
  font-size: 15px
}

.whiteBox .pageTitle span.subheading-2,
.subheading-2 {
  font-weight: 600;
  font-size: 18px
}

.editProfile .basicdetails {
  padding: 20px 0
}

.editProfile .addedskills li:first-child {
  margin-left: 0
}

.editProfile .addedskills li:last-child {
  margin-right: 0
}

.editProfile .el_details .blue-text {
  font-size: 14px
}

.pointer {
  cursor: pointer
}

.center {
  text-align: center
}

.loginPage.marginTop {
  margin-top: 100px
}

.progressbar {
  position: relative
}

.progressbartext {
  position: absolute;
  position: absolute;
  top: 40%;
  left: 35%;
  right: 35%;
  text-align: center
}

.progressbartext h6 {
  color: #151515;
  font-weight: 600;
  font-size: 30px
}

.progressbartext p {
  color: #4fb860;
  font-size: 16px;
  font-weight: 500
}

span.timeline-point-circle.fill:before {
  content: "";
  position: absolute;
  width: 1.5px;
  height: 100%;
  background: #EF7E2B;
  top: -40px;
  min-height: 40px;
  left: 7px
}

.timeline .timeline-item p {
  font-size: 14px;
  color: #151515
}

li:first-child .timeline-point-circle.fill::before {
  display: none
}

.education_added .timeline {
  padding-left: 0
}

.education_added .timeline li {
  padding-left: 25px;
  overflow: hidden
}

.education_added .timeline li:last-child:after {
  display: none
}

.education_added .timeline li span.timeline-point-circle {
  left: 0
}

.education_added .timeline li:after {
  left: 5px;
  z-index: 1;
  background-color: #d7d7dc;
  top: 22px;
  height: 100vh
}

.education_added .timeline li .el_details ul li {
  padding-left: 0
}

.education_added .timeline li:first-child .timeline-point-circle.fill:after {
  display: none
}

.education_added span.timeline-point-circle.fill:before {
  display: none
}

li:first-child .education_added .timeline span.timeline-point-circle.fill:after {
  display: none
}

.whiteBox .pageTitle span.time {
  font-size: 10px
}

.innelist1 .dropdown-menu.show {
  display: block;
  right: -22px !important;
  top: 14px !important
}

.innelist1 .dropdown-menu::before {
  right: 22px
}

.lb-dataContainer {
  position: fixed;
  top: 0;
  right: 9px
}

.lightbox {
  position: fixed;
  top: 0 !important;
  overflow: hidden;
  height: 100%
}

.lb-outerContainer {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%)
}

.education_added .timeline ul.medias li::after,
.education_added .timeline ul.medias li::before {
  display: none
}

.chat_heading {
  padding: 25px
}

.chat_heading h6 {
  font-size: 24px;
  color: #151515;
  font-weight: 700
}

.chat_heading h6 a img {
  vertical-align: middle
}

.chat_heading h6 a {
  margin-right: 8px
}

.chat_heading h6 a,
.chat_heading h6 span {
  display: inline-block;
  vertical-align: middle;
  font-weight: 700
}

.chatSection {
  margin: 0 10px 30px 10px;
  border-radius: 20px
}

.chatSection .chat-body {
  position: relative;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 20px
}

.chat_heading .infinite-scroll {
  overflow: inherit
}

.searchSec {
  padding: 0 15px 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center
}

.searchSec .input-group-icon .input-icon {
  top: 17px;
  left: 7px
}

.searchSec .input-group-icon .form-control {
  height: 55px;
  border-radius: 100px !important;
  width: 100% !important;
  max-width: 400px;
  min-width: 300px;
  padding-left: 40px;
  padding-right: 15px !important
}

.chatSection .chat-body .userList li {
  padding: 22px 30px 30px;
  border-bottom: 2px solid #EFEFEF;
  text-transform: capitalize;
}

.chatSection .chat-body .userList li:last-child {
  border-bottom: none
}

.chatSection .chat-body .userList li span {
  font-size: 18px;
  line-height: 1.4;
  color: #151515;
  font-weight: 600
}

.chatSection .chat-body .userList li .jobinnerid {
  align-items: baseline
}

.chatSection .chat-body .friend-list li .time {
  border: none !important;
  background: none !important
}

.chatSection .chat-body .mesgs {
  padding: 0;
  height: calc(100% - 80px);
  padding-bottom: 100px
}

.chatSection .chat-body .mesgs .msg_history {
  padding: 30px;
  min-height: 1px;
  max-height: auto
}

.chatSection .chat-body .mesgs .msgbottom {
  padding: 22px 30px 22px 26px;
  box-shadow: 0 -3px 18px #0000000A
}

.chatSection .chat-body .friend-name img {
  display: inline-block;
  vertical-align: middle
}

.chatSection .chat-body .friend-name strong {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  font-weight: 600;
  color: #151515
}

.scrollbar {
  height: 100%;
  max-height: 500px;
  min-height: 500px;
  width: 100%;
  overflow-y: auto
}

.scrollbar2 {
  height: 100%;
  max-height: 450px;
  min-height: 450px;
  width: 100%;
  overflow-y: scroll
}

.scrollbar3 {
  height: 100%;
  max-height: 100vh;
  min-height: 500px;
  width: 100%;
  overflow-y: scroll
}

.infinite-scroll.onSearchScroll {
  height: 100vh;
  min-height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-right: 5px
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5
}

#style-1::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5
}

#style-1::-webkit-scrollbar-thumb {
  background-color: #EF7E2B
}

.friend-list li.bounceInDown.selected {
  background: #FDF8F5 0 0 no-repeat padding-box;
  position: relative
}

.friend-list li.bounceInDown.selected::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 4px;
  height: 100%;
  background: #EF7E2B
}

.chatSection .chat-body .mesgs .msgbottom .type_msg {
  margin-left: 10px
}

.friend-list-heding {
  position: relative;
  height: 78px;
  overflow: hidden
}

.friend-list-heding .position {
  position: absolute;
  border-bottom: 1px solid #EFEFEF;
  width: 100%;
  padding: 15px 30px;
  display: none
}

.friend-list-heding .position:first-child {
  display: block
}

.subheading-5 {
  font-weight: 600;
  color: #151515;
  font-size: 18px
}

.paddingrt {
  padding-right: 40px
}

.viewreport .card {
  border-radius: 10px;
  box-shadow: none;
  background: #F7F7FF;
  padding: 20px 10px
}

.viewreport .card.pink {
  background: #FEF6F0
}

.viewreport .card.light {
  background: #F1FAF2
}

.viewreport .card .text-muted {
  letter-spacing: .44px;
  color: #373737 !important
}

.easypie-data img {
  height: 24px;
  width: 24px;
  max-width: 36px;
  vertical-align: middle
}

.apperance {
  background: #F7F7F7 0 0 no-repeat padding-box;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 0 0 37px
}

.apperance h4 {
  letter-spacing: .44px;
  color: #151515;
  font-size: 16px;
  font-weight: 600
}

.apperance p.times {
  letter-spacing: .36px;
  color: #373737;
  font-size: 14px;
  font-weight: 400
}

.graphdiv {
  background: #FFF 0 0 no-repeat padding-box;
  border: 1px solid #DDD;
  border-radius: 10px
}

.graphdiv.ibox {
  box-shadow: none
}

.orange.text-small {
  text-transform: none;
  font-size: 14px
}

.viewreport .subheading {
  padding-top: 20px;
  padding-bottom: 20px
}

.viewreport .subheading-5 {
  margin-top: 40px;
  margin-bottom: 20px
}

.list-6 {
  display: flex
}

.list-6 li:first-child {
  margin-left: 0
}

.list-6 li {
  background: #F4F4F4 0 0 no-repeat padding-box;
  border-radius: 32px;
  margin: 20px 10px 60px;
  padding: 8px 15px;
  color: #373737;
  font-weight: 500
}

.list-6 li.active {
  background: #EF7E2B 0 0 no-repeat padding-box;
  color: #fff
}

.card-grid-2 .times {
  font-size: 14px
}

.viewreport .card-grid-2 .card-grid-2-image-left .right-info .name-job {
  color: #151515;
  font-weight: 600;
  font-size: 16px
}

.pink2 {
  background: #FFF8F2 0 0 no-repeat padding-box;
  border-radius: 13px;
  letter-spacing: 0;
  color: #EF7E2B;
  font-size: 10px;
  padding: 2px 5px;
  margin-top: 5px;
  width: fit-content
}

.viewreport .ibox-tools ul li .form-control {
  min-height: 40px
}

.viewreport select#state,
.viewreport select.form-control,
.viewreport .select.form-control {
  background: url(assets/images/ic_drop_down_arrow.svg) no-repeat;
  background-position: center right 15px;
  background-size: 11px
}

.viewreport .ibox-title {
  color: #151515;
  display: flex;
  align-items: center
}

.viewreport .ibox-title img {
  height: 14px
}

.viewreport .flexbox-b {
  align-items: flex-start !important
}

.account-settings .nav-pills-custom .nav-link {
  background: #F8F8F8 0 0 no-repeat padding-box;
  border-radius: 10px;
  font-weight: 500;
  color: #151515;
  box-shadow: none
}

.account-settings .nav-pills-custom .nav-link span {
  display: block;
  font-weight: 600
}

.account-settings .nav-pills-custom .nav-link.active {
  color: #EF7E2B
}

.pills-custom {
  flex-direction: column
}

.account-settings .iboxcontent h3 {
  letter-spacing: 0;
  color: #151515;
  font-weight: 600;
  font-size: 24px;
  margin: 20px 0 40px
}

.account-settings .form-group {
  margin-bottom: 20px
}

.account-settings .mainTitle {
  font-size: 20px
}

.notificationbar {
  margin-bottom: 20px;
  border: 1px solid #D7D7DC;
  border-radius: 12px;
  display: flex;
  justify-content: space-between
}

.notificationbar.on-off .slider {
  height: 20px
}

.notificationbar.on-off input:checked+.slider {
  background-color: #EF7E2B;
  border: 1px solid #EF7E2B
}

.notificationbar.on-off .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: -3px;
  top: 2.5px;
  bottom: 0;
  background-color: #fff;
  transition: .4s
}

.notificationbar p {
  color: #151515
}

.plan-inner-outer {
  background: #FAFAFA 0 0 no-repeat padding-box;
  border-radius: 20px;
  padding: 0
}

.plan-inner {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  padding: 40px 20px
}

.plan-inner p {
  font-size: 14px;
  font-weight: 400;
  color: #151515;
  margin-top: 10px
}

.plantext h4 {
  letter-spacing: 0;
  color: #151515;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center
}

.plantext h4 span {
  background: #26C75C 0 0 no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  font-size: 10px;
  padding: 5px;
  font-weight: 400;
  margin-left: 10px
}

.billingbox {
  display: flex;
  justify-content: space-between
}

.billingboxinner {
  margin-right: 20px
}

.billingboxinner h6 {
  color: #151515;
  font-weight: 600;
  font-size: 16px
}

.commonBtn.nobk {
  background: #fafafa;
  border: 1px solid #EF7E2B;
  color: #EF7E2B
}

.bordertop {
  border-top: 1px solid #E3E3E3;
  padding-top: 20px
}

.cardbk {
  background: url(assets/images/bg_card.svg) no-repeat;
  height: 200px;
  width: 100%;
  background-size: cover;
  border-radius: 12px
}

.card-bk-inner-2,
.cardbk p {
  color: #FFF
}

.card-bk-inner-2 {
  padding: 20px
}

.carddetail {
  padding: 20px 0
}

.card-bk-inner {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.carddetail p {
  font-size: 12px
}

.carddetail h4 {
  font-size: 16px;
  color: #FFF;
  padding: 10px 0
}

.carddetail .on-off {
  border: none;
  padding: 0
}

.carddetail .on-off input:checked+.slider {
  background-color: #EF7E2B
}

.search-candidates .form-group {
  position: relative;
  margin-bottom: 0 !important
}

.saved-search-wrap .planbtn {
  padding: 10px 15px;
  width: 100%;
  margin-top: 10px;
  border-radius: 8px
}

.choose .planbtn {
  padding: 5px 20px
}

.search-candidates .iboxcontent {
  width: 100%;
  display: flex;
  justify-content: space-between
}

.search-candidates .saved-search-wrap a.saved-searches {
  color: #EF7E2B;
  margin-bottom: 5px;
  background: url(assets/images/ic_next_arrow.svg) no-repeat;
  background-position: 100%;
  background-size: 20px;
  padding-right: 55px;
  line-height: 32px;
  font-weight: 600;
  position: absolute;
  top: -30px;
  display: block
}

.filter-heading {
  font-weight: 600;
  font-size: 17px;
  padding: .75rem 0;
  display: flex;
  justify-content: space-between;
  color: #151515
}

.filter-heading a.clear-all {
  color: #FE831D;
  font-size: 14px;
  padding: 5px 10px;
  font-weight: 500
}

.search-candidates .ibox .ibox-body {
  padding: 40px
}

.search-candidates .card .form-group select.form-control {
  font-weight: 600;
  background: transparent
}

.search-candidates .card {
  min-height: auto;
  box-shadow: none;
  background: #FAFAFA;
  margin-bottom: 10px
}

.search-candidates .card .card-body {
  padding-top: 0
}

.search-candidates .card-header {
  border: transparent
}

.search-candidates .card-header a.card-link {
  display: block;
  color: #151515;
  font-weight: 500;
  font-size: 16px
}

.search-candidates .card-header a.card-link {
  background: url(assets/images/ic_minus.svg) no-repeat;
  background-position: 100%;
  width: 100%;
  display: block
}

.search-candidates .card-header .card-link.collapsed {
  background: url(assets/images/ic_pluse.svg) no-repeat;
  background-position: 100%;
  width: 100%
}

.search-candidates a.apply-btn.planbtn.fill {
  width: 100%;
  margin-top: 20px
}

.search-candidates .card-header .checkboxWrap input[type="checkbox"]+label {
  font-weight: 500 !important;
  margin-right: 0 !important
}

.search-results {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100vh
}

.no-results {
  width: 100%;
  color: #151515;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center
}

.search-results img {
  width: 100px
}

.search-candidates .form-group label {
  font-weight: 500
}

.search-candidates .form-group input.form-control {
  border-bottom: 1px solid #C3C3C3 !important
}

.search-candidates .form-group input.form-control::placeholder {
  font-weight: 600;
  color: #727272
}

.search-candidates select.form-control {
  font-weight: 600;
  color: #727272
}

.search-candidates .card {
  min-height: auto;
  box-shadow: none;
  background: #FAFAFA;
  margin-bottom: 10px
}

.table-wrapper td span {
  text-decoration: none !important
}

a.orange:hover {
  color: #EE7E2B
}

.recruiterpanel .basicdetails {
  padding: 10px 0
}

.contentjob .basicdetails .addedskills li:first-child {
  margin-left: 0
}

.multiselect-dropdown .dropdown-btn {
  border: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 0 !important
}

.form-control.noBorder {
  border: none !important;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0 !important
}

.recruiter-panel.pd-tb-39 {
  padding-top: 75px
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  display: none
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  background: url(assets/images/ic_down_arrow.svg) no-repeat;
  background-position: center center;
  background-size: 11px
}

.form-control {
  color: rgba(21, 21, 21, 1) !important
}

.form-control::placeholder {
  color: rgba(21, 21, 21, 0.4) !important;
  font-weight: 400
}

.table-bordered tbody {
  border-bottom-left-radius: 18px !important
}

.cardBox {
  margin: 10px 0 15px
}

.preview strong {
  font-size: 17px;
  color: #151515
}

.preview strong label,
.preview strong p {
  color: #151515;
  font-size: 15px
}

.preview strong span {
  font-size: 17px;
  color: #151515
}

.filter-set {
  display: flex;
  align-items: flex-start;
  justify-content: space-between
}

.swal2-styled {
  padding: .625em 1.1em !important
}

.cardList {
  padding: 10px 0
}

.cardList li {
  padding: 5px 0
}

.cardList li .radioWrap {
  display: inline-block
}

.cardList li label {
  margin: 0
}

.infomation {
  color: #000;
  opacity: .5;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 15px
}

.mailTemplate .form-group label {
  margin-bottom: 0;
  padding-right: 10px;
  line-height: 33px;
  width: 120px;
  max-width: 160px
}

.mailTemplate .d-flex .form-group {
  border-bottom: 1px solid #d6d6d6
}

.mailTemplate .d-flex .form-group textarea.form-control {
  border: 1px solid #d6d6d6;
  border-radius: 12px
}

.instruction {
  background: #F9F9F9 0 0 no-repeat padding-box;
  border-radius: 10px;
  padding: 20px 10px
}

.instruction h5 {
  font-size: 16px;
  color: #373737;
  font-weight: 400;
  margin-bottom: 10px
}

.instruction li {
  font-size: 14px;
  color: #151515;
  font-weight: 400;
  line-height: 24px
}

.modal-content.modulecss.CroperImages {
  box-shadow: none
}

li.parentNav .sub-menu {
  display: none;
  transition: all ease-in-out .4s;
}

li.parentNav.active .sub-menu {
  display: block;
  margin-left: 30px
}

li.parentNav.active .sub-menu li a {
  color: #151515;
  background: transparent;
  font-weight: 600;
  padding: 5px 0
}

li.parentNav.active .sub-menu li:last-child {
  margin-bottom: 0
}

li.parentNav.active .sub-menu li.active a {
  color: #EF7E2B
}

.graphs .ibox.inventory-chart {
  background: transparent;
  border: 1px solid #DDD;
  box-shadow: none
}

.ibox.inventory-chart {
  position: relative;
  overflow: hidden
}

span.filterYear {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  text-transform: capitalize;
  bottom: 5px
}

.graphs .card {
  overflow: hidden;
  border: 0;
  min-height: 100px;
  border: 1px solid #DDD;
  border-radius: 10px
}

.graphs .card-body .easypie i {
  font-size: 30px;
  width: 50px;
  height: 50px;
  line-height: 46px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  color: #404e67;
  background: #151515;
  border-radius: 10px
}

.graphs .card-body .easypie .easypie-data i img {
  width: 25px
}

.graphs .demo-chart {
  height: 400px !important;
  width: 100%
}

.graphs #chart-column {
  display: block;
  min-width: 100%;
  height: 200px;
  margin: 0 auto
}

.graphs .chartBar {
  width: 100%
}

.chartBar.noDataFound {
  width: 100%;
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff
}

.chartBar.noDataFound h2,
.noDataFound h3 {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  text-align: center
}

.graphs svg.highcharts-root g.highcharts-legend.highcharts-no-tooltip {
  display: none !important
}

.graphs .chartPie svg.highcharts-root g.highcharts-legend.highcharts-no-tooltip {
  display: block !important;
  width: 280px
}

.chartPie div#highcharts-c5qq7ro-7 {
  overflow: visible !important
}

.graphs text.highcharts-credits {
  display: none !important
}

.graphs .filter img {
  width: 18px;
  height: 25px
}

.graphs .redDot {
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  position: absolute;
  right: -4px;
  top: -2px;
  font-size: 10px;
  color: #fff;
  text-align: center;
  line-height: 17px
}

.graphs .green {
  color: #198C6F !important
}

.graphs .profitLoss {
  position: absolute;
  right: 15px;
  bottom: 10px
}

.form-control {
  color: rgba(21, 21, 21, 1) !important
}

.form-control::placeholder {
  color: rgba(21, 21, 21, 0.4) !important;
  font-weight: 400
}

.table-bordered tbody {
  border-bottom-left-radius: 18px !important
}

.brand-mini img {
  max-width: 34px
}

.cardBox {
  margin: 10px 0 15px
}

.preview strong {
  font-size: 17px;
  color: #151515
}

.preview strong label,
.preview strong p {
  color: #151515;
  font-size: 15px
}

.preview strong span {
  font-size: 17px;
  color: #151515
}

.swal2-styled {
  margin: .3125em;
  padding: .625em 1.1em !important
}

.cardList {
  padding: 10px 0
}

.cardList li {
  padding: 5px 0
}

.cardList li .radioWrap {
  display: inline-block
}

.cardList li label {
  margin: 0
}

.infomation {
  color: #000;
  opacity: .5;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 15px
}

.marginstart {
  margin-left: 3rem
}

.downloadBtn {
  color: #EF7E2B;
  border: 1px solid #EF7E2B;
  border-radius: 8px;
  padding: 0 15px;
  font-size: 15px;
  min-height: 40px;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  line-height: 38px
}

.downloadBtn:hover {
  color: #fff;
  background: #EF7E2B
}

.cap {
  text-transform: capitalize
}

.pieChart h6 {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: .28px;
  color: #707070;
  margin-bottom: 8px
}

.pieChart h5 {
  letter-spacing: .4px;
  color: #151515;
  font-size: 20px;
  font-weight: 500
}

.chartPie {
  height: 350px;
  width: 100%;
  padding-top: 20px
}

.chartPie .highcharts-container,
.chartPie div,
.chartPie div#highcharts-c5qq7ro-7 {
  overflow: visible !important
}

.chartPie .highcharts-container svg,
.chartPie div svg,
.chartPie div#highcharts-c5qq7ro-7 svg {
  overflow: visible !important
}

.chartDetail li {
  display: inline-block;
  vertical-align: middle;
  width: 49.5%;
  text-align: center
}

.chartDetail li small.profitLoss {
  display: block;
  font-size: 13px;
  position: relative
}

.registerModal {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%) !important;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  -o-transform: translateY(-50%) !important;
  max-width: 600px
}

.skill .dropdown {
  max-height: 300px;
  overflow-y: scroll
}

.leftSide {
  padding-top: 50px
}

.card-grid-2 .card-grid-2-image-left.paddingTop {
  padding: 15px
}

.mt-3 .card-time,
.mt-3 .card-applicants,
.mt-3 .card-briefcase {
  color: #373737;
  font-weight: 400
}

.verificationWrap label,
p {
  color: #151515;
  font-size: 20px;
  line-height: 30px;
  font-family: "Work Sans", sans-serif;
  font-weight: 400
}

p.location {
  width: 360px;
  font-size: 18px;
}

p.explain {
  color: #373737;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500
}

.left-sidebar .timeline li.active a p {
  font-weight: 600
}

.chartBar.noDataFound {
  min-height: 300px;
  font-weight: 600;
  line-height: 300px;
  font-size: 20px
}

option:disabled {
  color: rgba(21, 21, 21, 0.6) !important
}

.inventoryReport ul.grp-ul {
  display: flex
}

.inventoryReport li.services {
  width: 150px;
  margin-right: 10px !important;
  text-align: center
}

.inventoryReport li.services1 {
  width: 125px;
  margin-right: 10px !important;
  text-align: center
}

.inventoryReport li.services2 {
  width: 220px;
  margin-right: 10px !important;
  text-align: center
}

.inventoryReport li select.form-control {
  background-position: center right 9px
}

.inventoryReport li .form-control {
  padding: 0 10px !important;
  border: 1px solid #ced4da !important;
  border-radius: 8px;
  min-height: 40px
}

.inventoryReport .table-wrapper table {
  table-layout: inherit !important
}

.inventoryReport .table thead th:last-child {
  width: 110px
}

.inventoryReport .table thead th:nth-last-child(2) {
  width: 90px
}

.smallCase {
  color: #727272;
  line-height: 22px;
  display: block
}

.result .total-number {
  display: inherit
}

.result .user-details .user-info span.user-loc,
.result .listview dt:first-child,
.result .listview dt p,
.result .listview dt span,
.result span.activity-date {
  color: #151515;
  font-weight: 400;
  font-size: 16px
}

.result .total-number img {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle
}

.result .total-number img:last-child {
  margin-left: 10px
}

.result .listview dt p strong,
.result span.activity-date strong {
  font-weight: 600
}

.col-bx-top .listview dl dt:first-child,
.result .listview dt span,
.result .activity-date strong {
  font-weight: 500
}

.result .user-details .user-info span.user-loc {
  margin-bottom: 10px;
  display: block
}

.result .user-details .user-info h6.user-name {
  padding-bottom: 4px;
  font-size: 20px;
  color: #373737;
  font-weight: 600
}

.result .user-details .user-info span.match {
  border: 1px solid #FEE5D2;
  border-radius: 16px;
  color: #EF7E2B;
  font-size: 14px;
  padding: 5px 10px;
  background: #FFF9F5;
  font-weight: 600
}

.ngx-pagination a:hover,
.ngx-pagination button:hover {
  background: transparent
}

.joinservice {
  margin: 10px 0;
  background: #F8F8F8 0 0 no-repeat padding-box;
  color: #151515;
  font-weight: 600;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  overflow: hidden
}

.joinservice span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: block
}

.addmemeber .joinservice {
  margin-bottom: 20px;
  position: relative
}

.addmemeber .action {
  position: absolute;
  top: 10px;
  right: 17px
}

.addmemeber .action a:first-child {
  margin-right: 5px
}

.addmemeber .joinservice .name,
.addmemeber .joinservice .email {
  margin: 0;
  display: block
}

label.selectUser_label span,
label.selectUser_label {
  color: #151515;
  font-weight: 500
}

.search-dropdown.searchRecruiterPannel {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  width: 100%
}

.searchRecruiterPannel ul.dropdown {
  box-shadow: none
}

.user-top.table-wrapper table {
  table-layout: inherit
}

.smalltab.table-wrapper table {
  table-layout: inherit
}

.user-top.table-wrapper table {
  width: calc(100% * 1.2) !important
}

.user-top.table-wrapper th:last-child,
.user-top.table-wrapper td:last-child {
  width: 185px
}

.mailTemplate .d-flex {
  position: relative
}

.mailTemplate .d-flex .text-danger {
  position: absolute;
  bottom: -20px;
  left: 95px
}

.jobPerformanceTable .table-wrapper {
  border-radius: 0 !important
}

.jobPerformanceTable .table-wrapper thead th:first-child,
.jobPerformanceTable .table-wrapper thead th:last-child {
  border-radius: 0 !important
}

.apply img {
  cursor: pointer
}

.whiteBox .profileupload .pofiledetailinner .para4 {
  word-break: break-all
}

g.highcharts-axis-labels.highcharts-xaxis-labels,
g.highcharts-label.highcharts-tooltip {
  text-transform: capitalize
}

.subscription .heading-5 {
  font-weight: 600;
  font-size: 30px
}

.subscription .sub {
  margin: 30px auto 20px
}

.subscription .sub li {
  padding: 10px 20px
}

.subscription .sub li a {
  font-weight: 600;
  font-size: 20px;
  color: #151515
}

.subscription .sub li.active a {
  color: #fff
}

.subscription p.para {
  color: #373737
}

.subscription .choose {
  /* position: absolute; */
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto
}

.centerAlign {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%) !important;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  -o-transform: translateY(-50%) !important
}

.jobScreen .plan-type {
  margin-bottom: 0
}

.jobScreen .iboxcontent h3 {
  margin-bottom: 18px
}

.jobScreen .iboxcontent p {
  color: #151515;
  font-weight: 500
}

.jobScreen .customradiobox .custom_radio input[type="radio"]+label:before {
  border: 1px solid #C3C3C3;
  box-shadow: inset 0 0 0 .2em #fff, inset 0 0 0 1em #C3C3C3
}

.jobScreen .customradiobox .custom_radio input[type="radio"]:checked+label:before {
  border: 1px solid #EF7E2B;
  box-shadow: inset 0 0 0 .2em #fff, inset 0 0 0 1em #EF7E2B
}

.stepBox .page-content .heading-6 {
  margin-bottom: 30px
}

.priceValidation .text-danger {
  position: absolute;
  font-size: 13px;
}

.fullWidth {
  width: 100% !important
}

.education_added .timeline li span.timeline-point-circle {
  top: 7px
}

.userActivity .table-wrapper th:first-child {
  width: 190px
}

.stepsdes {
  margin: 30px 0 0
}

.stepsdes h6 {
  margin-bottom: 5px !important
}

.innerdes .addedskills li,
.stepsdes .addedskills li {
  cursor: default
}

.customBox.disabled {
  cursor: default;
  position: relative;
  z-index: 0
}

.customBox.disabled:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 1
}

.profileInfo .profileName .para3 {
  font-size: 13px;
  opacity: .8;
  line-height: 1.4
}

.noHand {
  cursor: default !important
}

.multiselect-item-checkbox,
.multiselect-dropdown .selected-item {
  text-transform: capitalize !important
}

.multiselect-item-checkbox div {
  text-transform: capitalize !important
}

.industryName:after {
  content: ",";
}

.industryName {
  padding-right: 5px;
  display: inline-block;
  position: relative;
}


.industryName:last-child:after {
  display: none
}
.chipsBox{border: 1px solid #ccc;
  display: inline-block;
  border-radius: 3px;
  padding: 2px 8px;
  margin: 3px; }
.ic_profile_edit {
  cursor: pointer;
  position: relative;
  z-index: 0
}

input[type=file],
input[type=file]::-webkit-file-upload-button {
  cursor: pointer
}

::-webkit-file-upload-button {
  cursor: pointer
}

.accordion-wrapper h3 {
  font-weight: 600;
  font-size: 18px;
  color: #151515
}

.accordion-wrapper .accordion-item {
  margin-bottom: 20px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  overflow: hidden
}

.accordion-wrapper .accordion-button:not(.collapsed) {
  background-color: #EF7E2B;
  box-shadow: none
}

.accordion-wrapper .accordion-button:not(.collapsed)::after {
  background-image: url(assets/images/down-white-arrow.svg);
  transform: var(--bs-accordion-btn-icon-transform)
}

.accordion-wrapper .accordion-button:not(.collapsed) h3 {
  color: #fff
}

.el_details p .firstlCaptial {
  white-space: pre-wrap;
  padding-right: 45px
}

.el_details p span.actionButton {
  position: absolute;
  right: 0
}

.card-grid-2 .card-grid-2-image-left .jobsdiv {
  max-width: 600px
}

.chatWrapper.content-wrapper {
  height: 100vh
}

.chatWrapper.content-wrapper .chatSection {
  height: 100%
}

.height100 {
  height: 100%
}

.multiselect-dropdown .dropdown-btn .selected-item-container~span {
  float: left !important
}

.multiselect-dropdown .dropdown-btn .selected-item-container~span[style] {
  float: left !important
}

.multiselect-dropdown .dropdown-btn .selected-item-container~span span:first-child:after {
  content: "More";
  font-weight: 500;
  padding-right: 10px;
  padding-left: 3px
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:after,
.multiselect-dropdown .dropdown-btn .selected-item-container:after,
.multiselect-dropdown .dropdown-btn .selected-item-container span:after {
  display: none
}

.multiselect-dropdown {
  padding-right: 10px
}

.form-control.textArea {
  max-height: 210px;
  height: 210px
}

.form-control.textAreaDiscription {
  min-height: 184px
}

ul.addedskills.pt-4 {
  padding-top: 12px !important
}

ul.jobdeslist.email li span {
  position: relative;
  padding-left: 15px;
  line-height: normal
}

ul.jobdeslist.email li span img {
  position: absolute;
  left: 0;
  top: 5px
}

.positionStatic .progress {
  position: static;
  margin: 10px 0 15px 0;
}

/* jobseeker */
.content-wrapper {
  background-color: #fff;
  padding: 70px 15px 10px;
}

.skillsWrap .left-sidebar {
  padding-top: 20px;
}

.skillsWrap .right-panel {
  padding-top: 40px;
}

div#imagePreview {
  position: relative;
  width: 100%;
  height: 100px;
}

div#imagePreview img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

li.noAfter::after {
  display: none;
}

li.noAfter a {
  margin-top: 5px;
  font-size: 14px;
}

a.clear-all span {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background: red;
  top: -3px;
  right: -3px;
  display: none;
}

a.clear-all.active span {
  display: block;
}

.align-items-baseline {
  position: relative;
}

.custom-control-label a.orange:focus,
a.orange:focus,
.custom-control-label a.orange:visited {
  color: #EE7E2B !important;
}

.notify-data {
  padding-bottom: 15px;
}

.usersTable .table thead th.minWidth,
.usersTable .table thead td.actionWrap {
  width: 130px;
}

.usersTable .table thead th.emailSec {
  width: 180px;
}

.usersTable .table-wrapper th.action,
.usersTable .user-top.table-wrapper th:last-child,
.usersTable .user-top.table-wrapper td:last-child {
  width: 70px !important
}

.account-settings.checkout .marginstart {
  margin-top: -40px;
}

.ngx-slider .ngx-slider-pointer {
  width: 16px !important;
  height: 16px !important;
  top: -6px !important;
  background-color: #EF7E2B !important;
}

.ngx-slider .ngx-slider-selection {
  background: #EF7E2B !important;
}

.ngx-slider .ngx-slider-pointer:after {
  display: none !important;
}

.form-control.multiDrop .multiselect-dropdown .dropdown-btn .selected-item-container {
  max-width: 100% !important
}

.form-control.multiDrop .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item span {
  font-size: 12px !important;
}

.space {
  padding-right: 3px;
}

.projectTile {
  height: 50px;
  overflow-y: scroll;
}

span.ngx-slider-span.ngx-slider-bubble.ngx-slider-limit.ngx-slider-ceil {
  color: #FAFAFA;
}

.relative {
  position: relative;
}

.orange.viewAll {
  cursor: pointer;
  display: inline-block;
}

.para-16.align-left {
  text-align: left;
  margin: 0;
  width: 100%;
}

.para-16.align-left span {
  display: inline-block;
  vertical-align: middle;
  width: 49%;
}

.para-16.align-left span:nth-child(2n) {
  padding-left: 15px;
}

.contentjob .whiteboxinner {
  position: relative
}

.contentjob .whiteboxinner .profileadd {
  position: absolute;
  right: 19px;
  width: 150px;
}

.badge p {
  font-size: 18px;
  text-align: left;
}

.search-dropdown.searchRecruiterPannel.dropdown-menu .dropdown-item,
.dropdown-menu .dropdown-item {
  white-space: inherit;
  word-break: break-word;
}

.chatTable td a.jobid {
  position: relative;
  padding-left: 40px;
}

.chatTable td a.jobid .checkBox_set,
.chatTable tr .checkBox_set {
  position: absolute;
  left: 10px;
  top: 14px;
}

.chatTable td a.jobid .checkBox_set label {
  margin: 0;
}

.chatTable tr {
  position: relative;
}

/* .joblist2.pointer li img{cursor: pointer;} */
.infinite-scroll.onSearchScrollFilter {
  max-height: 300px;
  /* min-height: 150px; */
  height: auto;
}

.noCap {
  text-transform: lowercase !important;
}

/* stripe mmodel */

.showSubscribeFrom,
.showInventoryFrom {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  height: 100%;
}

.showSubscribeFrom.open,
.showInventoryFrom.open {
  display: block;
}

.showSubscribeFrom.open .modal-content,
.showInventoryFrom.open .modal-content {
  border-radius: 20px;
  max-width: 650px;
  text-align: center;
  min-width: 450px;
  background: #fff;
  margin: 15px auto;
}

/* Multiple Dropdown css */
.subCatMulti .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid #337ab7;
  margin-right: 4px;
  margin-bottom: 4px;
  background: #337ab7;
  padding: 0 5px;
  color: #fff;
  border-radius: 2px;
  float: left;
  max-width: 210px !important;
  overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.subCatMulti .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item a {
  position: absolute;
  right: 0;
}


@media(min-width:1600px) {
  .container {
    max-width: 1500px
  }

  .employer {
    background-size: 100% auto
  }

  .searchSec .input-group-icon .form-control {
    min-width: 385px
  }
}

@media (max-width: 1599px) {
  .bannerContent .content {
    padding: 100px 0 120px
  }

  .mainheading {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 30px
  }

  .para2 {
    font-size: 18px;
    line-height: 30px
  }

  .verificationWrap label,
  p {
    font-size: 16px;
    line-height: 30px;
  }

  p.location {
    width: 275px;
  }

  .board-range .feature-content .small-heading {
    font-size: 18px;
    line-height: 26px
  }

  .mainheading,
  .third-heading,
  .third-heading span,
  .employerContent .second-heading {
    font-size: 50px;
    line-height: 48px;
    margin-bottom: 22px
  }

  .job-description h6,
  .client-content h6,
  .employer p {
    font-size: 18px
  }

  .job-description p,
  .card-block-info p.salary {
    font-size: 16px
  }

  .employer {
    background-size: 100% auto;
    padding: 60px 40px 160px
  }

  .leftSide {
    padding-top: 40px
  }

  .user-top.table-wrapper.table-responsive table {
    width: calc(100% * 1.7) !important
  }

  .usersTable .user-top.table-wrapper.table-responsive table {
    width: calc(100% * 1) !important
  }

  .subscription .heading-5 {
    font-size: 25px
  }

  .subscription .sub li a {
    font-size: 18px
  }

  .permissions-popup .checkboxWrap input[type="checkbox"]+label,
  .checkboxWrap input[type="checkbox"]+label,
  .radioWrap [type="radio"]:checked+label,
  .radioWrap [type="radio"]:not(:checked)+label {
    font-size: 14px
  }

  .registerModal h5,
  label.selectUser_label span,
  label.selectUser_label {
    font-size: 16px
  }

  .selectUser label {
    padding: 10px
  }

  .registerModal p {
    font-size: 14px
  }

  .modal-body image-cropper {
    height: 350px;
  }

  .badge p {
    font-size: 14px;
  }

  .badge img {
    height: 18px;
    margin-right: 5px;
  }

  .recruiterpanel .basicbox {
    padding: 15px;
  }
}

@media (max-width:1499px) {
  .mainheading {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 25px
  }

  .board-range .feature-content .small-heading {
    font-size: 15px;
    line-height: 26px
  }

  .bannerContent .bk {
    top: 0
  }

  .employerContent .second-heading {
    font-size: 40px;
    margin-bottom: 18px
  }

  .para2,
  .employerContent p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 25px
  }

  .verificationWrap label,
  p {
    font-size: 15px;
    line-height: 25px
  }

  p.explain {
    font-size: 14px
  }

  .steps .feature-content {
    padding: 30px
  }

  .feature-content .para {
    min-height: 42px
  }

  .searchSec .input-group-icon .form-control {
    min-width: 260px
  }

  .table-wrapper.table-responsive table {
    width: calc(100% * 1.2) !important
  }

  .smalltab.table-wrapper.table-responsive table {
    width: 100% !important
  }

  .table-wrapper th,
  tfoot th,
  .table td a,
  .table .action-wrap.table-action {
    padding: 15px
  }

  .on-off .table-bordered td,
  .table-bordered th,
  tfoot th {
    font-size: 13px
  }

  .table-wrapper.table-responsive {
    overflow-x: scroll;
    overflow-y: hidden
  }

  .hire {
    height: 400px
  }

  .bannerContent .img img {
    height: 100%;
    min-height: auto;
    background-position: bottom
  }

  .account-settings .nav-pills-custom .nav-link {
    font-size: 13px
  }

  .profileupload .avatar-preview {
    width: 70px;
    height: 70px
  }

  .profileupload div#imagePreview {
    height: 70px;
  }

  .profileupload .avatar-preview div#imagePreview.userImage {
    height: 100px
  }

  .whiteBox .profileupload .pofiledetailinner h5.name {
    font-size: 16px
  }

  .whiteBox .profileupload .pofiledetailinner p {
    font-size: 14px;
    line-height: 22px;
    width: 233px;
  }

  .subscription .heading-5 {
    font-size: 20px
  }

  .subscription .sub {
    margin: 20px auto 15px
  }

  .subscription .sub li a {
    font-size: 15px
  }

  .plan .pill span,
  .paymentinfo .basic p {
    font-size: 14px
  }

  .plan h6.orangetext {
    font-size: 18px
  }

  .planlist li span,
  .paymentinfo .basic h6 {
    font-size: 15px
  }

  .recruiterpanel .education_added .timeline li span.timeline-point-circle {
    top: 6px
  }

  .recruiterpanel .contentjob .timeline:before {
    left: 6px
  }

  .footer-top .social-links.mt-3 a {
    margin-right: 4px
  }

  .account-settings .iboxcontent h3 {
    font-size: 20px;
    margin: 15px 0 20px
  }

  .subscription .sub li {
    padding: 8px 20px
  }

  .subscription .para {
    font-size: 14px
  }

  .subscription .planlist {
    padding-right: 10px
  }

  .subscription .card-grid-2 .card-block-info {
    padding: 5px 0 0
  }

  .plan {
    margin-top: 20px
  }

  .plan .card-grid-2 {
    padding: 30px 20px;
    height: 350px
  }

  .plan .pill {
    padding: 10px
  }

  .planlist li {
    font-size: 13px;
    margin-bottom: 7px;
    line-height: 1.4
  }

  .subscription .choose {
    bottom: 30px
  }

  .profileInfo.mb-5 {
    margin-bottom: 30px !important
  }

  .subTitle {
    font-size: 16px;
    margin-top: 0
  }

  .form-group {
    margin-bottom: 18px
  }

  .form-control.textArea {
    height: 190px
  }

  .enterManually span {
    font-size: 15px;
    margin: 15px 0
  }

  input.commonBtn.mt-5 {
    margin-top: 20px !important
  }

  .topHeader {
    margin-bottom: 30px
  }

  .paymentinfo h6 {
    font-size: 17px;
    margin-bottom: 15px
  }

  .paymentinfo .basic {
    padding: 15px 20px;
    margin-bottom: 15px
  }

  .paymentinfo .basic h6 {
    margin-bottom: 10px
  }

  .para7 {
    margin: 30px 0
  }

  .planbtn {
    font-size: 14px;
    padding: 5px 20px
  }

  .choose .planbtn {
    padding: 3px 20px
  }

  .planfooter {
    padding: 15px 20px
  }

  .add-permissions {
    margin-top: 0
  }

  .add-permissions .ibox-title span,
  .btnwrap {
    margin-bottom: 0
  }

  .stepBox .page-content .heading-6 {
    margin-bottom: 10px;
    margin-top: 15px
  }

  .stepper p.para11.pt-3.pb-3 {
    padding: 10px 0 !important
  }

  .stepper div.pt-4.pb-4 {
    padding: 10px 0 !important
  }

  .addEmail,
  p.para6.fullWidth {
    margin-top: 15px !important
  }

  .stepsdes {
    margin: 0
  }

  .addedskills li {
    font-size: 13px
  }

  .pageTitle {
    font-size: 20px;
  }

  .pageTitle.subTitle2 {
    font-size: 15px;
  }

  .modal-body image-cropper {
    height: 280px;
  }

  .account-settings .form-group {
    margin-bottom: 15px
  }

  .account-settings .mainTitle {
    font-size: 18px
  }
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%
  }

  .bannerContent .content {
    padding: 80px 0 100px
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%
  }

  .mainheading,
  .third-heading,
  .third-heading span,
  .employerContent .second-heading {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px
  }

  h5 {
    font-size: 18px
  }

  .subheading {
    font-size: 20px
  }

  .card-grid-2 .card-grid-2-image-left .right-info .name-job,
  .card-block-info h6,
  .hire-content .para-3 {
    font-size: 15px;
    line-height: 24px;
    text-transform: capitalize
  }

  .card-block-info p.salary,
  .job-description h6,
  .job-description p,
  .client-content h6,
  .employer p {
    font-size: 14px
  }

  .employerContent .orangeBtn {
    font-size: 17px
  }

  .job-description p {
    min-height: 100px;
    overflow-y: auto
  }

  .hire {
    height: auto;
    padding: 60px 40px 80px;
    background-size: cover;
    background-position: center right
  }

  .employer {
    padding-bottom: 115px
  }

  #footer h4,
  .small-heading {
    font-size: 16px
  }

  #footer p,
  ul[class*="list-"] {
    font-size: 14px
  }

  .footer-top {
    padding-top: 40px
  }

  .bannerContent .content a.borderBtn,
  .bannerContent .content a.orangeBtn {
    padding: 10px;
    min-width: 210px;
    font-size: 15px
  }

  .greyBox {
    padding: 15px 25px
  }

  .greyBox h3 {
    font-size: 15px
  }

  .banner {
    overflow: hidden
  }

  .result .user-details .user-info h6.user-name {
    font-size: 18px
  }

  .result .user-details .user-info span.match {
    font-size: 13px
  }

  .result .user-details .user-info span.user-loc,
  .result .listview dt:first-child,
  .result .listview dt p,
  .result .listview dt span,
  .result span.activity-date {
    font-size: 14px
  }

  .permissions-popup .checkboxWrap input[type="checkbox"]+label,
  .checkboxWrap input[type="checkbox"]+label,
  .radioWrap [type="radio"]:checked+label,
  .radioWrap [type="radio"]:not(:checked)+label {
    margin: 0 10px 10px 0;
    padding-left: 30px
  }

  .modal-body image-cropper {
    height: 250px;
  }

  .CroperImages .modal-footer.buttons {
    padding: 10px;
  }
}

@media (max-width:1290px) {
  h4 {
    font-size: 20px;
    font-weight: 600
  }

  .clientimg img {
    width: 300px
  }

  .choice.employer {
    background-size: auto
  }

  .left-sidebar {
    width: 280px;
    padding: 30px 20px 40px 40px
  }

  .right-panel {
    padding: 60px;
    width: calc(100% - 280px);
    margin-left: 280px
  }

  .pageTitle,
  .mainTitle {
    margin-bottom: 20px;
    font-size: 22px
  }

  .el_details {
    margin-bottom: 25px
  }

  a.commonBtn.width50.mt-5 {
    margin-top: 2rem !important
  }

  select#state,
  select.form-control,
  .select.form-control {
    background-position: center right 0
  }

  .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    right: 0;
    padding: 4px 0;
    background-position: center right
  }

  .profileupload .avatar-preview {
    width: 80px;
    height: 80px
  }

  .search-candidates .saved-search-wrap a.saved-searches {
    padding-right: 22px !important;
    font-size: 14px
  }
}

@media (max-width:1199px) {
  .chat_heading h6 {
    font-size: 20px;
  }

  .employer {
    padding-bottom: 72px
  }

  .container {
    max-width: 1200px;
    padding: 0 30px
  }

  #header .search-bar {
    min-width: 190px;
    padding: 0;
    padding-left: 15px
  }

  #header .search-form input {
    font-size: 12px;
    padding-left: 28px
  }

  #header .list li {
    margin-left: 12px
  }

  .navbar a,
  .navbar a:focus,
  .list a {
    font-size: 13px;
    padding: 10px 15px
  }

  .mainheading,
  .third-heading,
  .third-heading span,
  .employerContent .second-heading {
    font-size: 25px;
    line-height: 30px
  }

  h5 {
    font-size: 16px
  }

  .subheading {
    font-size: 18px
  }

  .card-grid-2 .card-grid-2-image-left .right-info .name-job,
  .card-block-info h6,
  .hire-content .para-3 {
    font-size: 15px;
    line-height: 24px
  }

  .card-block-info p.salary,
  .job-description h6,
  .job-description p,
  .client-content h6,
  .employer p {
    font-size: 14px
  }

  .employerContent .orangeBtn {
    font-size: 16px
  }

  .bannerContent .content a.borderBtn,
  .bannerContent .content a.orangeBtn {
    padding: 10px;
    min-width: 200px;
    font-size: 15px
  }

  .greyBox {
    padding: 12px 25px
  }

  .greyBox h3,
  #footer h4,
  .small-heading {
    font-size: 15px
  }

  .choice .greyBox {
    width: 100%;
    min-width: 190px
  }

  .success-stories .owl-theme .owl-nav {
    top: -78px;
    right: -10px
  }

  .test-arrowbox {
    right: 17px
  }

  #footer p,
  ul[class*="list-"] {
    font-size: 13px
  }

  .footer-top {
    padding-top: 35px
  }

  h5.name {
    font-size: 18px
  }

  .left-sidebar {
    width: 250px;
    padding: 30px 12px 40px 40px
  }

  .overview .leftside {
    width: 250px
  }

  .rightside {
    padding: 8px 30px;
    width: calc(100% - 250px)
  }

  .right-panel {
    padding: 60px 40px;
    width: calc(100% - 250px);
    margin-left: 250px
  }

  .pageTitle,
  .mainTitle {
    font-size: 20px
  }

  .commonBtn.width50 {
    max-width: 200px
  }

  .lang .topHeader .skipBtn {
    right: 125px
  }

  .pills-custom {
    flex-direction: row
  }

  .account-settings .pills-custom {
    flex-direction: column
  }

  .account-settings .nav-pills-custom a {
    margin-right: 10px
  }

  .marginstart {
    margin-left: 0
  }

  .minheading h6 {
    font-size: 17px
  }

  .profileupload .avatar-preview {
    width: 70px;
    height: 70px
  }

  .verificationWrap label,
  p,
  .para-16 {
    font-size: 14px;
    line-height: 20px
  }

  .addMoreBtn.pd {
    padding: 10px 14px
  }

  .whiteBox .pageTitle span.subheading-2,
  .subheading-2 {
    font-size: 16px
  }

  .basicdetails .addedskills li {
    padding: 6px 10px
  }

  .subheading {
    font-size: 20px
  }

  .education_added .timeline li:after {
    width: 2px
  }

  .mb-5 {
    margin-bottom: 25px !important;
  }

  .page-content {
    padding-top: 0px;
  }

  .search-candidates .ibox .ibox-body {
    padding: 20px;
  }

  .modal-body image-cropper {
    height: auto;
  }
}

@media only screen and (max-width:996px) and (min-width:767px) {
  .employerContent {
    width: 70%
  }

  .hire {
    height: auto
  }

  .content .para2 {
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 16px
  }

  .employerContent .para2 {
    padding-bottom: 20px;
    font-size: 16px
  }

  .bannerContent .img img {
    margin-bottom: 0 !important;
    min-height: auto;
    height: 400px
  }
}

@media (max-width:996px) {
  .account-settings .nav-pills-custom .nav-link {
    font-size: 13px;
    padding: 7px 10px !important;
    border-radius: 3px;
    margin: 0 5px 5px 0 !important;
  }

  .account-setting .form-group {
    margin-top: 10px !important;
  }

  .account-settings .pills-custom {
    flex-direction: row
  }

  .account-settings .iboxcontent h3 {
    margin-top: 0px;
  }

  .upload-document-family {
    width: auto
  }

  .filter-set {
    display: flex;
    align-items: flex-start;
    justify-content: space-between
  }

  .filter-set {
    flex-wrap: wrap
  }

  .filter-set {
    flex-wrap: wrap
  }

  section.skillsWrap.scrolled-offset {
    flex-wrap: wrap
  }

  .left-sidebar {
    width: 100%;
    padding: 30px 0;
    height: auto
  }

  .timeline li {
    float: left
  }

  .addMoreBtn {
    margin-left: 0
  }

  .pageTitle {
    flex-wrap: wrap
  }

  .bottom .commonBtn {
    position: inherit
  }

  .right-panel {
    padding: 40px 20px;
    width: 100%;
    margin-left: 0
  }

  .clientimg img {
    width: 320px
  }

  .jobsDetailbox {
    margin-top: 20px
  }

  .mainJob {
    justify-content: flex-start
  }

  .mainJob .greyBox {
    margin-right: 10px
  }

  .timeline li::after {
    width: 100%;
    height: 1px;
    top: 6px;
    background-color: #727272
  }

  .timeline li:first-child:after {
    left: 50%;
    width: 50%
  }

  .education_added .timeline li:first-child:after {
    left: 3px;
    width: 1.5px
  }

  .timeline li:last-child::after {
    background: #727272;
    right: 50%;
    left: auto
  }

  .el_details ul li:after {
    display: none
  }

  .menu_icon {
    display: inline-block;
    position: relative;
    right: 20px
  }

  .hideMenuToggle .menu_icon {
    display: none;
  }
}

@media(max-width: 991px) {
  .content-wrapper {
    padding-top: 75px;
  }

  #header {
    height: 60px;
  }

  .recruiter-panel.pd-tb-39 {
    padding-top: 70px;
  }

  .container {
    padding: 0 20px
  }

  .center {
    text-align: left
  }

  .loginPage {
    padding: 25px 0;
    margin-top: 25px
  }

  .topHeader {
    margin-bottom: 25px
  }

  .profileInfo {
    margin-bottom: 25px !important
  }

  .profileName .d-flex.spaceRight.radioWrap {
    display: block !important
  }

  .profileName {
    margin-left: 30px
  }

  .subTitle {
    margin-bottom: 10px
  }

  .flexcss {
    flex-wrap: wrap
  }

  .iboxcontent h3 {
    font-size: 16px
  }

  .filter-heading {
    font-size: 15px
  }

  .search-candidates .card-header a.card-link,
  .whiteBox .pageTitle span.subheading-2,
  .subheading-2 {
    font-size: 14px
  }

  .search-candidates .form-group {
    margin-bottom: 1rem !important
  }

  #header .search-bar {
    left: 0 !important;
    width: 100% !important
  }

  .whiteBox {
    display: inline-block;
    width: 100%;
    padding: 20px 15px
  }

  .mobile-nav-toggle {
    display: block
  }

  .headerContainer {
    justify-content: space-between
  }

  .page-wrapper {
    min-height: 1px
  }

  .swiper-container-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction,
  .slick-dots li.slick-active {
    bottom: -40px !important;
    left: 0
  }

  .banner {
    height: auto
  }

  .content .mainheading {
    font-size: 36px
  }

  .bannerContent .img img {
    width: 100%;
    height: 400px;
    margin-bottom: 40px
  }

  .list-6 {
    flex-wrap: wrap
  }

  .list-6 li {
    margin-bottom: 10px;
    margin-left: 0
  }

  .skillsWrap .left-sidebar {
    position: fixed;
    height: auto;
    padding: 20px 20px 10px 10px;
    margin-top: 0;
    width: 100%;
    top: 70px;
    z-index: 1
  }

  .skillsWrap .right-panel {
    padding: 40px 20px;
    width: calc(100% - 0px);
    margin-left: 0;
    margin-top: 180px;
    z-index: 0;
    position: relative
  }

  .skillsWrap .left-sidebar .timeline {
    overflow: hidden;
    display: flex;
    align-items: normal;
    padding-bottom: 10px
  }

  .skillsWrap .left-sidebar .timeline .timeline-item {
    padding: 30px 0;
    min-width: 80px;
    max-width: 100%;
    overflow: hidden;
    display: inline-flex;
    justify-content: space-between;
    width: auto;
    text-align: center
  }

  .skillsWrap .left-sidebar .timeline .timeline-item:last-child {
    padding-right: 0
  }

  .skillsWrap .left-sidebar .timeline .timeline-item span.timeline-point-circle {
    height: 14px;
    width: 14px;
    left: 0;
    top: 0;
    right: 0;
    margin: auto
  }

  .left-sidebar .timeline li a p {
    font-size: 13px;
    text-align: center;
    display: block;
    width: 100%;
    margin: auto;
    min-width: 75px;
    max-width: 100%
  }

  .pageTitle,
  .mainTitle,
  .subTitle {
    font-size: 18px;
    margin-bottom: 15px
  }

  .skillsWrap .left-sidebar .timeline .timeline-item.active:first-child span.timeline-point-circle::after,
  .skillsWrap .left-sidebar .timeline .timeline-item.active span.timeline-point-circle.fill::after {
    width: 100%;
    height: 1px;
    top: 6px;
    min-height: auto;
    position: absolute;
    content: "";
    max-width: 100%;
    min-width: 200px;
    left: 13px
  }

  .skillsWrap .left-sidebar .timeline .timeline-item.active:last-child span.timeline-point-circle.fill::after {
    display: none
  }

  .skillsWrap .left-sidebar .timeline .timeline-item span.timeline-point-circle.fill:before {
    width: 100%;
    height: 1px;
    top: 6px;
    min-height: auto;
    max-width: 100%;
    min-width: 100px;
    left: -100px
  }

  .skillsWrap .commonBtn.width50,
  input.commonBtn.width50.mt-4,
  a.commonBtn.width50.mt-5 {
    margin: 0 !important
  }

  .education_added {
    display: block;
    overflow: hidden
  }

  .education_added .timeline li {
    position: relative;
    float: none
  }

  .suggestedskills li {
    font-size: 13px
  }

  .radioWrap [type="radio"]:checked+label,
  .radioWrap [type="radio"]:not(:checked)+label {
    font-size: 15px
  }

  .overview .leftside {
    width: 200px
  }

  .rightside {
    padding: 8px 20px;
    width: calc(100% - 200px)
  }

  .basicdetails .para-16 {
    margin-bottom: 10px
  }

  .loginPage.marginTop {
    margin-top: 70px;
  }

  .saved-search-wrap {
    display: flex;
    flex-direction: column;
  }

  .search-candidates .saved-search-wrap a.saved-searches {
    position: static;
    order: 2;
    width: 160px;
  }

  .saved-search-wrap .planbtn {
    order: 1px
  }

  .details-page .permissionsBox ul {
    flex-wrap: wrap;
  }

  .details-page .permissionsBox ul li {
    width: 50%;
    padding: 10px 10px 10px 0;
  }

  .permissionsBox ul li strong {
    font-size: 14px;
  }

  .chatSection .chat-body .userList li {
    text-transform: capitalize;
    padding: 10px 15px;
    font-size: 13px;
    border-bottom: 1px solid #EFEFEF;
  }

  .chatSection .chat-body .userList li span {
    font-size: 15px;
  }

  .chat_heading {
    padding: 15px 15px 10px 15px;
  }

  .table-wrapper.notes-table-responsive {
    overflow-x: scroll;
    overflow-y: hidden
  }

  .table-wrapper.notes-table-responsive table {
    width: calc(100% * 1.2) !important
  }

  .table-wrapper.notes-table-responsive th.width-auto {
    width: auto !important;
  }
}

@media (max-width:767px) {

  .loginImg,
  .hideMob {
    display: none;
  }

  .banner {
    height: 450px
  }

  .bannerContent .content {
    padding: 140px 0 0
  }

  .bk {
    position: inherit
  }

  .bannerContent .img img {
    width: 100%;
    height: 100%;
    max-width: auto;
    margin-bottom: 0;
    min-height: auto
  }

  .searchSec .input-group-icon .form-control {
    min-width: 220px
  }

  .steps .feature-content,
  .feature-content {
    margin-bottom: 20px
  }

  #header .search-bar {
    position: fixed;
    top: 70px;
    left: 15px;
    right: 15px;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(1, 41, 112, 0.1);
    background: #fff;
    transition: .3s;
    opacity: 1
  }

  #header .search-bar-show {
    top: 60px;
    visibility: visible;
    opacity: 1
  }

  .pd-tb {
    padding: 40px 0
  }

  .employerContent {
    width: 100%
  }

  .employer {
    background: #fff;
    padding: 40px 20px;
    height: auto
  }

  .hire {
    background: url(assets/images/hire.png) 0 0/cover no-repeat;
    padding: 40px 20px
  }

  .hire-content {
    width: 100%
  }

  .second-heading {
    font-size: 32px
  }

  a.orangeBtn,
  a.borderBtn {
    display: inline-block;
    font-size: 12px;
    width: fit-content
  }

  .spacer .title .para {
    width: 100%
  }

  .test-arrowbox {
    top: 0
  }

  #footer h4 {
    margin-top: 50px
  }

  .subscription {
    text-align: left
  }

  .sub {
    margin: 30px 0
  }

  .plan .card-grid-2 {
    height: auto
  }

  .para6 {
    width: 100%
  }

  .mainJob {
    justify-content: center
  }

  .page-sidebar .side-menu li .nav-label {
    display: inline-block
  }

  .table-wrapper {
    margin-top: 15px
  }

  .table-scroll .table-bordered td a,
  .table-scroll .table-bordered th {
    width: 150px
  }

  .ibox .ibox-body {
    position: relative
  }

  /* .ibox .ibox-body .flexbox{display:unset!important;-webkit-box-pack:unset!important;-webkit-justify-content:unset!important;-ms-flex-pack:unset!important;justify-content:unset!important;-webkit-box-align:unset!important;-webkit-align-items:unset!important;-ms-flex-align:unset!important;align-items:unset!important} */
  .ibox .ibox-body .flexbox .iboxcontent {
    position: relative;
    margin-bottom: 15px
  }

  .ibox .ibox-body .col-list-right {
    margin-bottom: 10px
  }

  .jobs {
    padding: 0 20px
  }

  .paddingtop {
    padding-top: 20px
  }

  .progessdiv .whitebox.flexcss {
    flex-wrap: wrap;
    justify-content: center
  }

  .profiledescription p {
    margin-top: 15px
  }

  .profiledescription .allowBtn {
    margin-bottom: 10px
  }

  .lastupdated p {
    margin-top: 20px
  }

  .jobs .card-grid-2 .card-grid-2-image-left,
  .jobs .card-grid-2 .card-block-info {
    flex-wrap: wrap
  }

  .otpInput {
    max-width: 60px
  }

  .left-sidebar .timeline li a p {
    font-size: 12px;
    text-align: center
  }

  .skillsWrap .left-sidebar .timeline .timeline-item {
    min-width: 80px;
    left: -15px
  }

  .skillsWrap .left-sidebar .timeline .timeline-item {
    padding-bottom: 0
  }

  .skillsWrap .right-panel {
    padding-top: 10px
  }

  .pageTitle,
  .mainTitle,
  .subTitle {
    font-size: 16px;
    margin-bottom: 12px
  }

  .overview .leftside,
  .rightside {
    width: 100%;
    padding: 0
  }

  .overview .leftside .tablist {
    margin-bottom: 20px;
    display: flex;
    overflow-x: scroll
  }

  .overview .leftside .tablist li {
    white-space: nowrap;
    margin: 10px 10px 10px 0
  }

  .expinner h6,
  .subTitle2,
  .lang .topHeader .skipBtn {
    font-size: 14px
  }

  .form-group label,
  select#state,
  select.form-control,
  .select.form-control,
  input.form-control,
  .addedskills li,
  .textinner,
  .joinservice,
  .radioWrap [type="radio"]:checked+label,
  .radioWrap [type="radio"]:not(:checked)+label,
  .verificationWrap label,
  p,
  .para-16,
  .addMoreBtn {
    font-size: 13px
  }

  .maxWidth {
    max-width: 100%;
    margin: 15px
  }

  .skillsWrap .left-sidebar .timeline {
    overflow-x: scroll
  }

  .jobswrap {
    max-width: 100%;
  }

  .flexbox.pageHeading {
    flex-direction: column;
    align-items: start;
  }

  .basicbk .para6 {
    margin-top: 0px;
  }

  .basicbk {
    flex-wrap: wrap;
  }
}

@media (max-width:550px) {
  .d-flex.tabBar {
    flex-direction: column;
  }

  .d-flex.tabBar .joblist2 {
    order: 1;
  }

  .d-flex.tabBar .joblist1 {
    order: 2;
  }

  .basicinfo .heading8 {
    font-size: 15px;
  }
}

@media (max-width:480px) {

  .viewreport .card {
    margin-right: 20px
  }

  .timeline .timeline-item span {
    font-size: 12px;
    color: #151515
  }

  .para-16 {
    margin-bottom: 10px
  }

  .addMoreBtn {
    margin-left: 0;
    margin-top: 10px
  }

  .right-panel {
    padding: 40px 20px;
    width: 100%
  }

  .scrolled-offset {
    margin-top: 60px
  }

  .bottom .commonBtn {
    position: inherit
  }

  .el_details {
    margin-bottom: 15px
  }

  .el_details ul {
    margin-bottom: 15px;
    margin-top: 15px
  }

  .education_added .timeline li {
    padding-bottom: 15px;
    padding-left: 15px
  }

  .education_added .timeline li span.timeline-point-circle {
    top: 5px;
    height: 8px;
    width: 8px
  }

  .pageTitle,
  .mainTitle,
  .subTitle {
    font-size: 14px
  }

  .form-group label,
  select#state,
  select.form-control,
  .select.form-control,
  input.form-control,
  .addedskills li,
  .textinner,
  .joinservice,
  .radioWrap [type="radio"]:checked+label,
  .radioWrap [type="radio"]:not(:checked)+label,
  .verificationWrap label,
  p,
  .para-16,
  .addMoreBtn,
  span.ft-wt,
  .education_added .timeline li .el_details ul li {
    font-size: 12px
  }

  .expinner h6,
  .subTitle2,
  .lang .topHeader .skipBtn {
    font-size: 13px
  }

  .lang .topHeader .skipBtn {
    top: 13px;
    right: 112px
  }

  .test-arrowbox {
    display: none
  }

  .clientimg img {
    width: 300px
  }

  .clientimg {
    padding: 20px 0
  }

  .d-flex.justify-content-between {
    display: block !important;
    justify-content: unset;
    position: relative
  }

  .justify-content-between .form-group {
    width: 100%
  }

  .addModal .modal-body {
    padding: 35px 15px !important
  }

  .maindiv {
    display: block
  }

  .dropdown-menu ul a {
    padding: 10px 5px
  }

  .linksent,
  .upload-document-family {
    width: auto
  }

  .auth {
    margin: 20px 0
  }

  .profileInfo {
    flex-wrap: wrap;
    justify-content: space-between
  }

  .profileName {
    margin-left: 0;
    width: calc(100% - 130px)
  }

  .name {
    margin-top: 0
  }

  .bluebox {
    flex-wrap: wrap;
    justify-content: center
  }

  .joinservice {
    margin: 10px 0
  }

  .fetchBtn {
    margin: 10px 0
  }

  .addressWrap .detectLocation .allowBtn {
    margin-left: 5px
  }

  .btnDiv {
    flex-wrap: wrap;
    justify-content: center
  }

  .btnDiv .form-group .commonBtn {
    width: 180px
  }

  .loginPage.marginTop {
    margin-top: 40px
  }

  .scrolled-offset {
    margin-top: 50px
  }

  .para3.mb-5 {
    margin-bottom: 1rem !important
  }

  .form-group.pt-5 {
    padding-top: 1rem !important
  }

  .registerModal .modal-body.text-center {
    text-align: left !important
  }

  .selectUser label {
    margin: 0 10px 20px 0
  }

  .heading-5 a {
    top: 30px;
    right: 0;
    left: 0
  }

  .sub {
    border: none;
    padding: 0
  }

  .planfooter .flexcss {
    flex-wrap: wrap
  }

  .choose {
    margin-top: 10px
  }

  .content .para2 {
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 16px
  }

  .employerContent .para2 {
    padding-bottom: 20px;
    font-size: 16px
  }

  .bannerContent .content a.borderBtn,
  .bannerContent .content a.orangeBtn {
    margin-top: 20px
  }

  .list li {
    margin-left: 6px
  }

  .clientimg img {
    width: 280px
  }

  .selectUser label::before {
    top: 20px
  }

  #registerModal .modal-dialog-centered {
    min-height: calc(100% - 15rem)
  }

  .otpInput {
    max-width: 55px
  }

  .registerModal,
  .centerAlign {
    width: 100%;
    max-width: 95%
  }

  .heading2 {
    font-size: 20px
  }

  .whiteBox .profileupload {
    flex: auto
  }

  .cross_icon {
    left: auto !important;
    right: 10px !important
  }

  #header .search-bar {
    padding: 6px 20px
  }

  .el_details p {
    padding-right: 20px
  }

  .profileadd {
    text-align: right;
    width: 100%;
    margin-bottom: 10px
  }

  .to {
    text-align: center;
    margin: 0px;
  }

  .details-page .permissionsBox ul li {
    width: 100%;
    padding: 10px 0;
  }

  .greybk {
    margin-top: 57px;
    padding: 12px 0;
  }

  .banner {
    height: auto;
  }

  #header .list {
    top: 58px;
  }

  .completeProfile,
  .overview,
  .main-wrapper.chatContainer {
    padding-top: 75px;
  }

  .subscription .choose,
  .planfooter {
    bottom: 0;
    position: relative;
  }

  .countryCode span {
    border: none;
  }

  .customradiobox .custom_radio input[type="radio"]+label {
    margin-right: 0;
    width: 50%;
  }

  .job-list.nav>li>a {
    margin-right: 15px;
  }

  .page-content.recruiterpanel .ibox .ibox-body .flexbox,
  .page-content.recruiterpanel .ibox .ibox-body .flexbox .ibox-tools {
    display: block;
  }

  .page-content.recruiterpanel .ibox .ibox-body .flexbox .ibox-tools .col-list-right {
    width: 100%;
    text-align: right;
  }

  .page-content.recruiterpanel .ibox .ibox-body .flexbox .ibox-tools ul {
    margin: 0;
  }

  .page-content.recruiterpanel .ibox .ibox-body .flexbox .input-group-icon .form-control {
    width: 100%;
  }

  .contentjob .basicdetails .addedskills li {
    margin: 10px 8px 3px 0px;
  }

  .page-content.recruiterpanel .el_details ul li .blue-text {
    margin-top: 0;
  }

  /* .table-scroll .table-bordered td a, .table-scroll .table-bordered th {width: auto;} */
  .addModal {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%) !important;
    -webkit-transform: translateY(-50%) !important;
    -moz-transform: translateY(-50%) !important;
    -ms-transform: translateY(-50%) !important;
    -o-transform: translateY(-50%) !important;
    background: #fff;
    width: 100%;
    max-width: 95%;
  }

  .inventoryReport ul.grp-ul {
    margin-top: 5px;
    flex-direction: column-reverse;
    width: 100%;
    position: relative;
  }

  .downloadBtn {
    padding: 0 9px;
    font-size: 14px;
    position: absolute;
    right: 0;
    width: 150px;
  }

  .inventoryReport ul.grp-ul li.services {
    width: 49%;
  }

  .inventoryReport ul.grp-ul li.services1,
  .inventoryReport ul.grp-ul li.services2 {
    margin-top: 10px;
    width: 100%;
  }

  .ibox .ibox-body {
    padding: 15px;
  }

  .recruiterpanel .job-list.nav>li>a {
    font-size: 13px;
  }

  .align-items-baseline .innerdes .para6 {
    margin-top: 0;
  }

  .planbtn {
    width: 100%;
    margin: 5px 0;
  }

  .filter-heading.button .planbtn {
    width: auto;
  }

  .jobseeker .empChoice,
  .jobs.pd-tb {
    margin-top: 50px;
  }

  .jobseeker .completeProfile,
  .completeProfileloged .completeProfile {
    padding: 70px 0 100px;
  }

  .editProfile .el_details .blue-text {
    margin: 0;
  }
}
.multiselect-item-checkbox input[type=checkbox]:disabled + div:before,.multiselect-item-checkbox input[type=checkbox]:disabled + div{
  cursor: not-allowed !important;
}